import {Component, OnInit} from '@angular/core';

import {GetResponseBanner} from '@models/banner.model';
import {GetResponseBooking} from '@models/booking.model';

// service
import {Logger} from '@services/logger.service';

// service api
import {BannerService} from '@api/banner.service';
import {BookingService} from '@services/api/booking.service';

const log = new Logger('SectionBannerComponent');

@Component({
    selector: 'app-section-banner',
    templateUrl: './section-banner.component.html',
    styleUrls: ['./section-banner.component.scss']
})

export class SectionBannerComponent implements OnInit {

    dataBanner: GetResponseBanner | undefined;
    dataBooking: GetResponseBooking | undefined;

    constructor(
        private newsService: BannerService,
        private bookingService: BookingService,
    ) {
    }

    async ngOnInit() {
        await this.getBanner();
        await this.getLot("now");
    }

    async getBanner() {
        try {
            this.dataBanner = await this.newsService.getBanners(1, 10);
            log.debug('get banner', this.dataBanner);
        } catch (error) {
            log.error('get banner error');
        }
    }

    async getLot(type: 'now' | 'tomorrow' | 'soon') {
        try {
            this.dataBooking = await this.bookingService.getLotsBooking({type});
            log.debug('get lot', this.dataBooking);
        } catch (error) {
            log.error('get lot error');
        }
    }
}
