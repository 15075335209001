<div class="con-card" *ngIf="typeLink === 'play-store'" (click)="goToPlayStore()">
  <div class="con-img">
    <!-- <img src="/assets/icons/app-apple.svg" /> -->
    <img src="/assets/icons/app-playstore.svg" />
  </div>
  <div class="con-text">
    <h4>Play Store</h4>
    <p>ดาวน์โหลดแอพพลิเคชั่นได้แล้ววันนี้</p>
    <p>สำหรับผู้ใช้ Samsung OPPO Xiaomi HUAWEI ฯลฯ</p>
  </div>
</div>
<div class="con-card" *ngIf="typeLink === 'app-store'" (click)="goToAppStore()">
  <div class="con-img">
    <img src="/assets/icons/app-apple.svg" />
    <!-- <img src="/assets/icons/app-playstore.svg"  /> -->
  </div>
  <div class="con-text">
    <h4>App Store</h4>
    <p>ดาวน์โหลดแอพพลิเคชั่นได้แล้ววันนี้</p>
    <p>สำหรับผู้ใช้ iPhone iPad mac macbook  ฯลฯ</p>
  </div>
</div>
