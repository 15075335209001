import {Component, Input, OnInit} from '@angular/core';

// service api
import {NotificationService} from "@api/notification.service";

import * as moment from "moment";

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html',
  styleUrls: ['./navbar-notification.component.scss']
})

export class NavbarNotificationComponent implements OnInit {

  @Input() resBadge!: any;
  @Input() notiList!: any;

  constructor(
    private _notificationService: NotificationService,
  ) {
    this._notificationService.notificationSubject.subscribe((event: any) => {
      if (event) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      // console.debug('resBadge', this.resBadge)
      console.debug('notiList', this.notiList)
    }, 2000)
  }

  getElapsedTime(notiDate: any) {
    const postMoment = moment(notiDate);
    const nowMoment = moment();

    const diffInSeconds = nowMoment.diff(postMoment, 'seconds');
    const diffInMinutes = nowMoment.diff(postMoment, 'minutes');
    const diffInHours = nowMoment.diff(postMoment, 'hours');
    const diffInDays = nowMoment.diff(postMoment, 'days');
    const diffInWeeks = nowMoment.diff(postMoment, 'weeks');
    const diffInMonths = nowMoment.diff(postMoment, 'months');
    const diffInYears = nowMoment.diff(postMoment, 'years');

    if (diffInSeconds < 60) {
      return 'ไม่กี่วินาทีก่อน';
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} นาทีที่แล้ว`;
    } else if (diffInHours < 24) {
      return `${diffInHours} ชั่วโมงที่แล้ว`;
    } else if (diffInDays < 7) {
      return `${diffInDays} วันที่แล้ว`;
    } else if (diffInWeeks < 4) {
      return `${diffInWeeks} สัปดาห์ที่แล้ว`;
    } else if (diffInMonths < 12) {
      return `${diffInMonths} เดือนที่แล้ว`;
    } else {
      return `${diffInYears} ปีที่แล้ว`;
    }
  }

  checkToPage(item: any) {
    // console.debug('item', item);
    if (!item?.info?.objectId) {
      return;
    }
    // console.debug('module', item.module);
    if (item.module === 'onBoard') {
      window.location.href = '/booking/my-onboard#success';
    } else if (item.module === 'createOffer') {
      window.location.href = '/booking/my-booking#offer';
    } else if (item.module === 'createP2POrder') {
      window.location.href = '/my-wallet#coins-transfer-trading-history';
    } else if (item.module === 'onMarket') {
      window.location.href = '/marketplace/my-market#manage-product';
    } else if (item.module === 'onMarket') {
      window.location.href = '/marketplace/my-market#manage-product';
    } else if (item.module === 'createOrder') {
      window.location.href = '/booking/my-booking#waitpay';
    }
  }
}
