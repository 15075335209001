import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from "@angular/router";

import Swiper from 'swiper';

// module
import { CoreCommonModule } from 'src/app/@core/common.module';
import { SharedPipesModule } from '../pipes/shared-pipes.module';

// card
import { CardBannerComponent } from "./card-banner/card-banner.component";
import { CardBannerMarketplaceComponent } from "./card-banner-marketplace/card-banner-marketplace.component";
import { CardDownloadAppComponent } from './card-download-app/card-download-app.component';
import { CardNewProductComponent } from "./card-new-product/card-new-product.component";
import { CardNoItemComponent } from './card-no-item/card-no-item.component';
import { CardProductComponent } from './card-product/card-product.component';
import { CardProductBookingComponent } from "@shared/components/card-product-booking/card-product-booking.component";
import { CardProductMarketplaceComponent } from './card-product-marketplace/card-product-marketplace.component';
import { CardRecommendShopComponent } from './card-recommend-shop/card-recommend-shop.component';

// slide
import { SlideBannerComponent } from "./slide-banner/slide-banner.component";
import { SlideBannerMarketplaceComponent } from "./slide-banner-marketplace/slide-banner-marketplace.component";
import { SlideFreemodeComponent } from './slide-freemode/slide-freemode.component';
import { SlideImageComponent } from "./slide-image/slide-image.component";
import { SlideNewProductComponent } from "./slide-new-product/slide-new-product.component";

// ng-bootstrap
import { NgbTooltip, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

// ng-gallery
import { GalleryComponent } from "ng-gallery";

// ng-lazyload-image
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';

// ngx
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

// component
import { SidebarMenuComponent } from "./sidebar-menu/sidebar-menu.component";

import { AvatarTextButtonComponent } from './avatar-text-button/avatar-text-button.component';
import { HeaderTitleComponent } from './header-title/header-title.component';
import { InputSearchComponent } from "./input-search/input-search.component";
import { LoadingComponent } from './loading/loading.component';
import { ButtonComponent } from "./button/button.component";
import { CoinComponent } from './coin/coin.component';

import { QrCodeComponent } from './qr-code/qr-code.component';
import { CarouselBookingComponent } from './carousel-booking/carousel-booking.component';

// modal
import { ConfirmModalComponent } from "@shared/components/modal/confirm-modal/confirm-modal.component";
import { SlideImagesModalComponent } from "@shared/components/modal/slide-images-modal/slide-images-modal.component";

@NgModule({
  providers: [
    Swiper,
    CommonModule,

    // module
    CoreCommonModule,
    SharedPipesModule,
    {provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks}
  ],
  declarations: [
    // component
    SidebarMenuComponent,

    AvatarTextButtonComponent,
    HeaderTitleComponent,
    InputSearchComponent,
    LoadingComponent,
    ButtonComponent,
    CoinComponent,

    // card
    CardBannerComponent,
    CardBannerMarketplaceComponent,
    CardDownloadAppComponent,
    CardNewProductComponent,
    CardNoItemComponent,
    CardProductComponent,
    CardProductBookingComponent,
    CardProductMarketplaceComponent,
    CardRecommendShopComponent,

    // slide
    SlideBannerComponent,
    SlideBannerMarketplaceComponent,
    SlideFreemodeComponent,
    SlideImageComponent,
    SlideNewProductComponent,

    CarouselBookingComponent,
    QrCodeComponent,

    // modal
    ConfirmModalComponent,
    SlideImagesModalComponent,
  ],
  exports: [
    // component
    SidebarMenuComponent,

    AvatarTextButtonComponent,
    HeaderTitleComponent,
    InputSearchComponent,
    LoadingComponent,
    ButtonComponent,
    CoinComponent,

    // card
    CardBannerComponent,
    CardBannerMarketplaceComponent,
    CardDownloadAppComponent,
    CardNewProductComponent,
    CardNoItemComponent,
    CardProductComponent,
    CardProductBookingComponent,
    CardProductMarketplaceComponent,
    CardRecommendShopComponent,

    // slide
    SlideBannerComponent,
    SlideBannerMarketplaceComponent,
    SlideFreemodeComponent,
    SlideImageComponent,
    SlideNewProductComponent,

    CarouselBookingComponent,
    QrCodeComponent,

    // modal
    ConfirmModalComponent,
    SlideImagesModalComponent,
  ],
  imports: [
    CommonModule,
    RouterLink,

    // module
    CoreCommonModule,
    SharedPipesModule,

    // ng-bootstrap
    NgbTypeahead,
    NgbTooltip,

    // ng-gallery
    GalleryComponent,

    // ng-lazyload-image
    LazyLoadImageModule,

    // ngx
    NgxSkeletonLoaderModule,
  ]
})

export class SharedComponentModule {
}
