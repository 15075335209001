import { Injectable } from '@angular/core';
import { Category, UserProfile } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  public fee: number | undefined;
  public sharedDataUserCurrent!: UserProfile;
  public shareDateCategoryMarketplace!: [];
  public shareDateCategoryBooking!: [];
  public shareDateProvinces!: [];
  constructor() { }



  setDataFee(value: number) {
    this.fee = value;
  }
  getDataFee() {
    return this.fee;
  }

  setDataUserCurrentInfo(data: any) {
    this.sharedDataUserCurrent = data;
  }
  getDataUserCurrentInfo() {
    return this.sharedDataUserCurrent;
  }

  setDataCategoryMarketplace(data: any) {
    if (!data) return;
    const filteredResults = data.results.filter((item: Category) => item.type === 'onmarket');
    this.shareDateCategoryMarketplace = filteredResults;
  }
  getDataCategoryMarketplace() {
    return this.shareDateCategoryMarketplace;
  }

  setDataCategoryBooking(data: any) {
    if (!data) return;
    const filteredResults = data.results.filter((item: Category) => item.type === 'default');
    this.shareDateCategoryBooking = filteredResults;
  }
  getDataCategoryBooking() {
    return this.shareDateCategoryBooking;
  }


  setDataProvinces(data: any) {
    this.shareDateProvinces = data;
  }

  getDataProvinces() {
    return this.shareDateProvinces;
  }






}
