<div class="con-nav">
  <div class="navbar-contact">
    <div class="contact">
      <a href="/help/about-us">เกี่ยวกับเรา</a>
      <a href="/help/contact-us">ติดต่อเรา</a>
    </div>
    <div class="translate">
      <a class="active"> TH</a>
      <span>|</span>
      <a> EN</a>
    </div>
  </div>

  <nav class="navbar navbar-expand-lg nav-header">
    <div class="container-fluid pb-0 pb-sm-2 pb-md-2">
      <a class="navbar-brand" href="/home">
        <img src="/assets/logo/logo-with-text-2.svg" title="LANGKA AMULET" alt="Langka Amulet"/>
      </a>
      <button class="navbar-toggler btn-cllaps" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
        <i class="bi bi-list" style="color: #fff; font-size: 28px"></i>
      </button>
      <div class="collapse navbar-collapse" id="main_nav" #mainNavCollapse>
        <ul class="con-login-2" *ngIf="currentUser">
          <li class="con-btn-shop" (click)="goLink('/marketplace/create-product')">
            <a class="nav-link btn-shop"> ลงขาย </a>
          </li>
          <li class="con-icons">
            <a class="nav-link" (click)="closeCollapse(); closeCollapse()">
              <img src="assets/icons/alert.svg" alt="alert"/>
            </a>
          </li>

          <li class="dropdown con-coins">
            <a class="nav-link a-btn" href="#" data-bs-toggle="dropdown">
              <img src="/assets/icons/coinsnew.svg" alt=""/>
              <span style="margin: 0 8px">{{ currentUserInfo?.coinBalance || 0 }}</span>
              <i class="bi bi-chevron-down"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-end ul-menu-coins">
              <li *ngFor="let item of coinsMenu">
                <a class="dropdown-item" href="#">
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </li>

          <li class="con-avatar-2">
            <div class="dropstart">
              <a class="nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                <img [src]="currentUser.profile?.picture || 'assets/images/avatar.svg'"
                    class="avatar-image shadow-sm"
                    alt="Avatar"
                    (error)="checkUrlAvatar($event)"/>
              </a>
              <ul class="dropdown-menu ul-profile">
                <li>
                  <p (click)="goLink('/profile#my-profile'); closeCollapse()">
                    {{ currentUser.profile?.firstName
                      ? currentUser.profile?.firstName +
                      " " +
                      currentUser.profile?.lastName
                      : "ไม่มีขื่อ" }}
                  </p>
                  <p>
                    ID: {{ currentUser.profile?.userDisplayId || "########" }}
                  </p>
                </li>
                <li *ngFor="let item of profileMenu" class="con-sub-menu">
                  <a class="dropdown-item" (click)="goLink(item.link); closeCollapse()">
                    <span>
                      <img [src]="item.icon" style="margin-right: 8px"/>
                      {{ item.name }}
                    </span>
                  </a>
                </li>
                <li (click)="logOut()">
                  <a> ออกจากระบบ</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <ul class="navbar-nav ul-navbar">
          <li class="nav-item dropdown has-megamenu">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
              ใบจอง
            </a>
            <div class="dropdown-menu megamenu" role="menu">
              <ul class="ul-booking-menu">
                <li *ngFor="let item of bookingMenu">
                  <a (click)="goLink(item.link); closeCollapse()">
                    <p class="titile">{{ item.name }}</p>
                    <p class="descrip">{{ item.description }}</p>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item dropdown has-megamenu">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
              มาร์เก็ตเพลซ
            </a>
            <div class="dropdown-menu megamenu" role="menu">
              <ul class="ul-marketplace-menu">
                <li *ngFor="let item of marketplaceMenu">
                  <a (click)="goLink(item.link); closeCollapse()">
                    <p class="titile">{{ item.name }}</p>
                    <p class="descrip">{{ item.description }}</p>
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item active">
            <a class="nav-link" href="/news"> ข่าวสาร </a>
          </li>
        </ul>

        <ul class="navbar-nav ms-auto con-left" *ngIf="!currentUser">
          <div class="con-no-login">
            <app-auth-signin-modal></app-auth-signin-modal>
          </div>
          <div class="con-no-login" style="margin-left: 8px">
            <a class="register" (click)="goLink('auth/sign-up')">
              <p>ลงทะเบียน</p>
            </a>
          </div>
        </ul>

        <ul class="navbar-nav ms-auto con-left" *ngIf="currentUser">
          <li class="nav-item con-icons">
            <a class="nav-link" (click)="getReadNotificationAll()">
              <!-- Notification -->
              <app-navbar-notification [resBadge]="resBadge" [notiList]="notiList"></app-navbar-notification>
              <!--/ Notification -->
            </a>
          </li>
          <li class="nav-item dropdown con-coins">
            <a class="nav-link a-btn" href="#" data-bs-toggle="dropdown">
              <img src="/assets/icons/coinsnew.svg" alt=""/>
              <span style="margin: 0 8px">{{ currentUserInfo?.coinBalance || 0 | numberTh }}</span>
              <i class="bi bi-chevron-down"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a class="dropdown-item" (click)="goLink(item.link); closeCollapse()" *ngFor="let item of coinsMenu">
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item con-avatar dropdown">
            <a class="nav-link" href="#" data-bs-toggle="dropdown">
              <img [src]="currentUser.profile?.picture || 'assets/images/avatar.svg'"
                  class="avatar-image shadow-sm"
                  alt="Avatar"
                  (error)="checkUrlAvatar($event)" />
            </a>
            <ul class="dropdown-menu dropdown-menu-end menu-profile">
              <li class="con-userid">
                <p (click)="goLink('/profile#my-profile'); closeCollapse()">
                  {{ currentUser.profile?.firstName
                    ? currentUser.profile?.firstName +
                    " " +
                    currentUser.profile?.lastName
                    : "ไม่มีขื่อ" }}
                </p>
                <p>
                  ID: {{ currentUser.profile?.userDisplayId || "########" }}
                </p>
              </li>
              <li *ngFor="let item of profileMenu" class="con-sub-menu">
                <a class="dropdown-item" (click)="goLink(item.link); closeCollapse()">
                  <span>
                    <img [src]="item.icon" style="margin-right: 8px"/>
                    {{ item.name }}
                  </span>
                </a>
              </li>
              <li class="li-checkout" (click)="logOut()">
                <a>ออกจากระบบ</a>
              </li>
            </ul>
          </li>

          <li class="nav-item con-btn-shop" (click)="goLink('/marketplace/create-product')">
            <a class="nav-link btn-shop"> ลงขาย </a>
          </li>
        </ul>

        <ul *ngIf="!currentUser" class="con-login-2 p-2">
          <div class="con-no-login">
            <app-auth-signin-modal></app-auth-signin-modal>
          </div>
          <div class="con-no-login" style="margin-left: 8px">
            <a class="register" (click)="goLink('auth/sign-up')">
              <p>ลงทะเบียน</p>
            </a>
          </div>
        </ul>
      </div>
      <!-- navbar-collapse.// -->
    </div>
    <!-- container-fluid.// -->
  </nav>
</div>
