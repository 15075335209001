<div
  #carousel
  id="carouseBooking"
  class="carousel"
  data-bs-ride="carousel"
>
  <div class="carousel-inner" #carouselInner>
    <div
      class="carousel-item"
      *ngFor="let item of data?.results; let i = index"
    >
      <div class="card" (click)="goProductDetail(item?.id)">
        <div class="con-img">
          <div class="time">เปิดให้จอง {{ item?.startDate | dateTh }}</div>

          <img
            [src]="
              item?.images[0] || '/assets/icons/marketplace/photo-daft.svg'
            "
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="card-body">
          <h5 class="card-title">{{ item?.name || "-" }}</h5>
          <p class="card-text">
            {{ item?.description || "-" }}
          </p>

          <div class="con-manufacturer">
            <i class="bi bi-geo-alt-fill"></i>
            {{ item?.manufacturer?.name || "-" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    class="carousel-control-prev"
    type="button"
    data-bs-target="#carouseBooking"
    data-bs-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    class="carousel-control-next"
    type="button"
    data-bs-target="#carouseBooking"
    data-bs-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
