import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-card-product',
  templateUrl: './card-product.component.html',
  styleUrls: ['./card-product.component.scss']
})
export class CardProductComponent {

  @Input() item: any;
  @Input() typeData = 'lot';

  constructor(
    private router: Router
  ) {

  }


  goProductDetail(id: string) {
    if (!id) return;
    this.router.navigate(['booking/detail/', id]);
  }

}
