<div class="new-product" *ngIf="!isLoading">
  <div class="product-l" *ngIf="!data?.results?.length">
    <div class="card-product">
      <div class="con-img">
        <img [src]="'assets/icons/langka-logo.png'" alt="LANGKA AMULET"/>
      </div>
      <div class="text-langka">
        ลังกา อมูเลท
      </div>
      <div class="text-a">
        เช่า บูชา
      </div>
      <div class="text-b">
        วัตถุมงคล
      </div>
    </div>
    <div class="card-product">
      <div class="text-langka">
        วัตุมงคลใหม่กำลังมา
      </div>
      <div class="text-a">
        เปิดจองพร้อมกัน
      </div>
      <div class="text-b">
        เร็ว ๆ นี้
      </div>
    </div>
  </div>

  <div class="product-l" *ngIf="data?.results?.length === 1">
    <div class="card-product">
      <div class="con-img">
        <img [src]="'assets/icons/langka-logo.png'" alt="LANGKA AMULET"/>
      </div>
      <div class="text-langka">
        ลังกา อมูเลท
      </div>
      <div class="text-a">
        เช่า บูชา
      </div>
      <div class="text-b">
        วัตถุมงคล
      </div>
    </div>
    <div class="random-product">
      <app-card-new-product [item]="data?.results[0]"></app-card-new-product>
    </div>
  </div>

  <div class="product-l" *ngIf="data?.results.length > 1">
    <div class="random-product" *ngFor="let item of randomData">
      <app-card-new-product [item]="item"></app-card-new-product>
    </div>
  </div>
</div>

<div class="new-product" *ngIf="isLoading">
  <div class="product-l">
    <div class="random-product">
      <div class="card-new-product">
        <div class="con-img">
          <ngx-skeleton-loader [theme]="{ width: '180px',height: '240px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
        </div>
        <div class="con-detail">
          <div class="title-dt">
            <ngx-skeleton-loader [theme]="{ width: '140px',height: '18px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
          </div>
          <div class="text-dt">
            <ngx-skeleton-loader [theme]="{ width: '140px',height: '14px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '140px',height: '14px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '140px',height: '14px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '140px',height: '14px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
          </div>
          <div class="location-dt">
              <span>
                <ngx-skeleton-loader [theme]="{ width: '180px',height: '18px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
              </span>
          </div>
        </div>
      </div>
    </div>
    <div class="random-product">
      <div class="card-new-product">
        <div class="con-img">
          <ngx-skeleton-loader [theme]="{ width: '180px',height: '240px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
        </div>
        <div class="con-detail">
          <div class="title-dt">
            <ngx-skeleton-loader [theme]="{ width: '140px',height: '18px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
          </div>
          <div class="text-dt">
            <ngx-skeleton-loader [theme]="{ width: '140px',height: '14px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '140px',height: '14px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '140px',height: '14px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '140px',height: '14px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
          </div>
          <div class="location-dt">
              <span>
                <ngx-skeleton-loader [theme]="{ width: '140px',height: '18px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
