import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// model
import { LotsBooking } from '@models/booking.model';

@Component({
  selector: 'app-card-new-product',
  templateUrl: './card-new-product.component.html',
  styleUrls: ['./card-new-product.component.scss']
})

export class CardNewProductComponent implements OnInit {

  @Input() item: LotsBooking | any;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      // console.debug('item', this.item)
    }, 1000)
  }

  goProductDetail(id: string) {
    if (!id) return;
    this.router.navigate(['booking/detail/', id]);
  }
}
