import {Inject, Injectable, InjectionToken} from '@angular/core';
import {ResolveEnd, Router} from '@angular/router';

import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

import * as _ from 'lodash';

// Injection token for the core custom settings
export const CORE_CUSTOM_CONFIG = new InjectionToken('coreCustomConfig');

@Injectable({
  providedIn: 'root'
})
export class CoreConfigService {
  // Private
  public localConfig: any;
  private readonly _defaultConfig: any;
  private _configSubject: BehaviorSubject<any> | any;

  /**
   * Constructor
   *
   * @param _config
   * @param {Router} _router
   */
  constructor(private _router: Router, @Inject(CORE_CUSTOM_CONFIG) public _config: any) {
    // Get the config from local storage
    const localStorageConfig = localStorage.getItem('config');
    if (_config.layout.enableLocalStorage && localStorageConfig) {
      this.localConfig = JSON.parse(localStorageConfig);
    } else {
      localStorage.removeItem('config');
    }

    // Set the defaultConfig to localConfig if we have it, otherwise use appConfig (app-config.ts)
    this._defaultConfig = this.localConfig ? this.localConfig : _config;

    // Initialize the config service
    this._initConfig();
  }

  //  Accessors
  // -----------------------------------------------------------------------------------------------------

  // Set the config
  set config(data) {
    let config;

    // Set config = localConfig, If we have else defaultConfig
    if (this.localConfig) {
      config = this.localConfig;
    } else {
      config = this._configSubject.getValue();
    }

    // Merge provided data with config, and create new merged config
    config = _.merge({}, config, data);

    // Set config to local storage if enableLocalStorage parameter is true
    if (config.layout.enableLocalStorage) {
      localStorage.setItem('config', JSON.stringify(config));
    }

    // Inform the observers
    this._configSubject.next(config);
  }

  // Get the config
  get config(): any | Observable<any> {
    return this._configSubject.asObservable();
  }

  /**
   * Get default config
   *
   * @returns {any}
   */
  get defaultConfig(): any {
    return this._defaultConfig;
  }

  // Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initialize
   *
   * @private
   */
  private _initConfig(): void {
    // Set the config from the default config
    this._configSubject = new BehaviorSubject(_.cloneDeep(this._defaultConfig));

    // On every RoutesRecognized event
    // Check if localDefault (localStorage if we have else defaultConfig) is different from the default one
    this._router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe(() => {
      // Get the local config from local storage
      const configFromLocalStorage = localStorage.getItem('config');

      if (configFromLocalStorage) {
        try {
          this.localConfig = JSON.parse(configFromLocalStorage);

          // Set localDefault to localConfig if we have else defaultConfig
          let localDefault = this.localConfig ? this.localConfig : this._defaultConfig;

          // If localDefault is different from the provided config (page config)
          if (!_.isEqual(this._configSubject.getValue().layout, localDefault.layout)) {
            // Clone the current config
            const config = _.cloneDeep(this._configSubject.getValue());

            // Reset the layout from the default config
            config.layout = _.cloneDeep(localDefault.layout);

            // Set the config
            this._configSubject.next(config);
          }
        } catch (error) {
          console.error('Error parsing JSON from local storage:', error);
        }
      }
    });
  }

  // Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set config
   *
   * @param data
   * @param {{emitEvent: boolean}} param
   */
  setConfig(data: any, param: { emitEvent: boolean; } = {emitEvent: true}): void {
    let config;

    // Set config = localConfig, If we have else defaultConfig
    const storedConfig = localStorage.getItem('config');
    if (storedConfig) {
      try {
        config = JSON.parse(storedConfig);
      } catch (error) {
        console.error('Error parsing storedConfig:', error);
      }
    } else {
      config = this._configSubject.getValue();
    }

    // Merge provided value with config, and create new merged config
    config = _.merge({}, config, data);

    // Set config to local storage if enableLocalStorage parameter is true
    if (config.layout.enableLocalStorage) {
      localStorage.setItem('config', JSON.stringify(config));
    }

    // If emitEvent option is true...
    if (param.emitEvent) {
      // Inform the observers
      this._configSubject.next(config);
    }
  }

  /**
   * Get config
   *
   * @returns {Observable<any>}
   */
  getConfig(): Observable<any> {
    return this._configSubject.asObservable();
  }

  /**
   * Reset to the default config
   */
  resetConfig(): void {
    this._configSubject.next(_.cloneDeep(this._defaultConfig));
  }


  
}
