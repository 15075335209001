import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";

// model
import {CoreCommonModule} from 'src/app/@core/common.module';
import {AuthModalModule} from './features/auth/auth.module';
import {MiscellaneousModule} from './miscellaneous/miscellaneous.module';
// component

@NgModule({
  providers: [],
  declarations: [
    // component

  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    // model
    CoreCommonModule,
    AuthModalModule,
    MiscellaneousModule

    // component
  ],
  exports: []
})

export class PagesModule {
}
