import { NgModule } from '@angular/core';

import { FeatherIconDirective } from './core-feather-icons/core-feather-icons';


@NgModule({
  declarations: [FeatherIconDirective],
  exports: [FeatherIconDirective]
})
export class CoreDirectivesModule {}
