import { Component } from '@angular/core';
import { MarketplaceService } from '@services/api/marketplace.service';
import { GetResponseProductMarketplace } from '@models/marketplace.model';
import { Logger } from '@services/logger.service';
import { AuthService } from '@app/auth/auth.service';
import { SharedDataService } from '@app/services/shared-data.service';
import { UserCurrent, UserLogin, UserProfile } from '@app/models';
const log = new Logger('SectionNewUpdateMarketplaceComponent');
@Component({
  selector: 'app-section-new-update-marketplace',
  templateUrl: './section-new-update-marketplace.component.html',
  styleUrls: ['./section-new-update-marketplace.component.scss']
})
export class SectionNewUpdateMarketplaceComponent {
  public currentUser: UserCurrent | undefined;
  public currentUserInfo!: UserProfile;
  dataProductMarketplace: GetResponseProductMarketplace | undefined;

  constructor(
    private marketplaceService: MarketplaceService,
    private _authService: AuthService,
    private sharedDataService: SharedDataService,
  ) {
    this._authService.currentUser.subscribe((current) => {
      if (current) {
          this.currentUser = current;

      } else {
          this.currentUser = undefined;
      }
  })

  }

  ngOnInit() {
    setTimeout(() => {
      this.currentUserInfo = this.sharedDataService.getDataUserCurrentInfo()
    }, 1000)
    this.fetchProductMarketplace();
  }


  async fetchProductMarketplace() {
    try {

      this.dataProductMarketplace = await this.marketplaceService.getProductRecommend({ page: 1, limit: 14 })
      log.debug('product mk', this.dataProductMarketplace);
    } catch (error) {
      log.error('error get product markeplace', error)
    }
  }

}
