<div class="card-product shadow p-2 m-1 rounded" (click)="goProductDetail(item?.id)">
  <div class="con-img">
    <img
      [src]="item?.images[0] || '/assets/icons/marketplace/photo-daft.svg'"
    />
  </div>
  <div class="con-detail">
    <p class="time">ปิดใน {{ item?.endDate | date:'fullDate' }} ชั่วโมง </p>
    <p class="name">{{ item.name }}</p>
    <p class="menufactoer">
      <i data-feather="map-pin" class="mr-1"></i> {{ item?.manufacturerDetail[0]?.name || 'ไม่มีชื่อ'}}
    </p>
  </div>
</div>
