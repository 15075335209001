import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import {LotsBooking} from '@models/booking.model';

@Component({
  selector: 'app-card-product-booking',
  templateUrl: './card-product-booking.component.html',
  styleUrls: ['./card-product-booking.component.scss']
})

export class CardProductBookingComponent implements OnInit {

  @Input() item: LotsBooking | any;
  @Input() typeData = 'lot';

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
    console.debug('item', this.item)
  }

  goProductDetail(id: string) {
    if (!id) return;
    this.router.navigate(['booking/detail/', id]);
  }
}
