import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {GetResponseBanner} from '@models/banner.model';

@Injectable({
  providedIn: 'root'
})

export class BannerService {

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  async getBanners(page: number, limit: number): Promise<GetResponseBanner | any> {
    return await this.httpClient
      .get<GetResponseBanner | any>(environment.api + `/banners?page=${page}&limit=${limit}&sortBy=sequence:asc`)
      .toPromise()
      .then(response => response);
  }
}
