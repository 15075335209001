export const environment = {
  production: false,
  webVersion: '1.0',

  api: 'https://uat.langkaamulet.app/v1',
  linkPlayStore: 'https://play.google.com/store/apps/details?id=langka.amulet.app',
  linkAppStore: 'https://apps.apple.com/th/app/langka-amulet-application/id6444536251',


  showMarketplace: true,
  openLangkaOfficial: true,
  GoogleClientID: '180239957477-nb2u8u7ctsnmuru9gcppgt9rsmhct906.apps.googleusercontent.com',

};


