<div class="con-card shadow-sm" *ngIf="!isLoading">
<!--   [ngClass]="item?.isBuyPolicy ? 'isSell' : ''" -->
<!--  <img [src]="items?.images[0] || 'assets/icons/marketplace/photo-daft.svg'"-->
<!--       alt="IMAGES"-->
<!--       (click)="goProductDetail(items?._id || items?.id)" /> -->

  <img [defaultImage]="'assets/icons/svg/loading-spin.svg'" [lazyLoad]="items?.images[0] || 'assets/icons/marketplace/photo-daft.svg'"
       src="" alt="IMAGES"
       (click)="goProductDetail(items?._id || items?.id)"/>

  <div class="con-flex">
    <h3 class="name">
      {{ items?.name || "ไม่มีชื่อ" }}
      <div class="con-vip" *ngIf="items?.package && items?.package?.isActivated">
        <img [src]="'assets/icons/svg/Crown.svg'" alt="IMAGES"/>
        VIP {{ items?.package?.vip }}
      </div>
    </h3>
    <div class="con-detail">
      <p>
        <i data-feather="map-pin" class="mr-1"></i>
        <!-- {{item.description}} -->
        {{ items?.province || "ไม่มีสถานที่" }}
      </p>

      <div class="con-coin">
        <img [src]="'assets/icons/coinsnew.svg'" alt="IMAGES"/>
        <h6>{{ items?.price || 0 | numberTh }}</h6>
      </div>
    </div>
  </div>
  <div class="con-contact">
    <button
        [disabled]="
        !currentUser ||
        (items?.applicant?.profile?.userDisplayId ||
          items?.applicant[0]?.profile?.userDisplayId) ===
          currentUser.profile?.userDisplayId ||
        items?.applicant === currentUser.id ||
        items?.applicant === currentUser.id
      "
        (click)="goChatMarket(items?.id || items?._id)"
    >
      <i data-feather="message-circle" class="mr-1"></i>
      แชท
    </button>
    <button (click)="makePhoneCall(items?.mobilePhone)">
      <i data-feather="phone" class="mr-1"></i>
      โทร
    </button>
  </div>
  <div class="badge-1" *ngIf="items?.isBargain">โชว์พระ</div>
  <!-- <div class="badge-isSell" >ขายแล้ว</div> -->
  <div class="con-i">
    <i data-feather="alert-circle" class="mr-1"></i>
  </div>
</div>

<div class="con-card shadow-sm" *ngIf="isLoading">
  <ngx-skeleton-loader [theme]="{ width: '150px',height: '100px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
  <div class="con-flex">
    <ngx-skeleton-loader [theme]="{ width: '100px',height: '18px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
    <div class="con-detail">
      <p>
        <ngx-skeleton-loader [theme]="{ width: '100px',height: '18px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
      </p>

      <div class="d-flex justify-content-end">
        <div>
          <ngx-skeleton-loader [theme]="{ width: '60px',height: '18px','border-radius': '0','margin-bottom': '0' }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
  <div class="con-contact">
    <div>
      <ngx-skeleton-loader [theme]="{ width: '60px',height: '25px','border-radius': '15px','margin-bottom': '0' }"></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader [theme]="{ width: '60px',height: '25px','border-radius': '15px','margin-bottom': '0' }"></ngx-skeleton-loader>
    </div>
  </div>
</div>
