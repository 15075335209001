import { Component, Input } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-card-download-app',
  templateUrl: './card-download-app.component.html',
  styleUrls: ['./card-download-app.component.scss']
})
export class CardDownloadAppComponent {

  @Input() typeLink = 'play-store'

  constructor(){

  }



  goToPlayStore() {
    const url = `${environment.linkPlayStore}`;
    window.open(url, '_blank');
}

  goToAppStore() {
    const url = `${environment.linkAppStore}`;
    window.open(url, '_blank');
}
}
