import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { environment } from '@environments/environment';

declare var google: any;

// import {
//   FacebookLoginProvider,
//   SocialAuthService,
// } from '@abacritt/angularx-social-login';

import { Logger } from '@services/logger.service';
const log = new Logger('GoogleLoginComponent');

type GoogleAuthUser = {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
};



@Component({
  selector: 'app-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.scss']
})


export class GoogleLoginComponent implements OnInit {

  @Output() onSignIn = new EventEmitter<any>();

  constructor(
    private el: ElementRef, private ngZone: NgZone,
    private _authService: AuthService,
  ) {

  }

  ngOnInit(): void {
    this.initializeGoogleSignIn();
  }

  private initializeGoogleSignIn(): void {
    google.accounts.id.initialize({
      client_id: environment.GoogleClientID,
      callback: this.handleCredentialResponse.bind(this)
    });

    google.accounts.id.renderButton(this.el.nativeElement, {
      theme: 'outline',
      size: 'large'
    });

    google.accounts.id.prompt();
  }

  private handleCredentialResponse(response: any): void {
    this.ngZone.run(async () => {
      log.debug('Encoded JWT ID token: ', response);
      const dataTemp = {
        jwt: response.credential
      }
      this._authService.checkUserLoginWithGoogle(dataTemp).then((res) => {
        this.onSignIn.emit(res);
      })



    });
  }


}
