import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";



// ng-bootstrap
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

// service
import { AuthService } from "@auth/auth.service";
import { Logger } from "@services/logger.service";
import { ModalService } from "@services/modal.service";
import { BlockUiService } from '@app/services/block-ui.service';
import { ToastService } from '@app/services/toast.service';

// service api
import { UserService } from '@app/services/api/user.service';
import { MustMatch } from 'src/helper/must-match.validator';
import { Router } from '@angular/router';

const log = new Logger('ForgotPasswordModalComponent');
@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent {


  @Input() email: string | undefined;
  @Input() isAgree: boolean = false;
  @Output() onClickBtn = new EventEmitter<string>();
  @ViewChild('otp1') firstOtp: ElementRef<HTMLInputElement> | undefined;


  public resetPassWordModal: any;

  public passwordTextType: boolean = false;
  public confPasswordTextType: boolean = false;

  public otpEmailDigitForm: FormGroup | any;
  public resetPasswordForm: FormGroup | any;
  public fetchRequestEmail: any;
  public submitBnt = false;

  public countdown: number = 15;
  public intervalCountdown: any;

  constructor(
    private _modal: NgbModal,
    private _authService: AuthService,
    private _userService: UserService,
    private _modalService: ModalService,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private _loadingService: BlockUiService,
    private _toastService: ToastService,
    private router: Router,


  ) {
  }

  ngOnInit() {
    this.otpEmailDigitForm = this.formBuilder.group({
      otpDigit1: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit2: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit3: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit4: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit5: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit6: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
    });

    this.resetPasswordForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(16),
          Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(16),
          Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        ],
      ],
    },
      {
        validator: MustMatch('password', 'confirmPassword'),
      });
  }

  ngAfterViewInit() {
    if (this.firstOtp) {
      this.renderer.selectRootElement(this.firstOtp.nativeElement).focus();
    }
  }

  async modalOpenResetPassWord(modal: any) {
    this._loadingService.startBlocking('กำลังส่งขอ OTP Email')
    const checkEmail = await this.requestOTPForgetPassword();
    if (!checkEmail) return;
    this.countdown = 15;
    this.startCountdown();
    this.resetPassWordModal = this._modal.open(modal, {
      size: 'lg', backdrop: 'static',
      centered: true
    });

    this._loadingService.stopBlocking()
  }


  get rfc() {
    return this.resetPasswordForm?.controls;
  }


  async checkOTP() {
    const { otpDigit1, otpDigit2, otpDigit3, otpDigit4, otpDigit5, otpDigit6 } = this.otpEmailDigitForm.value;
    const code = `${otpDigit1}${otpDigit2}${otpDigit3}${otpDigit4}${otpDigit5}${otpDigit6}`
    if (this.otpEmailDigitForm.invalid) return;
    if (String(this.fetchRequestEmail) === code) {
      this.submitBnt = true;
    } else {
      this._toastService.showError({ title: 'กรอก OTP ไม่ถูกต้อง' })
      this.submitBnt = false;
    }
  }

  otpController(event: any, next: any, prev: any) {
    if (event.target.value.length < 1 && prev) {
      prev.focus();
    } else if (next && event.target.value.length > 0) {
      next.focus();
    } else {
      return;
    }
  }

  async resend() {
    this.clearInput();
    this._loadingService.startBlocking('กำลังส่งขอ OTP Email')
    await this.requestOTPForgetPassword();
    this.countdown = 15;
    this.startCountdown();

  }

  clearInput() {
    this.clearOtpInputs()
    if (this.firstOtp) {
      this.renderer.selectRootElement(this.firstOtp.nativeElement).focus();
    }
  }

  clearOtpInputs() {
    Object.keys(this.otpEmailDigitForm.controls).forEach(controlName => {
      this.otpEmailDigitForm.get(controlName)?.setValue('');
      this.otpEmailDigitForm.get(controlName)?.markAsPristine();
      this.otpEmailDigitForm.get(controlName)?.markAsUntouched();
      this.otpEmailDigitForm.get(controlName)?.updateValueAndValidity();
    });
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  toggleConfPasswordTextType() {
    this.confPasswordTextType = !this.confPasswordTextType;
  }

  async openModalConfirm(status: any, title: string, detail: string) {
    if (status === 'success') {
      await this._modalService.openModalConfirm({
        title: title,
        detail: detail,
        status: 'success'
      })
    } else if (status === 'error') {
      await this._modalService.openModalConfirm({
        title: 'เกิดข้อผิดพลาด',
        detail: 'กรุณาตรวจสอบข้อมูลความถูกต้องอีกครั้ง.',
        status: 'error'
      })
    }
  }

  async requestOTPForgetPassword(): Promise<any> {
    if (!this.email) {
      this._loadingService.stopBlocking()
      return
    }
    const data = {
      email: this.email
    }
    try {
      const res = await this._userService.forgotPassword(data);
      if (res) this.fetchRequestEmail = res.localEmail.resetPasswordOtp
      log.debug('dddd',res)
      this._loadingService.stopBlocking()
      return true;
    } catch (error) {
      this._loadingService.stopBlocking()
      this._toastService.showError({ title: ' Email ของคุณไม่ถูกต้อง' })
      this.fetchRequestEmail = null
      return false;
    }
  }

  async confirmNewPassword() {
    if (!this.email || !this.fetchRequestEmail) return;
    if (await this._modalService.openModalConfirm({
      title: 'ยืนยันเปลี่ยนรหัสผ่าน',
    }) === false) {
      return;
    }
    this._loadingService.startBlocking('กำลังเปลี่ยนรหัสผ่าน')

    const data = {
      email: this.email,
      password: this.resetPasswordForm.value.password,
      confirmPassword: this.resetPasswordForm.value.confirmPassword
    };
    try {
      const res = await this._userService.resetPassword(data, this.fetchRequestEmail)

      if (res) {
        this._toastService.showSuccess({ title: 'เปลี่ยนรหัสผ่านเรียบร้อย' })
        this._loadingService.stopBlocking()
        this.closeModal()
        await this.router.navigate(['/home']);
      }
    } catch (error) {
      log.error('reset password', error);
      this._toastService.showError({ title: 'เกิดข้อผิดพลาด' })
    }
    this._loadingService.stopBlocking()

  }


  startCountdown() {
    this.intervalCountdown = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(this.intervalCountdown);
      }
    }, 1000);
  }

  closeModal() {
    this.submitBnt = false;
    this.clearInput()
    clearInterval(this.intervalCountdown);
    this.resetPassWordModal.close()
  }



}
