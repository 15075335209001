<div class="card-product" (click)="goProductDetail(item?.id)">
  <div class="con-img">
    <img
        [defaultImage]="'assets/icons/svg/loading-spin.svg'"
        [lazyLoad]="item?.images[0] || 'assets/icons/marketplace/photo-daft.svg'"
        [src]="''"
        [title]="item?.name || '-'"
        alt="Langka Amulet"
    />
    <div class="con-baged">
      <div class="text-a">แนะนำ</div>
    </div>
    <div class="cover-label">
      <div class="time"> เปิดจอง {{ item?.startDate | dateTh }}</div>
    </div>
  </div>
  <div class="con-detail">
    <div class="title-dt" placement="auto" ngbTooltip="{{ item?.name || '-' }}">{{ item?.name || '-' }}</div>
    <div class="text-dt">{{ item?.description || '-' }}</div>
    <div class="location-dt">
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <g clip-path="url(#clip0_1106_22996)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.65625 5.32165C2.65625 2.7829 4.85716 0.78125 7.5 0.78125C10.1428 0.78125 12.3438 2.7829 12.3438 5.32165C12.3438 7.70361 10.8669 10.5 8.48424 11.5183C7.85872 11.7856 7.14128 11.7856 6.51576 11.5183C4.13305 10.5 2.65625 7.70361 2.65625 5.32165ZM7.5 1.71875C5.31034 1.71875 3.59375 3.36298 3.59375 5.32165C3.59375 7.40186 4.90964 9.81238 6.88416 10.6562C7.27438 10.8229 7.72562 10.8229 8.11584 10.6562C10.0904 9.81238 11.4062 7.40186 11.4062 5.32165C11.4062 3.36298 9.68965 1.71875 7.5 1.71875Z" fill="#4F4C4B"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 4.84375C7.06853 4.84375 6.71875 5.19353 6.71875 5.625C6.71875 6.05647 7.06853 6.40625 7.5 6.40625C7.93147 6.40625 8.28125 6.05647 8.28125 5.625C8.28125 5.19353 7.93147 4.84375 7.5 4.84375ZM5.78125 5.625C5.78125 4.67576 6.55076 3.90625 7.5 3.90625C8.44924 3.90625 9.21875 4.67576 9.21875 5.625C9.21875 6.57424 8.44924 7.34375 7.5 7.34375C6.55076 7.34375 5.78125 6.57424 5.78125 5.625Z" fill="#4F4C4B"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.24713 9.37287C2.4209 9.56477 2.4062 9.8612 2.2143 10.035C1.85949 10.3563 1.71875 10.6638 1.71875 10.9375C1.71875 11.4148 2.17126 12.003 3.27294 12.4988C4.33063 12.9747 5.8247 13.2813 7.5 13.2813C9.1753 13.2813 10.6694 12.9747 11.7271 12.4988C12.8287 12.003 13.2812 11.4148 13.2812 10.9375C13.2812 10.6638 13.1405 10.3563 12.7857 10.035C12.5938 9.8612 12.5791 9.56477 12.7529 9.37287C12.9266 9.18097 13.2231 9.16627 13.415 9.34004C13.8918 9.77186 14.2188 10.3132 14.2188 10.9375C14.2188 12.0135 13.2721 12.8315 12.1118 13.3537C10.9074 13.8957 9.27648 14.2188 7.5 14.2188C5.72352 14.2188 4.09259 13.8957 2.88822 13.3537C1.72785 12.8315 0.78125 12.0135 0.78125 10.9375C0.78125 10.3132 1.10815 9.77186 1.58503 9.34004C1.77693 9.16627 2.07336 9.18097 2.24713 9.37287Z" fill="#4F4C4B"/>
        </g>
        <defs>
          <clipPath id="clip0_1106_22996">
            <rect width="15" height="15" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <span>{{ item?.manufacturer?.name || '-' }}</span>
    </div>
  </div>
</div>
