import { Component } from '@angular/core';
import { MarketplaceService } from '@services/api/marketplace.service';
import { GetResponseRecommendShop } from '@models/recommend-shop.model';
import { Logger } from '@services/logger.service';
import { UserService } from '@app/services/api/user.service';
const log = new Logger('SectionRecommendedShopComponent');
@Component({
  selector: 'app-section-recommended-shop',
  templateUrl: './section-recommended-shop.component.html',
  styleUrls: ['./section-recommended-shop.component.scss']
})
export class SectionRecommendedShopComponent {

  dataRecommendShop: GetResponseRecommendShop | any;

  constructor(
    private marketplaceService: MarketplaceService,
    private _userService: UserService,
  ) {

  }

  ngOnInit() {
    this.getRecommend();
  }

  async getRecommend() {
    try {

      this.dataRecommendShop = await this.marketplaceService.getRecommendedAdvertise({ page: 1, limit: 10 })
      log.debug('data Recommend ', this.dataRecommendShop);
    } catch (error) {
      log.error('get Recommend Error', error)
    }
  }


  // async fetchProfileShop(shopId:string) {
  //   try {
  //     const res = await this._userService.getUserById(shopId);
  //     if (res) {
  //       this.shopProfile = res;
  //       if (this.shopProfile?.shop) this.shopDetail = this.shopProfile.shop;
  //     }

  //     log.debug('res profile shop', res);
  //     log.debug('res shopDetail ', this.shopDetail);
  //   } catch (error) {
  //     log.error('fetch profile shop', error)
  //   }
  // }

}
