import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";

// service
import { ImageDefaultService } from "@services/image-default.service";

@Component({
  selector: 'app-card-banner',
  templateUrl: './card-banner.component.html',
  styleUrls: ['./card-banner.component.scss']
})

export class CardBannerComponent implements OnInit {

  @Input() item: any;

  constructor(
    private _imageDefaultService: ImageDefaultService,
    private router: Router
  ) {
  }

  ngOnInit() {
    // console.debug('item', this.item)
  }

  goToLink(link: any) {
    // console.debug('link', link)
    if (link.linkType === 'external') {
      this.router.navigate(['news/detail/', link?.linkUrl], {queryParams: {title: link?.name}});
    } else if (link.linkType === 'internal') {
      this.router.navigate(['booking/detail/', link?.linkUrl], {queryParams: {title: link?.name}});
    } else {
      return;
    }
  }

  async checkUrlImageDraft(event: any) {
    await this._imageDefaultService.checkUrlDraft(event);
  }
}
