import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GalleryItem, ImageItem } from 'ng-gallery';

// service
import { ModalService } from '@app/services/modal.service';
import { SharedModalService } from '@app/@shared/shared-modal.service';
import { Logger } from "@services/logger.service";

const log = new Logger('SlideImagesModalComponent');

@Component({
  selector: 'app-slide-images-modal',
  templateUrl: './slide-images-modal.component.html',
  styleUrls: ['./slide-images-modal.component.scss'],
})

export class SlideImagesModalComponent implements OnInit {

  @ViewChild('modalSlideImages', {static: true}) modalSlideImagesTemplate!: TemplateRef<any>;

  isMobile: boolean = window.innerWidth <= 992;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobile = window.innerWidth <= 992;
  }

  // public
  public items: GalleryItem[] | any;
  public imageData: any;

  constructor(
    private modalService: ModalService,
    private sharedModalService: SharedModalService
  ) {
    this.modalService.modalSlideImagesData$.subscribe((data: any) => {
      if (data) {
        this.imageData = data;
        this.openImage()
      }
    })
  }

  ngOnInit() {
    this.sharedModalService.modalSlideImagesTemplate = this.modalSlideImagesTemplate;
  }

  openImage() {
    const resImg = Object.keys(this.imageData).map(key => {
      return {
        srcUrl: this.imageData[key],
        previewUrl: this.imageData[key]
      };
    });

    this.items = resImg.map((item: { srcUrl: any; previewUrl: any; }) => new ImageItem({
      src: item.srcUrl,
      thumb: item.previewUrl
    }));
  }
}


