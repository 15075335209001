import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// data
import { bookingMenu, coinsMenu, marketplaceMenu, profileMenu } from '@assets/data';

// ngx
import { TranslateService } from '@ngx-translate/core';

// model
import { UserCurrent, UserLogin } from '@models/user.model';

// service
import { AuthService } from '@auth/auth.service';
import { CoreConfigService } from 'src/app/@core/services/config.service';
import { ImageDefaultService } from '@app/services/image-default.service';
import { Logger } from '@app/services/logger.service';
import { ModalService } from '@app/services/modal.service';
import { SharedDataService } from '@app/services/shared-data.service';
import { UpdateService } from '@app/services/update.service';

// service api
import { NotificationService } from "@api/notification.service";
import { UserService } from '@app/services/api/user.service';

import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import * as _ from 'lodash';

const log = new Logger('HeaderComponent')

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('mainNavCollapse', {static: true}) mainNavCollapse!: ElementRef;

  public bookingMenu = bookingMenu
  public coinsMenu = coinsMenu
  public marketplaceMenu = marketplaceMenu
  public profileMenu = profileMenu

  public currentPage = {
    page: 1,
    limit: 10,
    totalResults: 0,
    totalPages: 1
  };

  public currentUser: UserCurrent | undefined;
  public currentUserInfo: any;
  public userID: any;

  public coreConfig: any;

  public languageOptions: any;
  public selectedLanguage: any;

  public isCollapsed = true;
  public isCollapsedLang = true;
  public isCollapsedUserProfile = true;

  public resBadge: any;
  public resNoti: any;
  public notiList: any;

  private _unsubscribeAll: Subject<any>;

  constructor(
    public _translateService: TranslateService,
    private _authService: AuthService,
    private _coreConfigService: CoreConfigService,
    private _imageDefaultService: ImageDefaultService,
    private _modalService: ModalService,
    private _sharedDataService: SharedDataService,
    private _updateService: UpdateService,
    private _notificationService: NotificationService,
    private _userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private elRef: ElementRef,
  ) {
    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us'
      },
      th: {
        title: 'ไทย',
        flag: 'th'
      },
    };

    this._unsubscribeAll = new Subject();

    this._authService.currentUser.subscribe(async (current) => {
      if (current) {
        this.currentUser = current;
        this.userID = current.id
        await this.getUserCurrentInfo();
      } else {
        this.currentUser = undefined;
      }
    })

    this._updateService.updateUser$.subscribe(async (e) => {
      if (e && e !== 'logout') {
        await this.getUserCurrentInfo()
      } else {
        this._sharedDataService.setDataUserCurrentInfo(null);
      }
    })
  }

  setLanguage(language: any): void {
    this.selectedLanguage = language;
    this._translateService.use(language);
    this._coreConfigService.setConfig({app: {appLanguage: language}}, {emitEvent: true});
  }

  ngOnInit(): void {
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
      this.coreConfig = config;
    });

    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });

    this.getNotificationBadge();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  async getUserCurrentInfo() {
    if (this.currentUser) {
      try {
        const res = await this._userService.getUserCurrentInfo();
        if (res) {
          this.currentUserInfo = res;
          this._sharedDataService.setDataUserCurrentInfo(res);
        }

        // log.debug('res user me', res)
      } catch (error) {
        log.error('res user me', error)
      }
    }
  }

  async logOut() {
    if (await this._modalService.openModalConfirm({
      title: 'ต้องการออกจากระบบ',
      detail: 'คุณต้องการออกจากระบบใช่หรือไม่ ?'
    })) {
      await this._authService.logout();
      this.isCollapsedUserProfile = true;
    }
  }

  closeCollapse(): void {
    const mainNav = this.mainNavCollapse.nativeElement;
    if (mainNav.classList.contains('show')) {
      mainNav.classList.remove('show');
    }
  }

  check() {
    console.log(this._authService.currentUserValue)
  }

  async goLink(link: string) {
    let mapLink = link
    if (link === 'marketplace/shop' && this.currentUser) mapLink = 'marketplace/shop/' + this.currentUser.id;

    const {path, fragment} = this.splitUrl(mapLink);
    if (!fragment) {
      await this.router.navigate([path]);
    } else {
      await this.router.navigate([path], {fragment: fragment});
    }
  }

  splitUrl(url: string): { path: string, fragment: string } {
    const parts = url.split('#');
    const path = parts[0];
    const fragment = parts[1] || '';

    return {path, fragment};
  }

  async getNotificationBadge() {
    if (!this.userID) {
      return;
    }

    try {
      this.resBadge = await this._notificationService.getNotificationBadge(this.userID)
      this._notificationService.pillBadgeSubject.next(this.resBadge?.count)
      // log.debug('resBadge', this.resBadge);
    } catch (error) {
      log.error('get notification badge error', error)
    }
  }

  async getReadNotificationAll() {
    if (!this.userID) {
      return;
    }

    try {
      const resNoti = await this._notificationService.readNotificationAllByUser({
        id: this.userID,
        page: this.currentPage.page,
        limit: this.currentPage.limit,
      });
      this.resNoti = resNoti;
      this.notiList = resNoti.results;

      await this.getNotificationBadge();
      // log.debug('res noti', this.resNoti);
      // log.debug('get noti', this.notiList);
    } catch (error) {
      log.error('get read notification all error', error)
    }
  }

  async checkUrlAvatar(event: any) {
    await this._imageDefaultService.checkUrlAvatar(event);
  }
}
