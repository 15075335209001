<!-- <ng-template #modalSignUp let-modal> -->
<div class="row mt-3">
  <div class="modal-left col-md-6">
    <picture>
      <img
        src="assets/images/banner-langka-login.png"
        alt="LOGIN LANGKA AMULET"
      />
    </picture>
  </div>

  <div class="modal-right col-md-6" *ngIf="isLoading">
    <app-loading></app-loading>
  </div>

  <div
    class="modal-right col-md-6"
    *ngIf="!isLoading && typeVerify === 'regis'"
  >
    <div class="modal-header">
      <p class="modal-title">
        <span class="btn-login">ลงทะเบียน</span>
      </p>
    </div>
    <div class="modal-body">
      <div class="content-layout">
        <form
          class="form-register"
          [formGroup]="registerForm"
          (ngSubmit)="onSubmitRegister()"
        >
          <div class="form-group">
            <label for="email" class="form-label email">อีเมล</label>
            <input
              type="text"
              formControlName="email"
              class="form-control"
              placeholder="langka_amulet@example.com"
              aria-describedby="email"
              id="email"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div
              *ngIf="f.email.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': f.email.touched }"
            >
              <div *ngIf="f.email.errors.required">จำเป็นต้องระบุอีเมล</div>
              <div *ngIf="f.email.errors.email">
                อีเมลจะต้องเป็นที่อยู่อีเมลที่ถูกต้อง
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="password" class="form-label password">รหัสผ่าน</label>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="password"
                class="form-control form-control-merge"
                placeholder="············"
                aria-describedby="password"
                id="password"
                [ngClass]="{
                  'is-invalid error': submitted && f.password.errors
                }"
              />
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i
                    class="feather"
                    [ngClass]="{
                      'icon-eye': passwordTextType,
                      'icon-eye-off': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  >
                  </i>
                </span>
              </div>
            </div>
            <div
              *ngIf="f.password.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': f.password.touched }"
            >
              <div *ngIf="f.password.errors.required">
                จำเป็นต้องกรอกรหัสผ่าน
              </div>
              <div
                *ngIf="
                  f.password.errors.minlength || f.password.errors.maxlength
                "
              >
                รหัสผ่านต้องอยู่ระหว่าง 8-16 ตัว
              </div>
              <div *ngIf="f.password.errors.pattern">
                กรุณากรอกรหัสผ่านที่มีตัวเลข และตัวอักษรอย่างน้อย 1 ตัว
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="confirm-password" class="form-label confirm-password"
              >ยืนยันรหัสผ่าน</label
            >
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="confPasswordTextType ? 'text' : 'password'"
                formControlName="confirmPassword"
                class="form-control form-control-merge"
                placeholder="············"
                aria-describedby="confirm-password"
                id="confirm-password"
                [ngClass]="{
                  'is-invalid error': submitted && f.confirmPassword.errors
                }"
              />
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i
                    class="feather"
                    [ngClass]="{
                      'icon-eye': confPasswordTextType,
                      'icon-eye-off': !confPasswordTextType
                    }"
                    (click)="toggleConfPasswordTextType()"
                  >
                  </i>
                </span>
              </div>
            </div>
            <div
              *ngIf="f.confirmPassword.errors"
              class="invalid-feedback d-block"
              [ngClass]="{ 'd-block': f.confirmPassword.touched }"
            >
              <div *ngIf="f.confirmPassword.errors.required">
                จำเป็นต้องยืนยันรหัสผ่าน
              </div>
              <div
                *ngIf="
                  f.confirmPassword.errors.minlength ||
                  f.confirmPassword.errors.maxlength
                "
              >
                รหัสผ่านต้องอยู่ระหว่าง 8-16 ตัว
              </div>
              <div *ngIf="f.confirmPassword.errors.pattern">
                กรุณากรอกยืนยันรหัสผ่านที่มีตัวเลข และตัวอักษรอย่างน้อย 1 ตัว
              </div>
              <div *ngIf="f.confirmPassword.errors.mustMatch">
                กรุณากรอกรหัสผ่านให้ตรงกัน
              </div>
            </div>
          </div>

          <div class="login-or-div">
            <span>หรือ ลงทะเบียนผ่าน</span>
            <hr />
          </div>
          <div class="con-tab-social">
            <app-google-login
              (onSignIn)="checkLogin($event)"
            ></app-google-login>
          </div>

          <div class="form-group">
            <div class="form-check checkbox">
              <input
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="isAgreePolicy"
                [ngModelOptions]="{ standalone: true }"
                id="agree-term-policy"
              />
              <label class="form-check-label" for="agree-term-policy">
                ฉันตกลงและยอมรับ
                <a href="javascript:void(0);">
                  นโบายความเป็นส่วนตัว & คุ้กกี้
                </a>
                และ
                <a href="javascript:void(0);"> ข้อตกลงและ เงื่อนไข ของเรา</a>
              </label>
            </div>
          </div>
          <!-- [disabled]="registerForm.status === 'INVALID' || !isAgreePolicy" -->
          <button
            [disabled]="!isAgreePolicy || registerForm.invalid"
            class="btn btn-primary btn-block waves-effect waves-float"
            rippleeffect
          >
            ลงทะเบียน
          </button>
        </form>
        <!-- <div class="modal-footer"></div>   -->
      </div>
    </div>
  </div>

  <div
    class="modal-right col-md-6"
    *ngIf="!isLoading && typeVerify === 'verify'"
  >
    <div class="modal-header">
      <p class="modal-title">
        <span class="btn-login">ข้อตกลงการให้บริการ</span>
      </p>
    </div>

    <div class="p-3">
      <app-auth-verify-modal
        (onClickBtn)="verifyEvent($event)"
      ></app-auth-verify-modal>
    </div>
  </div>

  <div
    class="modal-right col-md-6"
    *ngIf="!isLoading && typeVerify === 'verify-email'"
  >
    <div class="modal-header">
      <p class="modal-title">
        <span class="btn-login">ยืนยัน Email</span>
      </p>
    </div>
    <div *ngIf="currentUser?.email">
      <app-auth-otp-email
        [email]="currentUser.email"
        (onClickBtn)="otpEmailEvent($event)"
      ></app-auth-otp-email>
    </div>
  </div>
  <div
    class="modal-right col-md-6"
    *ngIf="!isLoading && typeVerify === 'address'"
  >
    <div class="modal-header">
      <p class="modal-title">
        <span class="btn-login">โปรดกรอกที่อยู่</span>
      </p>
    </div>
    <div class="p-3">
      <app-auth-address [currentUser]="currentUser"></app-auth-address>
    </div>
  </div>
  <div
    class="modal-right col-md-6"
    *ngIf="!isLoading && typeVerify === 'verify-mobile'"
  >
    <div class="modal-header">
      <p class="modal-title">
        <span class="btn-login">โปรดยืนยัน เบอร์โทร</span>
      </p>
    </div>
    <app-auth-otp-mobile
      (onVerifyMobilePhone)="otpMobileEvent($event)"
    ></app-auth-otp-mobile>
  </div>
</div>
<!-- </ng-template> -->
