import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Register, RequestOtpPhone, TokenLogin, User, UserCurrent, UserLogin, UserProfile, VerifyEmail, VerifyPhone } from '@models/index';
import { UserService } from '@app/services/api/user.service';
import { AddressService } from '@app/services/api/address.service';
import { Logger } from '@services/logger.service';


import { ToastService } from '@app/services/toast.service';
import { Router } from '@angular/router';
import { UpdateService } from '@app/services/update.service';

const log = new Logger('AuthService')
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly CURRENT_USER = 'currentUser';
  private readonly TOKEN_USER = 'tokenUser';



  private tokenUserSubject: BehaviorSubject<TokenLogin | null>;
  private currentUserSubject: BehaviorSubject<UserCurrent | null>;

  private loginErrorSubject: BehaviorSubject<boolean | null>;

  public loginError: Observable<boolean | null>;

  public currentUser: Observable<UserCurrent | null>;
  public tokenUser: Observable<TokenLogin | null>;


  constructor(
    private httpClient: HttpClient,
    private _toastService: ToastService,
    private router: Router,
    private _userService: UserService,
    private _addressService: AddressService,
    private _updateService: UpdateService,




  ) {
    const currentUserFromStorage = localStorage.getItem(this.CURRENT_USER);
    const tokenUserFromStorage = localStorage.getItem(this.TOKEN_USER);

    const parsedUser = currentUserFromStorage ? JSON.parse(currentUserFromStorage) : null;
    const parsedTokenUser = tokenUserFromStorage ? JSON.parse(tokenUserFromStorage) : null;

    this.currentUserSubject = new BehaviorSubject<UserCurrent | null>(parsedUser);
    this.currentUser = this.currentUserSubject.asObservable();

    this.tokenUserSubject = new BehaviorSubject<TokenLogin | null>(parsedTokenUser);
    this.tokenUser = this.tokenUserSubject.asObservable();



    this.loginErrorSubject = new BehaviorSubject<boolean | null>(false);
    this.loginError = this.loginErrorSubject.asObservable();


    this._updateService.updateUser$.subscribe((user) => {
      if (user?.profile) {
        console.log(' update USerß', user)
        this.setLocalCurrentUser(user);
      }
    })

    // this.loginError.subscribe((e) => {
    //   if (e) {
    //     this._toastService.showError({ title: 'ไม่สามารถเข้าสู่ระบบได้' })
    //   }
    // })



  }

  /**
   * User login
   *
   * @param username
   * @param password
   * @returns user
   */


  public get currentUserValue(): UserCurrent | null {
    return this.currentUserSubject.value;
  }

  public get tokenUserValue(): TokenLogin | null {
    return this.tokenUserSubject.value;
  }


  async login(email: string, password: string): Promise<UserCurrent> {
    return this.httpClient
      .post<any>(`${environment.api}/auth/login`, { email, password })
      .toPromise()
      .then(async (response: any) => {

        const tokenLogin: TokenLogin = {
          tokens: null,
        }

        const userCurrent: UserCurrent = {
          id: null,
          addressId: null,
          profile: null,
          role: null,
          email: null,
          mobilePhone: null,
          loginWith: null,
        }
        log.debug('response user', response)
        if (response.user && response.tokens) {

          const user = response.user;

          tokenLogin.tokens = response.tokens;

          userCurrent.profile = user.profile;
          userCurrent.role = user.role;
          userCurrent.id = user.id;
          userCurrent.email = user.email;
          userCurrent.mobilePhone = user?.mobilePhone ? user?.mobilePhone : null;

          userCurrent.loginWith =
            user.facebook.isUserLoginWithFacebook ? 'facebook' :
              user.google.isUserLoginWithGoogle ? 'google' :
                user.localEmail.isUserLoginWithLocalEmail ? 'email' : null;

          localStorage.setItem(this.TOKEN_USER, JSON.stringify(tokenLogin));
          localStorage.setItem(this.CURRENT_USER, JSON.stringify(userCurrent));

          this.tokenUserSubject.next(tokenLogin);
          this.currentUserSubject.next(userCurrent);
        }

        const address = await this.checkAddress(userCurrent?.id || '')
        userCurrent.addressId = address;
        if (address) localStorage.setItem(this.CURRENT_USER, JSON.stringify(userCurrent));

        if (!response.user.profile.agreement) {
          this.goLink('/auth/sign-up#verify');
        } else if (!response.user.isEmailVerified) {
          this.goLink('/auth/sign-up#verify-email');
        }
        else if (!response?.user?.localMobile || !response.user.localMobile.isMobileVerified) {
          this.goLink('/auth/sign-up#verify-mobile');
        }
        else if (!userCurrent.addressId) {
          this.goLink('/auth/sign-up#address');
        } else {
          this.goLink('/home');
        }

        return userCurrent;
      })
      .catch((error) => {
        return error
      });
  }





  async register(data: Register): Promise<any> {
    return this.httpClient
      .post(environment.api + '/auth/sign-up', data)
      .toPromise()
      .then((response: any) => {

        const tokenRegister: TokenLogin = {
          tokens: null,
        }
        const userRegister: UserCurrent = {
          id: null,
          addressId: null,
          profile: null,
          role: null,
          email: null,
          loginWith: null,
        }

        if (response.user && response.tokens) {
          const user = response.user;

          tokenRegister.tokens = response.tokens;

          userRegister.profile = user.profile;
          userRegister.role = user.role;
          userRegister.id = user.id;
          userRegister.email = user.email;

          userRegister.loginWith =
            user.facebook.isUserLoginWithFacebook ? 'facebook' :
              user.google.isUserLoginWithGoogle ? 'google' :
                user.localEmail.isUserLoginWithLocalEmail ? 'email' : null;


          localStorage.setItem(this.TOKEN_USER, JSON.stringify(tokenRegister));
          localStorage.setItem(this.CURRENT_USER, JSON.stringify(userRegister));

          this.currentUserSubject.next(userRegister);
          this.tokenUserSubject.next(tokenRegister);
        }





        // if (response.error.code === 400) this._toastService.openToasts({ status: 'error', title: ' Email มีผู้ใช้แล้ว' })

        // Email already taken
        return response;
      })
      .catch((error) => {
        if (error.error.code === 400) this._toastService.openToasts({ status: 'error', title: ' Email มีผู้ใช้แล้ว', message: error.error.message })
        this._toastService.openToasts({ status: 'error', title: 'เกิดข้อผิดพลาด' })
        return Promise.reject(error);
      });
  }



  async checkUserLoginWithGoogle(dataTemp: object): Promise<UserLogin> {

    return this.httpClient
      .post(environment.api + '/auth/google-web-checker', dataTemp)
      .toPromise()
      .then(async (response: any) => {
        const tokenLogin: TokenLogin = {
          tokens: null,
        }
        const userLoginGoogle: UserCurrent = {
          profile: null,
          role: null,
          id: null
        }
        log.debug('goo', response)
        if (response.user && response.tokens) {
          const user = response.user;

          tokenLogin.tokens = response.tokens;
          userLoginGoogle.profile = user.profile;
          userLoginGoogle.role = user.role;
          userLoginGoogle.id = user.id;
          userLoginGoogle.email = user?.email || null;
          userLoginGoogle.mobilePhone = user?.mobilePhone ? user?.mobilePhone : null;

          userLoginGoogle.loginWith =
            user.facebook.isUserLoginWithFacebook ? 'facebook' :
              user.google.isUserLoginWithGoogle ? 'google' :
                user.localEmail.isUserLoginWithLocalEmail ? 'email' : null;

          localStorage.setItem(this.TOKEN_USER, JSON.stringify(tokenLogin));
          localStorage.setItem(this.CURRENT_USER, JSON.stringify(userLoginGoogle));



          this.currentUserSubject.next(userLoginGoogle);
          this.tokenUserSubject.next(tokenLogin);
          // this.goLink('/home');
        }

        const address = await this.checkAddress(userLoginGoogle?.id || '')
        userLoginGoogle.addressId = address;
        if (address) localStorage.setItem(this.CURRENT_USER, JSON.stringify(userLoginGoogle));

        if (!response.user.profile.agreement) {
          this.goLink('/auth/sign-up#verify');
        }
        else if (!response?.user?.localMobile || !response.user.localMobile.isMobileVerified) {
          this.goLink('/auth/sign-up#verify-mobile');
        }
        else if (!userLoginGoogle.addressId) {
          this.goLink('/auth/sign-up#address');
        } else {
          this.goLink('/home');
        }
        return userLoginGoogle;
      })
      .catch((error) => {
        console.log('google error', error)
        this.loginErrorSubject.next(true);
        if (error.error.code === 400 || error.error.messege === 'Email already taken') {
          this._toastService.showError({ title: ' Email นี้ลงทะเบียนผ่าน Email ไปแล้ว', message: error.error.message })
        } else {
          this._toastService.showError({ title: 'ไม่สามารถเข้าสู่ระบบได้' })
        }


        return error;
      });
  }


  async logout() {
    localStorage.removeItem(this.TOKEN_USER);
    localStorage.removeItem(this.CURRENT_USER);
    this.currentUserSubject.next(null);
    this.tokenUserSubject.next(null);
    this.loginErrorSubject.next(false);
    await this.goLink('/home');
    window.location.reload();
  }


  async setLocalCurrentUser(user: any) {

    const userCurrent: UserCurrent = {
      id: null,
      addressId: null,
      profile: null,
      role: null,
      email: null,
      mobilePhone: null,
      loginWith: null,
    }

    if (user) {
      userCurrent.profile = user.profile;
      userCurrent.role = user.role;
      userCurrent.id = user.id;
      userCurrent.email = user.email;
      userCurrent.mobilePhone = user?.mobilePhone ? user?.mobilePhone : null;

      userCurrent.loginWith =
        user.facebook.isUserLoginWithFacebook ? 'facebook' :
          user.google.isUserLoginWithGoogle ? 'google' :
            user.localEmail.isUserLoginWithLocalEmail ? 'email' : null;


      const address = await this.checkAddress(userCurrent?.id || '')
      userCurrent.addressId = address;
      if (address) localStorage.setItem(this.CURRENT_USER, JSON.stringify(userCurrent));
      localStorage.setItem(this.CURRENT_USER, JSON.stringify(userCurrent));

      this.currentUserSubject.next(userCurrent);
    }
  }




  getUserAgreement(): Promise<any> {
    return this.httpClient
      .get(environment.api + '/users/agreement')
      .toPromise()
      .then(res => {
        log.debug('res getUserAgreement', res);
        return res
      });
  }

  verifyEmail(data: VerifyEmail): Promise<any> {
    return this.httpClient
      .post(environment.api + '/auth/verify-email', data)
      .toPromise();
  }

  resendVerifyEmail(): Promise<any> {
    return this.httpClient
      .post(environment.api + '/auth/send-verification-email', null)
      .toPromise();
  }


  requestOtpVerifyPhone(data: RequestOtpPhone): Promise<any> {
    return this.httpClient
      .post(environment.api + '/auth/request-otp', data)
      .toPromise();
  }


  verifyMobilePhone(data: VerifyPhone): Promise<any> {
    return this.httpClient
      .post(environment.api + '/auth/verify-otp', data)
      .toPromise();
  }

  async checkAddress(userId: string): Promise<any> {
    if (!userId) return null;
    try {
      const result: any = await this._addressService.getAllAddress(userId);
      if (result?.results.length > 0) {
        return result.results[0]?.id || null
      } else {
        null
      }
    } catch (error) {
      log.error('get all address error', error);
      return null
    }
  }



  async goLink(link: string) {
    const { path, fragment } = await this.splitUrl(link);
    if (!fragment) {
      await this.router.navigate([path]);
    } else {
      this.router.navigate([path], { fragment: fragment });
    }
  }

  splitUrl(url: string): { path: string, fragment: string } {
    const parts = url.split('#');
    const path = parts[0];
    const fragment = parts[1] || '';

    return { path, fragment };
  }

  // await this.saveToken(authResp.tokens.access.token);

  // isTokenExpired(){
  //   const _user = JSON.parse(localStorage.getItem('currentUser'));
  //   const current = new Date()
  //   const expiredOn = Date.parse(_user.tokens.access.expires)
  //   return (current.getTime() > expiredOn)
  // }




}
