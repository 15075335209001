<div class="modal-body">
  <div class="con-otp">
    <form [formGroup]="otpEmailDigitForm" (reset)="clearInput()">
      <h3>รหัส OTP จะถูกส่งไปที่ Email</h3>
      <h4>
        {{ email }}
      </h4>
      <div class="con-input">
        <input
          #otp1
          formControlName="otpDigit1"
          (keyup)="otpController($event, otp2, null)"
          type="tel"
          maxlength="1"
        />
        <input
          #otp2
          formControlName="otpDigit2"
          (keyup)="otpController($event, otp3, otp1)"
          type="tel"
          maxlength="1"
        />
        <input
          #otp3
          formControlName="otpDigit3"
          (keyup)="otpController($event, otp4, otp2)"
          type="tel"
          maxlength="1"
        />
        <input
          #otp4
          formControlName="otpDigit4"
          (keyup)="otpController($event, otp5, otp3)"
          type="tel"
          maxlength="1"
        />
        <input
          #otp5
          formControlName="otpDigit5"
          (keyup)="otpController($event, otp6, otp4)"
          type="tel"
          maxlength="1"
        />
        <input
          #otp6
          formControlName="otpDigit6"
          (keyup)="otpController($event, null, otp5)"
          type="tel"
          maxlength="1"
        />
      </div>
      <p>
        กรุณารอ {{countdown }} วินาที <span *ngIf="countdown !== 0"> เพื่อขอรหัสอีกครั้ง</span>
        <button *ngIf="countdown === 0" type="button" (click)="resend()">ขอรหัสอีกครั้ง</button>
        <!-- <button type="reset">รีเซ็ต</button> -->
      </p>
      <button class="confirm" (click)="emitEvent()">ยืนยัน</button>
      <button class="reset btn btn-outline-primary" (click)="clearInput()">
        ล้าง
      </button>
      <!-- <button class="btn btn-outline-primary " (click)="emitEvent('confirm')">ยืนยัน</button> -->
    </form>
  </div>
</div>
