import { Component, ViewChild, ElementRef } from '@angular/core';
import { GetResponseBooking } from '@models/booking.model';
import { BookingService } from '@services/api/booking.service';
import { Logger } from '@services/logger.service';

const log = new Logger('SectionBookingComponent');

type TypeLot = 'now' | 'tomorrow' | 'soon'

@Component({
  selector: 'app-section-booking',
  templateUrl: './section-booking.component.html',
  styleUrls: ['./section-booking.component.scss']
})

export class SectionBookingComponent {

  dataBooking!: GetResponseBooking | any;

  dataBookingNow!: GetResponseBooking | any;
  dataBookingTomorrow!: GetResponseBooking | any;
  dataBookingSoon!: GetResponseBooking | any;
  constructor(
    private bookingService: BookingService,
  ) {
  }

  ngOnInit() {
    this.getLot('now');
    this.getLot('tomorrow');
    this.getLot('soon');
  }

  // @ViewChild('conList', { static: false }) conList: ElementRef | undefined;
  // @ViewChild('conList', { static: false }) conList_tomorrow: ElementRef | undefined;
  // @ViewChild('conList', { static: false }) conList_soon: ElementRef | undefined;

  scrollContent(direction: string) {
    const scrollAmount = 100;  // You can adjust this value

    // if (direction === 'left') {
    //   this.conList!.nativeElement.scrollLeft -= scrollAmount;
    //   this.conList!.nativeElement.conList_tomorrow -= scrollAmount;
    //   this.conList!.nativeElement.conList_soon -= scrollAmount;
    // } else {
    //   this.conList!.nativeElement.scrollLeft += scrollAmount;
    //   this.conList!.nativeElement.conList_tomorrow += scrollAmount;
    //   this.conList!.nativeElement.conList_soon += scrollAmount;
    // }
  }

  async onChangeSegment(type: TypeLot) {
    await this.getLot(type);
  }

  async getLot(type: TypeLot) {
    try {

      switch (type) {
        case 'now':
          this.dataBookingNow = await this.bookingService.getLotsBooking({ type });
          break
        case 'tomorrow':
          this.dataBookingTomorrow = await this.bookingService.getLotsBooking({ type });
          break
        case 'soon':
          this.dataBookingSoon = await this.bookingService.getLotsBooking({ type });
          break
      }

      // this.dataBooking = await this.bookingService.getLotsBooking({ type });
      // log.debug('lot booking', this.dataBooking);
    } catch (error) {
      log.error('error get dataBooking');
    }
  }
}
