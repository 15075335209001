import { Injectable } from '@angular/core';
import { SharedModalService } from '@app/@shared/shared-modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs/internal/Subject';

interface dataModal {
  title?: string,
  detail?: string,
  btnConfirm?: string,
  status?: string
}

@Injectable({
  providedIn: 'root'
})

export class ModalService {

  public modalConfirmRef: any;
  public modalConfirmData$ = new Subject<dataModal>();

  public modalSlideImagesRef: any;
  public modalSlideImagesData$ = new Subject<dataModal>();

  constructor(
    private modalService: NgbModal,
    private sharedModalService: SharedModalService
  ) {
  }

  openModalConfirm(data: dataModal): Promise<boolean> {
    this.modalConfirmData$.next(data)
    const modalTemplate = this.sharedModalService.modalConfirmTemplate;
    this.modalConfirmRef = this.modalService.open(modalTemplate,
      {
        size: 'lg', backdrop: 'static',
        centered: true
      });

    if (data.status === 'success' || data.status === 'error') {
      setTimeout(() => {
        this.modalConfirmRef.close();
      }, 3000);
    }

    return this.modalConfirmRef.result.then((result: any) => {
        return result;
      },
    ).catch((error: any) => {
      return false
    })
  }

  openModalSuccess(data: dataModal) {
    if (!data?.title) {
      data.title = 'ทำรายการสำเร็จ';
    }

    data.status = 'success';
    this.modalConfirmData$.next(data);
    const modalTemplate = this.sharedModalService.modalConfirmTemplate;
    this.modalConfirmRef = this.modalService.open(modalTemplate,
      {
        size: 'lg', backdrop: 'static',
        centered: true
      });

    setTimeout(() => {
      this.modalConfirmRef.close();
    }, 3000);
  }

  closeModalConfirm(value: boolean) {
    this.modalConfirmRef.close(value);
  }


  // Slide Images //
  openModalSlideImages(data: any) {
    this.modalSlideImagesData$.next(data)
    const modalTemplate = this.sharedModalService.modalSlideImagesTemplate;
    this.modalSlideImagesRef = this.modalService.open(modalTemplate,
      {
        size: 'lg',
        centered: true
      });

    return this.modalSlideImagesRef.result.then((result: any) => {
        return result;
      },
    ).catch((error: any) => {
      console.error('error', error)
      return false
    })
  }

  closeModalSlideImages(value: boolean) {
    this.modalConfirmRef.close(value);
  }

  // END Slide Images //
}
