<div class="p-1 con-text">
  <p>
    ข้อกำหนดและเงื่อนไขการใช้เว็บไซต์/แอปพลิเคชัน Langka Amulet App
    ข้อกำหนดและเงื่อนไขการใช้เว็บไซต์หรือการใช้แอปพลิเคชัน
    หรือข้อตกลงการใช้เว็บไซต์หรือการใช้แอปพลิเคชันเป็นสัญญาประเภทหนึ่งที่เจ้าของเว็บไซต์หรือแอปพลิเคชันกำหนดขึ้นเพื่อกำหนดและควบคุมเงื่อนไขลักษณะการใช้เว็บไซต์หรือแอปพลิเคชันของตนจากผู้เยี่ยมชมผู้ใช้
    หรือสมาชิกของเว็บไซต์หรือแอปพลิเคชัน เพื่อจุดประสงค์ต่างๆ เช่น
    เพื่อให้เกิดประสิทธิภาพสูงสุดในการใช้งาน เช่น การแจ้งให้ผู้เยี่ยมชม
    ผู้ใช้หรือสมาชิกของเว็บไซต์หรือแอปพลิเคชัน ทราบถึงจุดเด่น
    คุณลักษณะจำเพาะของเว็บไซต์หรือแอปพลิเคชันหรือความต้องการขั้นต่ำของระบบ
    (Minimum System Requirements) ในการใช้เว็บไซต์หรือแอปพลิเคชันนั้นๆ
    เพื่อป้องกันความเสียหายที่อาจเกิดขึ้นกับเว็บไซต์หรือแอปพลิเคชันจากการใช้งานไม่ถูกต้องหรือไม่ถูกวิธี
    เช่นการห้ามโพสข้อความรูปภาพในเว็บไซต์ที่มีขนาดใหญ่ของสมาชิกจนทำให้เว็บไซต์หรือแอปพลิเคชันทำงานช้ากว่าปกติและทำให้ระบบมีการใช้ทรัพยากรสูง
    (Server Load)
    เพื่อป้องกันความเสี่ยงจากความรับผิดทางกฎหมายของผู้เป็นเจ้าของเว็บไซต์หรือแอปพลิเคชันหากมีการใช้เว็บไซต์หรือแอปพลิเคชันดังกล่าวที่ผิดกฎหมายหรือละเมิดสิทธิของผู้อื่น
    โดยผู้เยี่ยมชม ผู้ใช้หรือสมาชิกของเว็บไซต์หรือแอปพลิเคชันนั้นๆ
    ซึ่งเป็นที่สำคัญที่สุด เช่น การสงวนสิทธิตามกฎหมายของผลงาน
    ข้อมูลและการออกแบบที่เจ้าของเว็บไซต์หรือแอปพลิเคชันเผยแพร่บนเว็บไซต์หรือแอปพลิเคชันการห้ามโพสข้อความในเว็บไซต์หรือแอปพลิเคชันที่เป็นการละเมิดลิขสิทธิ์ของผู้อื่น
    (Copyright Infringement)
    การห้ามใช้เว็บไซต์หรือแอปพลิเคชันเป็นช่องทางหรือเครื่องมือในการทำผิดกฎหมาย
    เพื่อจุดประสงค์จำเพาะต่างๆของเจ้าของแต่ละเว็บไซต์หรือแอปพลิเคชันซึ่งมีลักษณะและข้อควรระวังในการใช้ต่างกัน
    เช่นในกรณีที่เว็บไซต์หรือแอปพลิเคชันนั้นมีการเสนอขายสินค้าหรือบริการอาจมีการกำหนดข้อจำกัดความรับผิดเกี่ยวกับสินค้าและบริการที่อยู่ในเว็บไซต์หรือแอปพลิเคชัน
    (Limitation ofProduct/Service Liability) เงื่อนไขการรับประกัน (Warranty)
    การชำระค่าสินค้าและบริการ (Payment)นโยบายการคืนเงินและเปลี่ยนสินค้า (Refund
    and Return Policy) การโอนความเสี่ยงในสินค้า (Passing of
    Risk)หรือในกรณีที่เว็บไซต์หรือแอปพลิเคชัน
    มีการเก็บข้อมูลส่วนตัวของผู้เยี่ยมชม ผู้ใช้
    หรือสมาชิกเจ้าของเว็บไซต์หรือแอปพลิเคชันจึงควรปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
    (Data Privacy) ของประเทศไทย(รวมถึง
    ประเทศอื่นอีกด้วยหากผู้ใช้งานเป็นคนของชาติอื่นและกฎหมายคุ้มครองข้อมูลส่วนบุคคลของประเทศนั้นๆมีการบังคับใช้กฎหมายนอกดินแดน
    เช่น สหภาพยุโรป) ซึ่งโดยหลักเกณฑ์ทั่วไป คือต้องแจ้งให้
    ผู้ใช้หรือสมาชิกของเว็บไซต์หรือแอปพลิเคชัน
    ทราบว่าข้อมูลส่วนตัวของตนใดบ้างที่จะถูกจัดเก็บ
    และจะถูกนำไปใช้ทำอะไรบ้างมีการเปิดเผยหรือไม่ และ ผู้ใช้
    หรือสมาชิกของเว็บไซต์ยินยอมให้ใช้หรือเปิดเผยข้อมูลนั้นหรือไม่",
    "IMPLEMENTATION": "การนำไปใช้", "ACCEPT_06":
    "ตามที่ได้กล่าวก่อนหน้าว่าข้อกำหนดและเงื่อนไขการใช้เว็บไซต์หรือการใช้แอปพลิเคชันเป็นสัญญาที่เจ้าของเว็บไซต์หรือแอปพลิเคชันกำหนดและควบคุมเงื่อนไขลักษณะการใช้เว็บไซต์หรือแอปพลิเคชันของตนจากผู้เยี่ยมชม
    ผู้ใช้หรือสมาชิกของเว็บไซต์หรือแอปพลิเคชัน
    เพื่อให้เป็นไปตามวัตถุประสงค์ต่างๆเจ้าของเว็บไซต์หรือแอปพลิเคชันจึงควรกำหนด
    ลักษณะ เงื่อนไขข้อจำกัดการใช้และข้อห้ามในการใช้เว็บไซต์หรือการใช้แอปพลิเคชัน
    อย่างละเอียด ชัดเจนและเป็นที่เข้าใจได้ของผู้เยี่ยมชม ผู้ใช้ หรือสมาชิกทั่วไป
    เพื่อให้ผู้เยี่ยมชม ผู้ใช้หรือสมาชิกของเว็บไซต์หรือแอปพลิเคชัน สามารถรับทราบ
    เข้าใจ และสามารถนำไปปฏิบัติได้", "ACCEPT_07": "นอกจากนี้
    เจ้าของเว็บไซต์หรือแอปพลิเคชัน ยังควรกำหนด สภาพบังคับ โทษ หรือผล
    ในกรณีที่ผู้ใช้หรือสมาชิกของเว็บไซต์หรือแอปพลิเคชันไม่ปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้เว็บไซต์หรือการใช้แอปพลิเคชัน
    เช่น การเตือน (Notice)
    จำกัดสิทธิการเข้าถึงและใช้เว็บไซต์หรือการใช้แอปพลิเคชัน (Block/Ban)
    ยกเลิกบัญชีสมาชิก (AccountDelete) หรือการปรับเป็นตัวเงิน (Penalty)
    เจ้าของหรือผู้พัฒนาเว็บไซต์หรือแอปพลิเคชันอาจจัดแสดงการเข้าถึงข้อกำหนดและเงื่อนไขการใช้เว็บไซต์หรือการใช้แอปพลิเคชันไว้ที่หน้าแรกของเว็บไซต์หรือแอปพลิเคชันซึ่งโดยทั่วไปนิยมจัดทำลิงค์
    ไว้ด้านล่างของหน้าแรกในเว็บไซต์ (Homepage Footer) หรืออาจจัดให้ ผู้เยี่ยมชม
    ผู้ใช้หรือสมาชิกเว็บไซต์หรือแอปพลิเคชันต้องยอมรับข้อกำหนดและเงื่อนไขดังกล่าวก่อนการเข้าชม
    ใช้ หรือเป็นสมาชิกครั้งแรกก็ได้ (Agreed and Accepted)
    ในกรณีที่ข้อกำหนดและเงื่อนไขการใช้เว็บไซต์หรือการใช้แอปพลิเคชันตามกฎหมายกำหนดให้ต้องได้รับความยิมยอมจากผู้เยี่ยมชมผู้ใช้
    หรือสมาชิกก่อน
    ในกรณีที่เจ้าของหรือผู้พัฒนาไม่ได้มีเจตนาให้เว็บไซต์หรือแอปพลิเคชันเป็นเว็บไซต์หรือแอปพลิเคชันสาธารณะที่ให้ผู้อื่นสามารถนำไปใช้
    หรือเปลี่ยนแปลงแก้ไขต่อได้ (Open Source)
    เจ้าของหรือผู้พัฒนาอาจสงวนสิทธิของงานของตนต่างๆ ไม่ให้ผู้อื่นนำไปใช้ทำซ้ำ
    คัดลอก หรือแก้ไขดัดแปลงได้ ตามพระราชบัญญัติลิขสิทธิ์ ไม่ว่าจะเป็น คำสั่ง
    (Code) ชุดคำสั่ง (Program) การออกแบบหน้าใช้งานเว็บไซต์หรือแอปพลิเคชัน
    เนื้อหา ข้อความ บทความ รูปภาพ เสียง
    หรือภาพยนต์ภายในเว็บไซต์หรือแอปพลิเคชันในกรณีที่เว็บไซต์หรือแอปพลิเคชัน
    กำหนดสิทธิให้ผู้เยี่ยมชม ผู้ใช้ หรือสมาชิก สามารถ เพิ่ม บันทึกข้อมูล
    และเปิดเผยเนื้อหาลงบนหน้าเว็บไซต์หรือแอปพลิเคชันได้ (Post)
    หากเนื้อหาดังกล่าวเป็นการละเมิดลิขสิทธิ์ผู้อื่น
    และไม่เข้าข้อยกเว้นการละเมิดลิขสิทธิ์ (Fair Use) ตามพระราชบัญญัติลิขสิทธิ์
    และเจ้าของเว็บไซต์หรือแอปพลิเคชันที่ปล่อยปะละเลยให้มีการกระทำดังกล่าวภายในเว็บไซต์หรือแอปพลิเคชันของตนก็อาจมีความผิดด้วยในกรณีที่มีการเสนอขายสินค้าหรือบริการบนเว็บไซต์หรือแอปพลิเคชัน
    (e-Commerce)
    หรือการใช้เว็บไซต์หรือแอปพลิเคชันเป็นตลาดแลกเปลี่ยนซื้อขายสินค้าของสมาชิก
    (e-Marketplace) หรือการให้บริการอื่นตามที่กำหนดใน ประกาศกระทรวงพาณิชย์
    เรื่อง ให้ผู้ประกอบพาณิชยกิจต้องจดทะเบียนพาณิชย์
    เจ้าของเว็บไซต์หรือแอปพลิเคชันนั้นจะต้องดำเนินการจดทะเบียนพาณิชย์อิเล็กทรอนิกส์กับหน่วยงานที่ประกาศกำหนดและในกรณีที่มีการนำเสนอสินค้าหรือบริการโดยตรงถึงผู้บริโภคผ่านเว็บไซต์หรือแอปพลิเคชันนั้นอีกด้วยในกรณีที่เว็บไซต์หรือแอปพลิเคชันเป็นเว็บไซต์หรือแอปพลิเคชันที่ให้บริการเกี่ยวกับระบบการชำระเงินตามพระราชบัญญัติระบบการชำระเงินและ
    ประกาศคณะกรรมการธุรกรรมทางอิเล็กทรอนิกส์ เรื่อง หลักเกณฑ์ วิธีการ
    และเงื่อนไขในการประกอบธุรกิจบริการการชำระเงินทางอิเล็กทรอนิกส์ เช่น
    การให้บริการเงินอิเล็กทรอนิกส์ (e-Money) การให้บริการเครือข่ายบัตรเครดิต
    (Credit Card Network) การให้บริการเครือข่ายอีดีซี (EDC Network)
    การให้บริการหักบัญชี (Clearing) การให้บริการชำระดุล (Settlement)
    หรือบริการการชำระเงินลักษณะอื่นๆที่กฎหมายดังกล่าวกำหนด
    เจ้าของจะต้องดำเนินการต่างๆ ตามที่กฎหมายกำหนดสำหรับการให้บริการแต่ละประเภท
    เช่น จดแจ้งการให้บริการ ขึ้นทะเบียน หรือขออนุญาต ตามลำดับ โดยทั่วไป
    ผู้เยี่ยมชม ผู้ใช้ หรือสมาชิกมักเข้าถึง เยี่ยมชม
    ใช้งานเว็บไซต์หรือแอปพลิเคชันผ่านอุปกรณ์อิเล็กทรอนิกส์ เช่น
    คอมพิวเตอร์ส่วนบุคคล แท็บเล็ต
    หรือโทรศัพท์เคลื่อนที่จึงไม่จำเป็นต้องให้ผู้เยี่ยมชม ผู้ใช้
    หรือสมาชิกจัดพิมพ์ข้อกำหนดและเงื่อนไขการใช้เว็บไซต์หรือการใช้แอปพลิเคชันเป็นแผ่นกระดาษและลงนามเหมือนสัญญาทั่วไปอย่างไรก็ดี
    แม้ข้อกำหนดและเงื่อนไขการใช้เว็บไซต์หรือการใช้แอปพลิเคชันจะไม่ได้มีการลงนามโดยผู้เยี่ยมชม
    ผู้ใช้
    หรือสมาชิกข้อกำหนดและเงื่อนไขการใช้เว็บไซต์หรือการใช้แอปพลิเคชันดังกล่าวก็ยังมีผลผูกพันผู้เยี่ยมชม
    ผู้ใช้หรือสมาชิกตามกฎหมาย เนื่องจากการที่ผู้เยี่ยมชม ผู้ใช้ หรือสมาชิก
    ได้อ่านและรับทราบถึงรวมถึงยอมรับข้อกำหนดและเงื่อนไขการใช้เว็บไซต์หรือการใช้แอปพลิเคชันแล้วและยังคงดำเนินการต่อบนเว็บไซต์หรือการใช้แอปพลิเคชันดังกล่าว
    อันรวมถึงแต่ไม่จำกัดเพียง การเยี่ยมชม การใช้การสั่งซื้อสินค้าและบริการ
    การกรอกข้อมูลต่างๆถือเป็นการตกลงเข้าผูกพันข้อกำหนดและเงื่อนไขการใช้เว็บไซต์หรือการใช้แอปพลิเคชันดังกล่าวนี้
    โดยปริยายในกรณีที่มีการแก้ไข ปรับปรุง
    หรือเปลี่ยนแปลงข้อกำหนดและเงื่อนไขการใช้เว็บไซต์หรือการใช้แอปพลิเคชันเจ้าของหรือผู้พัฒนาควรแจ้งให้
    ผู้เยี่ยมชม ผู้ใช้
    หรือสมาชิกทราบถึงเนื้อหาที่มีการเปลี่ยนแปลงทุกครั้งที่มีการแก้ไขเปลี่ยนแปลง
    รวมถึงลำดับฉบับการแก้ไข (Version)
    และวันที่ข้อกำหนดและเงื่อนไขดังกล่าวมีผลบังคับใช้ (Effective Date)
    ด้วยกฎหมายที่เกี่ยวข้อง สำหรับประเทศไทย
    มีกฎหมายที่กับเกี่ยวข้องกับกิจการที่ดำเนินการในเว็บไซต์หรือแอปพลิเคชัน
    ดังต่อไปนี้ พระราชบัญญัติธุรกรรมทางอิเล็กทรอนิกส์
    ซึ่งรองรับการทำนิติกรรมทางอิเล็กทรอนิกส์ หากมีวิธีการและมาตรการตามที่กำหนด
    พระราชบัญญัติลิขสิทธิ์ซึ่งคุ้มครองการลอกเลียนคำสั่ง (Code) ชุดคำสั่ง
    (Program) การออกแบบหน้าใช้งาน (Interface Design) หรือ เนื้อหา (Content)
    ของเจ้าของเว็บไซต์หรือแอปพลิเคชัน
    พระราชบัญญัติว่าด้วยการกระทำผิดเกี่ยวกับคอมพิวเตอร์
    ซึ่งช่วยคุ้มครองเจ้าของเว็บไซต์หรือแอปพลิเคชันในการป้องกันและกำหนดโทษเกี่ยวกับอาญชากรรมทางคอมพิวเตอร์
    (Computer Crime) อีกด้วยประกาศกระทรวงพาณิชย์
    เรื่องให้ผู้ประกอบพาณิชยกิจต้องจดทะเบียนพาณิชย์ในกรณีที่มีการเสนอขายสินค้าหรือบริการบนเว็บไซต์หรือแอปพลิเคชัน
    (e-Commerce)หรือการใช้เว็บไซต์หรือแอปพลิเคชันเป็นตลาดแลกเปลี่ยนซื้อขายสินค้าของสมาชิก
    (e-Marketplace) หรือการให้บริการอื่นตามที่กำหนดพระราชบัญญัติระบบการชำระเงิน
    และประกาศคณะกรรมการธุรกรรมทางอิเล็กทรอนิกส์ เรื่อง หลักเกณฑ์วิธีการ
    และเงื่อนไขในการประกอบธุรกิจบริการการชำระเงินทางอิเล็กทรอนิกส์ในกรณีที่เว็บไซต์หรือแอปพลิเคชันเป็นเว็บไซต์หรือแอปพลิเคชันที่ให้บริการเกี่ยวกับระบบการชำระเงินพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลในกรณีที่เว็บไซต์หรือแอปพลิเคชันที่มีการเก็บ
    ใช้ เผยแพร่
    และ/หรือโอนข้อมูลส่วนบุคคลของผู้ใช้งานกฎหมายคุ้มครองข้อมูลส่วนบุคคลของสหภาพยุโรป
    หรือ General Data Protection Regulation (GDPR)
    ในกรณีที่เว็บไซต์หรือแอปพลิเคชันมีการให้บริการหรือเสนอขายสินค้าแก่บุคคลธรรมดาซึ่งอยู่ในสหภาพยุโรปหรือมีการเก็บข้อมูลพฤติกรรมของผู้ใช้งานซึ่งอยู่ในสหภาพยุโรป
    เว็บไซต์หรือแอปพลิเคชันนั้นก็ต้องปฏิบัติตามกฎหมายดังกล่าวด้วย
    ข้าพเจ้าได้อ่าน และยอมรับข้อกำหนดและเงื่อนไขการให้บริการข้างต้น
  </p>
</div>

<div class="con-submit">
  <div class="form-group con-checkbox">
    <div class="form-check checkbox">
      <input
        class="form-check-input"
        type="checkbox"
        id="agree-term-policy"
        [(ngModel)]="isVerify"
        [ngModelOptions]="{ standalone: true }"
      />
      <label class="form-check-label" for="agree-term-policy">
        ฉันตกลงและยอมรับ
      </label>
    </div>
  </div>

  <div class="con-btn">
    <button
      [disabled]="!isVerify"
      class="btn btn-primary btn-block waves-effect waves-float"
      (click)="emitEvent('confirm')"
    >
      ยืนยัน
    </button>
    <button
      class="btn btn-outline-primary btn-block waves-effect waves-float"
      (click)="emitEvent('cancel')"
    >
      ยกเลิก
    </button>
  </div>
</div>
