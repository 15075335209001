import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SingletonService {

  public layoutFooterDisplay$ = new Subject<boolean>();
  public layoutSidebarMenuDisplay$ = new Subject<boolean>();


  constructor() { }

  public getLayoutFooterDisplay() {
    return this.layoutFooterDisplay$;
  }
  public setLayoutFooterDisplay(display = true) {
    this.layoutFooterDisplay$.next(display);
  }

  public getLayoutSidebarMenuDisplay() {
    return this.layoutSidebarMenuDisplay$;
  }
  public setLayoutSidebarMenuDisplay(display = false) {
    this.layoutSidebarMenuDisplay$.next(display);
  }


}
