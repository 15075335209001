<div class="reset-password">
    <div class="card">
      <div class="card-body">
        <div class="header-rs">
          <div class="title-rs"> ลืมรหัสผ่าน </div>
          <div class="sub-title-rs">
            เราจะส่งรหัสยืนยันตัวตน ให้คุณทางอีเมล
          </div>
        </div>
        <div class="form-group form-email">
          <label class="form-label" for="email">กรุณากรอก Email ของคุณ</label>
          <input
            class="form-control"
            [(ngModel)]="userEmail"
            type="email"
            placeholder="กรอกอีเมล"
            id="email"
          />
        </div>
        <div class="form-check checkbox">
          <input
            class="form-check-input"
            type="checkbox"
            id="agree"
            [(ngModel)]="isAgree"
            [ngModelOptions]="{ standalone: true }"
  
          />
          <label class="form-check-label" for="agree">
            ระบบจะส่งรหัสยืนยันตัวตนจะถูกส่งไปทางอีเมลของคุณกรุณาใส่อีเมล
          </label>
        </div>
        <!--button-reset-->
        <app-forgot-password-modal [isAgree]="isAgree" [email]="userEmail"></app-forgot-password-modal>
        <!--END button-reset-->
      </div>
    </div>
  </div>
  