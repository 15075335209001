<div class="reset-password-modal">
    <div class="button-reset">
      <button
        [disabled]="!isAgree || !email"
        class="btn-reset"
        (click)="modalOpenResetPassWord(modalResetPassWord)"
      >
        ขอรหัส OTP
      </button>
    </div>
  
    <ng-template #modalResetPassWord let-modal>
      <div class="modal-header">
        <div></div>
        <div class="modal-title tx-tt">เปลี่ยนรหัสผ่านของคุณ</div>
        <button
          type="button"
          class="btn btn-flat-primary btn-closes"
          (click)="closeModal()"
          aria-label="Close"
        >
          <span aria-hidden="true" [data-feather]="'x'" [size]="'22'"></span>
        </button>
      </div>
      <div class="modal-body" tabindex="0">
        <div class="header-rsm">
          <div class="title-rsm">เปลี่ยนรหัสผ่านของคุณ</div>
          <div class="sub-title-rsm">
            {{
              !submitBnt
                ? "เราจะส่งรหัสยืนยันตัวตน และคำแนะนำในการรีเซ็ตรหัสผ่านให้คุณทางอีเมล"
                : "รหัสผ่านใหม่ของคุณจะต้องแตกต่างจากรหัสผ่านที่ใช้ก่อนหน้านี้"
            }}
          </div>
        </div>
  
        <div class="con-otp" *ngIf="!submitBnt">
          <form
            class="form-otp"
            [formGroup]="otpEmailDigitForm"
            (reset)="clearInput()"
          >
            <h3>รหัส OTP จะถูกส่งไปที่ Email</h3>
            <h4>
              {{ email }}
            </h4>
            <div class="con-input">
              <input
                #otp1
                formControlName="otpDigit1"
                (keyup)="otpController($event, otp2, null)"
                type="tel"
                maxlength="1"
              />
              <input
                #otp2
                formControlName="otpDigit2"
                (keyup)="otpController($event, otp3, otp1)"
                type="tel"
                maxlength="1"
              />
              <input
                #otp3
                formControlName="otpDigit3"
                (keyup)="otpController($event, otp4, otp2)"
                type="tel"
                maxlength="1"
              />
              <input
                #otp4
                formControlName="otpDigit4"
                (keyup)="otpController($event, otp5, otp3)"
                type="tel"
                maxlength="1"
              />
              <input
                #otp5
                formControlName="otpDigit5"
                (keyup)="otpController($event, otp6, otp4)"
                type="tel"
                maxlength="1"
              />
              <input
                #otp6
                formControlName="otpDigit6"
                (keyup)="otpController($event, null, otp5)"
                type="tel"
                maxlength="1"
              />
            </div>
            <p>
              <span *ngIf="countdown > 0"
                >กรุณารอ {{ countdown }} วินาที ก่อนกดส่ง ขอรหัสอีกครั้ง</span
              >
              <span *ngIf="countdown === 0">ท่านได้รับรหัสแล้วหรือไม่? </span>
              <button
                *ngIf="countdown === 0"
                type="button"
                class="bnt-otp"
                (click)="resend()"
              >
                ขอรหัสอีกครั้ง
              </button>
  
              |
              <button type="reset" class="bnt-reset" (click)="clearInput()">
                รีเซ็ต
              </button>
            </p>
          </form>
        </div>
  
        <div class="con-password" *ngIf="submitBnt">
          <form class="form-password" [formGroup]="resetPasswordForm">
            <div class="form-group form-input-password">
              <label for="password" class="form-label password"
                >รหัสผ่านใหม่ของคุณ</label
              >
              <div class="input-group form-input-password">
                <input
                  [type]="passwordTextType ? 'text' : 'password'"
                  formControlName="password"
                  class="form-control"
                  placeholder="กรอกรหัสผ่านใหม่ของคุณ"
                  aria-describedby="password"
                  id="password"
                  [ngClass]="{
                    'is-invalid error':
                      rfc.password.touched && rfc.password.errors
                  }"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i
                      class="feather"
                      [ngClass]="{
                        'icon-eye': passwordTextType,
                        'icon-eye-off': !passwordTextType
                      }"
                      (click)="togglePasswordTextType()"
                    >
                    </i>
                  </span>
                </div>
  
                <div
                  *ngIf="rfc.password.errors && rfc.password.touched"
                  class="invalid-feedback"
                  [ngClass]="{ 'd-block': rfc.password.touched }"
                >
                  <div *ngIf="rfc.password.errors.required">
                    จำเป็นต้องกรอกรหัสผ่าน
                  </div>
                  <div
                    *ngIf="
                      rfc.password.errors.minlength ||
                      rfc.password.errors.maxlength
                    "
                  >
                    รหัสผ่านต้องอยู่ระหว่าง 8-16 ตัว
                  </div>
                  <div *ngIf="rfc.password.errors.pattern">
                    กรุณากรอกรหัสผ่านที่มีตัวเลข และตัวอักษรอย่างน้อย 1 ตัว
                    หรือไม่ควรมีสัญลักษณ์พิเศษ
                  </div>
                </div>
              </div>
  
              <div class="form-group form-input-password">
                <label class="form-label password" for="confirm-password"
                  >ยืนยันรหัสผ่านใหม่</label
                >
                <div class="input-group form-input-password">
                  <input
                    [type]="confPasswordTextType ? 'text' : 'password'"
                    class="form-control"
                    placeholder="ยืนยันรหัสผ่านใหม่"
                    formControlName="confirmPassword"
                    aria-describedby="confirm-password"
                    id="confirm-password"
                    [ngClass]="{
                      'is-invalid error':
                        rfc.confirmPassword.touched && rfc.confirmPassword.errors
                    }"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i
                        class="feather"
                        [ngClass]="{
                          'icon-eye': confPasswordTextType,
                          'icon-eye-off': !confPasswordTextType
                        }"
                        (click)="toggleConfPasswordTextType()"
                      >
                      </i>
                    </span>
                  </div>
                  <div
                    *ngIf="rfc.confirmPassword.errors"
                    class="invalid-feedback d-block"
                    [ngClass]="{ 'd-block': rfc.confirmPassword.touched }"
                  >
                    <div *ngIf="rfc.confirmPassword.errors.required">
                      จำเป็นต้องยืนยันรหัสผ่าน
                    </div>
                    <div
                      *ngIf="
                        rfc.confirmPassword.errors.minlength ||
                        rfc.confirmPassword.errors.maxlength
                      "
                    >
                      รหัสผ่านต้องอยู่ระหว่าง 8-16 ตัว
                    </div>
                    <div *ngIf="rfc.confirmPassword.errors.pattern">
                      กรุณากรอกยืนยันรหัสผ่านที่มีตัวเลข และตัวอักษรอย่างน้อย 1
                      ตัว
                    </div>
                    <div *ngIf="rfc.confirmPassword.errors.mustMatch">
                      กรุณากรอกรหัสผ่านให้ตรงกัน
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>
  
      <div class="modal-footer">
        <div class="button-confirm">
          <button
            [disabled]="resetPasswordForm.invalid"
            type="button"
            class="btn-confirm"
            aria-label="Close"
            *ngIf="submitBnt"
            (click)="confirmNewPassword()"
          >
            ยืนยันเปลี่ยนรหัสผ่าน
          </button>
          <button
            type="button"
            class="btn-confirm"
            aria-label="Confirm"
            (click)="checkOTP()"
            rippleEffect
            *ngIf="!submitBnt"
          >
            ยืนยัน
          </button>
        </div>
      </div>
    </ng-template>
  </div>
  