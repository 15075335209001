<div class="con-section-category">
  <app-header-title
    title="หมวดหมู่ มาร์เก็ตเพลช"
    link="/marketplace/home"
  ></app-header-title>

  <div class="con-items" [@listAnimation]="category.length">
    <div class="item" *ngFor="let cate of category | slice : 0 : showCount" >
      <app-avatar-text-button [fragment]="cate?.id || ''"  [imageSrc]="cate?.images[0]" [title]="cate.name"></app-avatar-text-button>
    </div>
  </div>
  <div class="con-btn">
    <button *ngIf="showCount === 10" (click)="showMore()">
      ดูเพิ่มเติม ({{ category.length || 0 }}) หมวดหมู่
      <i data-feather="chevron-down"></i>
    </button>
    <button *ngIf="showCount > 10" (click)="showMore()">
      ย่อลง
      <i data-feather="chevron-up"></i>
    </button>
  </div>
</div>
