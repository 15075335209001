import { Component, AfterViewInit, Input } from '@angular/core';
import Swiper from 'swiper';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper/modules';

// service/api
import { Logger } from "@services/logger.service";
import { MarketplaceService } from '@services/api/marketplace.service';

const log = new Logger('Slide Image');

@Component({
    selector: 'app-slide-image',
    templateUrl: './slide-image.component.html',
    styleUrls: ['./slide-image.component.scss']
})

export class SlideImageComponent implements AfterViewInit {

    @Input() images = [];
    galleryTop:any;
    galleryThumbs:any;

    constructor(
        private marketplaceService: MarketplaceService,
    ) {
        this.marketplaceService.updateData$.subscribe((e) => {
            if (e) this.ngAfterViewInit();
        })
    }
    ngAfterViewInit() {
        // console.log('img', this.images)

        this.galleryTop = new Swiper('.gallery-top', {
            spaceBetween: 10,
            pagination: {
                el: '.swiper-pagination-top',
                type: 'fraction',
            },
            navigation: {
                nextEl: '.swiper-button-next-top',
                prevEl: '.swiper-button-prev-top',
            },
            keyboard: true,
            modules: [Navigation, Pagination],
        });

        this.galleryThumbs = new Swiper('.gallery-thumbs', {
            spaceBetween: 10,
            slidesPerView: 'auto',
            centeredSlides: true,
            slideToClickedSlide: true,
            freeMode: true,
            navigation: {
                nextEl: '.swiper-button-next-thumbs',
                prevEl: '.swiper-button-prev-thumbs',
            },
            thumbs: {
                swiper: this.galleryTop
            },
            modules: [FreeMode, Navigation, Thumbs],
        });

        this.galleryTop.on('slideChange',  () => {
            const activeIndex = this.galleryTop.activeIndex;
            this.galleryThumbs.slideTo(activeIndex);
        });

        const sliders = document.querySelectorAll('.slide-top');
        for (let i = 0; i < sliders.length; ++i) {
            sliders[i].addEventListener('click',  (event) => {
                this.galleryTop.slideTo(i);

                document.documentElement.style.overflow = 'hidden';

                (event.target as any).parentNode.parentNode.parentNode.classList.add('fullscreen');

                const topButtons = document.querySelectorAll('.top-buttons');
                topButtons.forEach((button: any) => {
                    button.style.top = '50%';
                });
                const lowerButtons = document.querySelectorAll('.lower-buttons');
                lowerButtons.forEach((button: any) => {
                    button.style.top = '50%';
                });

                setTimeout(() => {
                    this.galleryTop.update();
                    this.galleryThumbs.update();
                }, 200);
            }, false);
        }

        const closeButtons = document.querySelectorAll('.close-button');
        for (let y = 0; y < closeButtons.length; ++y) {
            closeButtons[y].addEventListener('click',  (event) => {
                const fullScreenElements = document.querySelectorAll('.fullscreen');
                for (let x = 0; x < fullScreenElements.length; ++x) {
                    fullScreenElements[x].classList.remove('fullscreen');

                    const topButtons = document.querySelectorAll('.top-buttons');
                    topButtons.forEach((button: any) => {
                        button.style.top = '185px';
                    });
                    const lowerButtons = document.querySelectorAll('.lower-buttons');
                    lowerButtons.forEach((button: any) => {
                        button.style.top = '385px'
                    });

                    setTimeout(() => {
                        this.galleryTop.update();
                        this.galleryThumbs.update();
                    }, 200);
                }
                document.documentElement.style.overflow = '';
            });
        }
    }

    ngOnDestroy(){
        const fullScreenElements = document.querySelectorAll('.fullscreen');
        for (let x = 0; x < fullScreenElements.length; ++x) {
            fullScreenElements[x].classList.remove('fullscreen');

            const topButtons = document.querySelectorAll('.top-buttons');
            topButtons.forEach((button: any) => {
                button.style.top = '185px';
            });
            const lowerButtons = document.querySelectorAll('.lower-buttons');
            lowerButtons.forEach((button: any) => {
                button.style.top = '385px'
            });

            setTimeout(() => {
                this.galleryTop.update();
                this.galleryThumbs.update();
            }, 200);
        }
        document.documentElement.style.overflow = '';
    }
}
