import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

// ng
import {BlockUI, NgBlockUI} from 'ng-block-ui';

@Injectable({
  providedIn: 'root',
})

export class BlockUiService {

  @BlockUI() blockUI: NgBlockUI | any;

  private blockUiSubject = new Subject<string>();

  constructor() {
  }

  startBlocking(title = 'กำลังโหลดข้อมูล') {
    this.blockUI.start(title);
  }


  stopBlocking() {
    this.blockUI.stop();
  }

  // เอาไปใช้ //
  //   this._blockUiService.startBlocking('Loading...');
  //   setTimeout(() => {
  //   this._blockUiService.stopBlocking();
  //   }, 10000);
  // จบ //
}
