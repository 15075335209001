<div class="banner-container">
  <div class="thumbnail">
    <div class="event-image">
      <picture>
        <img [src]="item?.images[0] || 'assets/images/cover2.png'"
             class="banner-img"
             alt="Langka Amulet"
             title="Banner Langka Amulet"
             (error)="checkUrlImageDraft($event)" />
      </picture>
      <div class="banner-type" (click)="goToLink(item)">รายละเอียด</div>
    </div>
    <div class="caption" style="text-align: left">
      <div class="text-ellipsis">
        <strong>{{ item?.name }}</strong>
      </div>
      <div class="detail">
        <div class="text-detail">
          {{ item?.feature }}
        </div>
      </div>
    </div>
  </div>
</div>
