import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdateService } from '@app/services/update.service';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-auth-otp-email',
  templateUrl: './auth-otp-email.component.html',
  styleUrls: ['./auth-otp-email.component.scss']
})
export class AuthOtpEmailComponent {



  @Input() email!: string;
  @Output() onClickBtn = new EventEmitter<string>();
  @ViewChild('otp1') firstOtp: ElementRef<HTMLInputElement> | undefined;

  public otpEmailDigitForm: FormGroup | any;
  public countdown: number = 15;
  public intervalCountdown: any; 

  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private _updateService: UpdateService,
    private _authService: AuthService,
  ) {
    this._updateService.updateRegister$.subscribe((e) => {

      if (e === 'verify-email') {

        console.log('otp email working', e);

      }
    })


  }

  async ngOnInit() {
    this.otpEmailDigitForm = this.formBuilder.group({
      otpDigit1: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit2: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit3: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit4: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit5: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit6: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
    });
  }

  ngAfterViewInit() {
    if (this.firstOtp) {
      this.renderer.selectRootElement(this.firstOtp.nativeElement).focus();
    }

    this.startCountdown();
  }

  emitEvent() {
    const { otpDigit1, otpDigit2, otpDigit3, otpDigit4, otpDigit5, otpDigit6 } = this.otpEmailDigitForm.value;
    const code = `${otpDigit1}${otpDigit2}${otpDigit3}${otpDigit4}${otpDigit5}${otpDigit6}`
    if (this.otpEmailDigitForm.invalid) return;

    this.onClickBtn.emit(code);
  }

  otpController(event: any, next: any, prev: any) {

    if (event.target.value.length < 1 && prev) {
      prev.focus();
    } else if (next && event.target.value.length > 0) {
      next.focus();
    } else {
      return;
    }

  }


  async btnOtpReset() {
    this.clearInput();
    // this.startCountdown(this.countdown);
    // this.resetOtp = true;
    try {
      if (this.firstOtp) {
        this.renderer.selectRootElement(this.firstOtp.nativeElement).focus();
      }
      // const resResetOTP = await this.userService.requestOtpPIN();

      // console.log('reset OTP PIN', resResetOTP);
    } catch (e) {
      console.error('Error resend ', e);
    }
  }

  clearInput() {
    this.clearOtpInputs()
    if (this.firstOtp) {
      this.renderer.selectRootElement(this.firstOtp.nativeElement).focus();
    }


  }

  async resend() {
    this.clearInput()
    this.countdown = 15;
    this.startCountdown()
    try {
      const resend = await this._authService.resendVerifyEmail();
      if (resend) this.clearInput()

    } catch (error) {
      console.error('resend', error)
    }
  }

  clearOtpInputs() {
    Object.keys(this.otpEmailDigitForm.controls).forEach(controlName => {
      this.otpEmailDigitForm.get(controlName)?.setValue('');
      this.otpEmailDigitForm.get(controlName)?.markAsPristine();
      this.otpEmailDigitForm.get(controlName)?.markAsUntouched();
      this.otpEmailDigitForm.get(controlName)?.updateValueAndValidity();
    });
  }


  startCountdown() {
    this.intervalCountdown = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--; 
      } else {
        clearInterval(this.intervalCountdown); 
      }
    }, 1000); 
  }



}
