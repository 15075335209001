<div class="swiper swiper-banner-marketplace">
    <div class="swiper-wrapper">
        <div class="swiper-slide">
            <app-card-banner-marketplace></app-card-banner-marketplace>
        </div>
        <div class="swiper-slide">
            <app-card-banner-marketplace></app-card-banner-marketplace>
        </div>
    </div>
</div>
