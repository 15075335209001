import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreDirectivesModule } from './directives/directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CoreDirectivesModule],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, CoreDirectivesModule]
})
export class CoreCommonModule { }
