import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberTh'
})
export class NumberThPipe implements PipeTransform {

  transform(value: number | string): string {
    if(!value) return '0';
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;

    if (!isNaN(numericValue) ) {
      return numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return '';
  }
  
}
