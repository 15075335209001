<footer class="footer container-fluid p-3 mt-4">
    <div class="row">
        <div class="col-md-8">
            <div class="company">
                <div class="mb-2">
                    <img [src]="'/assets/logo/langka-logo.png'"
                         class="img-logo"
                         alt="IMG_LOGO"/>
                    <span class="name-c">บริษัท ลังกา อมูเลท จำกัด</span>
                </div>
                <div class="address">
                    <p>
                        301/856 ซอย รามคำแหง68 (สุภาพงษ์)
                        <br/>
                        แขวง หัวหมาก เขต บางกะปิ กรุงเทพมหานคร 10240
                    </p>
                </div>
                <div class="contact">
                    <p>
                        โทร:
                        <a href="tel:+66839294636" class="mr-1">+66 (0) 83 929 4636</a>
                        E-mail:
                        <a href="mailto:contact@langkaamulet.com"
                        >contact@langkaamulet.com</a
                        >
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4 year-amulet">
            <p>©2023 Langka Amulet , All rights reserved</p>
        </div>
    </div>
</footer>
