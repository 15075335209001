import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SingletonService } from '@app/services/singleton.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(
    private router: Router,
    private _singleton: SingletonService,
  ) {

  }
  async ngOnInit() {
    this._singleton.setLayoutSidebarMenuDisplay(false);

  }
  async goBoards() {
    await this.router.navigate(['/booking/boards']);
  }

}
