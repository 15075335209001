import { Component } from '@angular/core';

@Component({
  selector: 'app-section-download',
  templateUrl: './section-download.component.html',
  styleUrls: ['./section-download.component.scss']
})
export class SectionDownloadComponent {

}
