import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class MenuService {

  private selectedLinkSubject: BehaviorSubject<string | null>;
  public selectedLink_1: Observable<string | null>;

  public selectedLinkSource = new Subject<string>();
  public pillBadgesAsNotification = new Subject<string>();

  selectedLink$ = this.selectedLinkSource.asObservable();
  pillBadges$ = this.pillBadgesAsNotification.asObservable();

  public linkKey: any;

  constructor(
    private router: Router
  ) {


    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const currentUrl = event.url;
      this.setSelectedLinkValue(currentUrl);
    });


    this.selectedLinkSubject = new BehaviorSubject<string | null>(null);
    this.selectedLink_1 = this.selectedLinkSubject.asObservable();
  }


  public get getSelectedLinkValue(): string | null {
    return this.selectedLinkSubject.value;
  }

  public setSelectedLinkValue(newLink: string | null): void {
    this.selectedLinkSubject.next(newLink);
  }




  // setLink(link: string): void {
  //   localStorage.setItem(this.linkKey, link);
  // }

  // getLink(): string | null {
  //   return localStorage.getItem(this.linkKey);
  // }

  // clearLink(): void {
  //   localStorage.removeItem(this.linkKey);
  // }


  // setSelectedLink(link: string) {
  //   this.selectedLinkSource.next(link);
  // }

  setPillBadgesAsNotification(pillBadges: any) {
    this.pillBadgesAsNotification.next(pillBadges);
  }



  async goLink(link: string) {
    console.log('link', link)
    let mapLink = link
    const { path, fragment } = await this.splitUrl(mapLink);
    if (!fragment) {
      await this.router.navigate([path]);
    } else {
      this.router.navigate([path], { fragment: fragment });
    }
  }

  splitUrl(url: string): { path: string, fragment: string } {
    const parts = url.split('#');
    const path = parts[0];
    const fragment = parts[1] || '';

    return { path, fragment };
  }




}
