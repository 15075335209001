import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss']
})
export class HeaderTitleComponent {

  @Input() title: string | undefined = 'title';
  @Input() titleR: string | undefined = 'ดูทั้งหมด';
  @Input() link: string | undefined = '/';

  constructor(private router: Router) { }

  navigateToRoute() {
    console.log(this.link)
      // Navigate to 'some-route'
      this.router.navigate([this.link]);
  }

}
