import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {Subject} from 'rxjs';

// service
import {Logger} from '../logger.service';

const log = new Logger('NotificationService');

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  public notificationSubject = new Subject<any>();
  public pillBadgeSubject = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  async getNotificationBadge(id: string): Promise<any> {
    const params = new HttpParams()
      .set('isRead', false);
    return await this.httpClient
      .get(environment.api + `/pushNotifications/get-notification-badge/${id}`, { params })
      .toPromise();
  }

  async getNotificationAllByUser(
    {
      id = null,
      sortBy = '',
      page = 1,
      limit = 10
    }): Promise<any> {
    let url = `${environment.api}/pushNotifications/get-notifications/${id}`
    let params = new HttpParams()
      .set('sortBy', sortBy)
      .set('page', page)
      .set('limit', limit);
    return await this.httpClient
      .patch(url, {params})
      .toPromise();
  }

  async readNotificationAllByUser(
    {
      id = null,
      page = 1,
      limit = 10
    }): Promise<any> {
    const params = new HttpParams()
      .set('isRead', true)
      .set('sortBy', 'createdAt:desc')
      .set('page', page)
      .set('limit', limit);
    const uri = `${environment.api}/pushNotifications/read-all-notification/${id}?${params.toString()}`;
    return await this.httpClient
      .patch(uri, {})
      .toPromise();
  }
}
