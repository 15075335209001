import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryParams } from '@app/models';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  getDeliverProvider(): Promise<any> {
    return this.httpClient
      .get(environment.api + `/mobile/delivers-provider`)
      .toPromise();
  }




  completeDeliver(deliverId: string): Promise<any> {
    return this.httpClient
      .post(environment.api + `/mobile/delivers/complete` + deliverId, null)
      .toPromise();
  }

  completeForMarketplace(deliverId: string): Promise<any> {
    return this.httpClient
      .post(environment.api + `/mobile/products-on-market/complete/` + deliverId, null)
      .toPromise();
  }


  getDelivered(param: QueryParams): Promise<any> {
    const { limit = 10, page = 1 } = param;
    const url = `${environment.api}/mobile/my-items/delivered`;
    let params = new HttpParams()
      .set('limit', limit.toString())
      .set('page', page.toString());
    return this.httpClient
      .get(url, { params })
      .toPromise();
  }
  getDeliverTracking(param: QueryParams): Promise<any> {
    const { limit = 10, page = 1 } = param;
    const url = `${environment.api}/mobile/my-items/tracking`;
    let params = new HttpParams()
      .set('limit', limit.toString())
      .set('page', page.toString());
    return this.httpClient
      .get(url, { params })
      .toPromise();
  }
  getDeliverCompleted(param: QueryParams): Promise<any> {
    const { limit = 10, page = 1 } = param;
    const url = `${environment.api}/mobile/my-items/completed`;
    let params = new HttpParams()
      .set('limit', limit.toString())
      .set('page', page.toString());
    return this.httpClient
      .get(url, { params })
      .toPromise();
  }
  getDeliverProblemed(param: QueryParams): Promise<any> {
    const { limit = 10, page = 1 } = param;
    const url = `${environment.api}/mobile/my-items/problemed`;
    let params = new HttpParams()
      .set('limit', limit.toString())
      .set('page', page.toString());
    return this.httpClient
      .get(url, { params })
      .toPromise();
  }

}
