import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-auth-verify-modal',
  templateUrl: './auth-verify-modal.component.html',
  styleUrls: ['./auth-verify-modal.component.scss']
})
export class AuthVerifyModalComponent {

  @Output() confirm = true;
  @Output() onClickBtn = new EventEmitter<string>();

  public isVerify = false;

  constructor(
    private _modalService: ModalService,
    private router: Router,
    private _authService: AuthService,

  ) {

  }

  async emitEvent(value: string) {
    if (value !== 'confirm') {
      if (await this._modalService.openModalConfirm({
        title: 'ต้องการกลับไปหน้าหลัก',
      })) {
        await this.router.navigate(['/home']);
        this._authService.logout();
        return;
      }
    }

    this.onClickBtn.emit(value);
  }
}
