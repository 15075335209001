import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-avatar-text-button',
  templateUrl: './avatar-text-button.component.html',
  styleUrls: ['./avatar-text-button.component.scss']
})
export class AvatarTextButtonComponent {

  @Input() imageSrc: any = '/assets/images/big-amulet.png';
  @Input() title: string = 'ไม่มีข้อมูล';
  @Input() fragment:string = ''
  constructor(
    private router: Router,
  ){

  }

  async goCate() {
    
      await this.router.navigate(['/marketplace/home'], { fragment: this.fragment });
  }

}

