import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { CoreCommonModule } from 'src/app/@core/common.module';
import {RouterModule, Routes} from '@angular/router';
import { ErrorComponent } from '@pages/miscellaneous/error/error.component';
import { SharedComponentModule } from '@shared/components/shared-component.module';
import { HomeModule } from '@pages/home/home.module';

// Modal
import { AuthSigninModalComponent } from "@pages/features/auth/auth-signin-modal/auth-signin-modal.component";
import { AuthRegisterModalComponent } from "@pages/features/auth/auth-register-modal/auth-register-modal.component";
import { AuthVerifyModalComponent } from './auth-verify-modal/auth-verify-modal.component';
import { AuthOtpEmailComponent } from './auth-otp-email/auth-otp-email.component';
import { AuthAddressComponent } from './auth-address/auth-address.component';
import { AuthOtpMobileComponent } from './auth-otp-mobile/auth-otp-mobile.component';
import { GoogleLoginComponent } from './google-login/google-login.component';
import { AuthComponent } from './auth.component';
import { AuthForgotPasswordComponent } from './auth-forgot-password/auth-forgot-password.component';
import { ForgotPasswordModalComponent } from './components/forgot-password-modal/forgot-password-modal.component';

const routes: Routes = [
    {
      path: '',
      component: AuthComponent,
    },
    {
      path: 'sign-up',
      component: AuthRegisterModalComponent,
    },
    {
      path: 'forgot-password',
      component: AuthForgotPasswordComponent,
    }
  ];

@NgModule({
    providers: [],
    declarations: [
        // Modal
        AuthSigninModalComponent,
        AuthRegisterModalComponent,
        AuthVerifyModalComponent,
        AuthOtpEmailComponent,
        AuthAddressComponent,
        AuthOtpMobileComponent,
        GoogleLoginComponent,
        AuthComponent,
        AuthForgotPasswordComponent,
        ForgotPasswordModalComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        CoreCommonModule,
        HomeModule,
        SharedComponentModule
    ],
    exports: [
        // Modal
        AuthSigninModalComponent,
        AuthRegisterModalComponent,
        AuthVerifyModalComponent,
        AuthOtpEmailComponent,
        AuthAddressComponent,
        AuthOtpMobileComponent,
        GoogleLoginComponent,
        AuthComponent,
        AuthForgotPasswordComponent,
        ForgotPasswordModalComponent
    ]
})

export class AuthModalModule {
}
