import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormBuilder, Validators } from "@angular/forms";

import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { CoreConfigService } from "@core/services/config.service";
import { MustMatch } from "../../../../../helper/must-match.validator";
import { AuthService } from '@app/auth/auth.service';

import { Logger } from '@app/services/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@app/services/toast.service';
import { UpdateService } from '@app/services/update.service';
import { UserCurrent, UserLogin } from '@app/models';
import { BlockUiService } from '@app/services/block-ui.service';


const log = new Logger('AuthRegisterModalComponent');



type typeVerify = 'regis' | 'verify' | 'verify-email' | 'address' | 'verify-mobile'

@Component({
  selector: 'app-auth-register-modal',
  templateUrl: './auth-register-modal.component.html',
  styleUrls: ['./auth-register-modal.component.scss'],
})

export class AuthRegisterModalComponent implements OnInit {
  public typeVerify: typeVerify = 'regis';

  public isAgreePolicy = false;
  public currentUser: UserCurrent | any;

  // Public
  public myModal: any

  public coreConfig: any;
  public passwordTextType: boolean = false;
  public confPasswordTextType: boolean = false;
  public registerForm: any;
  public submitted = false;
  public isLoading = false;


  public verifyData: string | undefined;


  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _modalService: NgbModal,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _toastService: ToastService,
    private _updateService: UpdateService,
    private _loadingService: BlockUiService,

  ) {
    this.currentUser = this._authService.currentUserValue;

    this._authService.currentUser.subscribe((current) => {
      if (current) {
        this.currentUser = current;
      } else {
        this.currentUser = undefined;
      }
    })

    this._unsubscribeAll = new Subject();


    this.route.fragment.subscribe((fragment: any) => {
      log.debug('fragment', fragment)
      if (fragment) this.typeVerify = fragment;
      switch (fragment) {

        case 'verify':
          log.debug('verify working');
          break
        case 'verify-email':

          this._updateService.updateRegister$.next('verify-email')

          break
        case 'address':

          break
        case 'verify-mobile':

          break

        default:

          break

      }


    });
  }

  // modalOpenSignUp(modalSignUp: any) {
  //   this.myModal = this._modalService.open(modalSignUp, {
  //     windowClass: "myCustomModalClass",
  //     size: 'lg',
  //     backdrop: 'static',
  //     beforeDismiss: () => {
  //       return false;
  //     },
  //     centered: true
  //   });
  // }

  get f() {
    return this.registerForm?.controls;
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  toggleConfPasswordTextType() {
    this.confPasswordTextType = !this.confPasswordTextType;
  }

  async onSubmitRegister() {
    // this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }
    log.debug('form', this.registerForm.value)
    try {
      this.isLoading = true;
      const res = await this._authService.register(this.registerForm.value);
      log.debug('create register', res);
      if (res) {
        this.typeVerify = 'verify'
      }

    } catch (error) {
      this._toastService.showError({ title: 'เกิดข้อผิดพลาด' })
      log.error('create register ', error)
    }
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.registerForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(16),
          Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(16),
          Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        ],
      ],
    },
      {
        validator: MustMatch('password', 'confirmPassword'),
      });

    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
      this.coreConfig = config;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }




  // verify
  async verifyEvent(eventData: string) {
    if (eventData !== 'confirm') {

      return;
    }
    try {
      const resVerify = await this._authService.getUserAgreement();
      if (resVerify?.profile?.agreement) {
        if (this.currentUser.loginWith === 'email') {
          this.typeVerify = 'verify-email';
        } else {
          this.typeVerify = 'verify-mobile';
        }
      }

    } catch (error) {
      this._toastService.showError({ title: 'เกิดข้อผิดพลาด' })
      log.error('verify error', error)
    }
  }

  // otp email
  async otpEmailEvent(eventData: string) {
    this.verifyData = eventData;

    log.debug('otpEmailEvent', eventData)
    const data = {
      code: eventData
    }
    try {
      this._loadingService.startBlocking('กำลังตรวจเช็ค')
      const res = await this._authService.verifyEmail(data);
      log.debug('res verify email', res);
      this.typeVerify = 'address';

    } catch (error: any) {
      if (error?.error?.code === 401) {
        this._toastService.showError({ title: 'กรอกรหัสไม่ถูกต้อง' })
      } else {
        this._toastService.showError({ title: 'เกิดข้อผิดพลาดการยืนยัน otp email' })
      }
      log.error('verify Email ', error)
    }
    this._loadingService.stopBlocking()
  }

  otpMobileEvent(eventData: string) {
    this.verifyData = eventData;

    if (eventData === 'success') {
      this.typeVerify = 'address';
    }

    console.log('verify event', this.verifyData)
  }




  checkLogin(ev: any) {
    console.log('ev', ev)
  }
}
