import { Injectable } from '@angular/core';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class CopyTextService {

  constructor(
    private _toastService: ToastService,

  ) { }

  copyText(text: string) {
    if (!text) return;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        () => {
          this._toastService.showInfo({ title: 'คัดลอกแล้ว' })
        },
        (err) => {
          this._toastService.showError({ title: 'เกิดข้อผิดพลาด' })
        }
      );
    }

  }

}
