import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
      },
      {path: '', redirectTo: 'home', pathMatch: 'full'}

    ]
  },
  {
    path: 'auth',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/features/auth/auth.module').then(m => m.AuthModalModule),
      },
      {path: '', redirectTo: 'auth', pathMatch: 'full'}

    ]
  },
  {
    path: 'booking',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/features/booking/booking.module').then(m => m.BookingModule)
      },
      {path: '', redirectTo: 'booking', pathMatch: 'full'}
    ]
  },
  {
    path: 'marketplace',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/features/marketplace/marketplace.module').then(m => m.MarketplaceModule)
      },
      {path: '', redirectTo: 'marketplace', pathMatch: 'full'}
    ]
  },
  {
    path: 'chat',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/features/chat/chat.module').then(m => m.ChatModule)
      },
      {path: '', redirectTo: 'chat', pathMatch: 'full'}
    ]
  },
  {
    path: 'history',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/features/history/history.module').then(m => m.HistoryModule)
      },
      {path: '', redirectTo: 'history', pathMatch: 'full'}
    ]
  },
  {
    path: 'profile',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/features/profile/profile.module').then(m => m.ProfileModule)
      },
      {path: '', redirectTo: 'profile', pathMatch: 'full'}
    ]
  },
  {
    path: 'news',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/features/news/news.module').then(m => m.NewsModule)
      },
      // {path: '', redirectTo: 'news', pathMatch: 'full'}
    ]
  },
  {
    path: 'notification',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/features/notification/notification.module').then(m => m.NotificationModule)
      },
      // {path: '', redirectTo: 'notification', pathMatch: 'full'}
    ]
  },
  {
    path: 'delivery',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/features/delivery/delivery.module').then(m => m.DeliveryModule)
      },
      // {path: '', redirectTo: 'delivery', pathMatch: 'full'}
    ]
  },
  {
    path: 'my-wallet',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/features/coins-p2p/coins-p2p.module').then(m => m.CoinsP2pModule)
      },
      // {path: '', redirectTo: 'my-wallet', pathMatch: 'full'}
    ]
  },
  {
    path: 'setting',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/features/setting/setting.module').then(m => m.SettingModule)
      },
      // {path: '', redirectTo: 'setting', pathMatch: 'full'}
    ]
  },
  {
    path: 'help',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/features/help/help.module').then(m => m.HelpModule)
      },
      // {path: '', redirectTo: 'help', pathMatch: 'full'}
    ]
  },
  {
    path: '**',
    redirectTo: '/miscellaneous/error' // Error 404 - Page not found
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
