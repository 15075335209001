import {AfterViewInit, Component, ElementRef, Input} from '@angular/core';
import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';

// service
import {Logger} from '@services/logger.service';

const log = new Logger('SlideFreemodeComponent')

@Component({
  selector: 'app-slide-freemode',
  templateUrl: './slide-freemode.component.html',
  styleUrls: ['./slide-freemode.component.scss']
})

export class SlideFreemodeComponent implements AfterViewInit {

  @Input() data: any;
  @Input() typeData = 'lot';

  // Public

  // Private
  private swiper: any;

  constructor(
    private elementRef: ElementRef
  ) {
  }

  async ngAfterViewInit() {
    log.debug('daaaa',this.data)
    const swiperElement = this.elementRef.nativeElement.querySelector('.swiper-freemode');

    this.swiper = new Swiper(swiperElement, {
      direction: 'horizontal',
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next-freemode',
        prevEl: '.swiper-button-prev-freemode',
      },
      modules: [Navigation, Pagination],
      breakpoints: {
        1400: {
          slidesPerView: 6,
        },
        992: {
          slidesPerView: 4,
        },
        767: {
          slidesPerView: 3,
        },
        375: {
          slidesPerView: 2,
        },
        280: {
          slidesPerView: 1,
        },
      },
    });
  }
}
