<app-header></app-header>
<div class="container container-fluid body">
  <div class="row">
    <div class="col-12 col-md-4 bd-sidebar" *ngIf="layoutSidebarMenuDisplay">
      <app-sidebar-menu></app-sidebar-menu>
    </div>
    <div
      class="col-12"
      [class.col-md-8]="layoutSidebarMenuDisplay"
      [class.bd-content]="layoutSidebarMenuDisplay"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
<app-footer *ngIf="layoutFooterDisplay"></app-footer>
