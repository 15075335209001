import {Component, Input, OnInit} from '@angular/core';

// model
import {GetResponseBooking} from '@models/booking.model';

@Component({
  selector: 'app-slide-new-product',
  templateUrl: './slide-new-product.component.html',
  styleUrls: ['./slide-new-product.component.scss']
})

export class SlideNewProductComponent implements OnInit {

  @Input() data: GetResponseBooking | any;

  isLoading: boolean = true;

  randomData: any[] | undefined;

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {
      this.randomData = this.getRandomData(this.data.results);
      this.isLoading = false;
    }, 2000)
  }

  getRandomData(data: any[]): any[] {
    const shuffledData = [...data];
    for (let i = shuffledData.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledData[i], shuffledData[j]] = [shuffledData[j], shuffledData[i]];
    }
    return shuffledData.slice(0, 2);
  }
}
