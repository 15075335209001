import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RecommendShop } from '@models/recommend-shop.model';
@Component({
  selector: 'app-card-recommend-shop',
  templateUrl: './card-recommend-shop.component.html',
  styleUrls: ['./card-recommend-shop.component.scss']
})
export class CardRecommendShopComponent {

  @Input() data: RecommendShop | any;

  
  constructor(
    private router: Router,
  ){
  }
  
  async goShop(userId:string) {
    // console.log(this.data.user.shop)
    // return;
    if(!userId) return;
      await this.router.navigate(['/marketplace/shop/'+ userId]);
  }


}
