<div class="section-booking">
  <nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <button
        class="nav-link mobile-no-padding active"
        id="nav-booking-now-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-booking-now"
        type="button"
        role="tab"
        aria-controls="nav-booking-now"
        aria-selected="true"
      >
        กำลังเปิดจอง
      </button>
      <button
        class="nav-link mobile-no-padding"
        id="nav-booking-tomorrow-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-booking-tomorrow"
        type="button"
        role="tab"
        aria-controls="nav-booking-tomorrow"
        aria-selected="false"
      >
        เปิดพรุ่งนี้
      </button>
      <button

        class="nav-link mobile-no-padding"
        id="nav-booking-coming-soon-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-booking-coming-soon"
        type="button"
        role="tab"
        aria-controls="nav-booking-coming-soon"
        aria-selected="false"
      >
        เร็วๆนี้
      </button>
    </div>
  </nav>

  <div class="tab-content" id="nav-tabContent">
    <!-- now -->
    <div
      class="tab-pane fade show active"
      id="nav-booking-now"
      role="tabpanel"
      aria-labelledby="nav-booking-now-tab"
    >
      <div *ngIf="dataBookingNow?.results?.length > 0; else noItemsNow">
        <!-- <app-slide-freemode [data]="dataBookingNow"></app-slide-freemode> -->
        <app-carousel-booking [data]="dataBookingNow"></app-carousel-booking>
      </div>
      <ng-template #noItemsNow>
        <div
          class="d-flex mt-5 justify-content-center align-items-center h-100"
        >
          <div class="card" style="width: 100%">
            <div class="card-body">
              <app-card-no-item
                [detail]="'ขณะนี้ยังไม่มีรายการ กรุณากลับมาอีกครั้งในภายหลัง'"
              ></app-card-no-item>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <!-- tomorrow -->
    <div
      class="tab-pane fade"
      id="nav-booking-tomorrow"
      role="tabpanel"
      aria-labelledby="nav-booking-tomorrow-tab"
    >
      <div
        *ngIf="dataBookingTomorrow?.results?.length > 0; else noItemsTomorrow"
      >
      <app-carousel-booking [data]="dataBookingTomorrow"></app-carousel-booking>
        <!-- <app-slide-freemode [data]="dataBookingTomorrow"></app-slide-freemode> -->
      </div>

      <ng-template #noItemsTomorrow>
        <div
          class="d-flex mt-5 justify-content-center align-items-center h-100"
        >
          <div class="card" style="width: 100%">
            <div class="card-body">
              <app-card-no-item
                [detail]="'ขณะนี้ยังไม่มีรายการ กรุณากลับมาอีกครั้งในภายหลัง'"
              ></app-card-no-item>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <!-- soon -->
    <div
      class="tab-pane fade"
      id="nav-booking-coming-soon"
      role="tabpanel"
      aria-labelledby="nav-booking-coming-soon-tab"
    >
      <div *ngIf="dataBookingSoon?.results?.length > 0; else noItemsSoon">
        <app-carousel-booking [data]="dataBookingSoon"></app-carousel-booking>
        <!-- <app-slide-freemode [data]="dataBookingSoon"></app-slide-freemode>
         -->

      </div>

      <ng-template #noItemsSoon>
        <div
          class="d-flex mt-5 justify-content-center align-items-center h-100"
        >
          <div class="card" style="width: 100%">
            <div class="card-body">
              <app-card-no-item
                [detail]="'ขณะนี้ยังไม่มีรายการ กรุณากลับมาอีกครั้งในภายหลัง'"
              ></app-card-no-item>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
