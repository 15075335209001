import { Component } from '@angular/core';
import { SingletonService } from '@services/singleton.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {

  layoutFooterDisplay = true;
  layoutSidebarMenuDisplay = false;

  constructor(
    private _singleton:SingletonService
  ){
    this._singleton.getLayoutFooterDisplay().subscribe((display) => {
      this.layoutFooterDisplay = display;
    })

    this._singleton.getLayoutSidebarMenuDisplay().subscribe((display) => {
      this.layoutSidebarMenuDisplay = display;
    })
  }

}
