import { Component, ElementRef, AfterViewInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CarouselComponent } from 'ngx-bootstrap/carousel';


@Component({
  selector: 'app-carousel-booking',
  templateUrl: './carousel-booking.component.html',
  styleUrls: ['./carousel-booking.component.scss']
})
export class CarouselBookingComponent {
  @Input('data') data: any;

  @ViewChild('carousel') carousel!: CarouselComponent;
  @ViewChild('carouselInner') carouselInner!: ElementRef;
  currentIndex = 0; // Track the current active item index

  constructor(
    private router: Router
  ) {
  }

  handleSmallScreenLogic(): void {
    const carouselElement = this.carouselInner.nativeElement;
    const carouselItems = carouselElement.querySelectorAll('.carousel-item');

    this.carousel.interval = 0; // Disabling auto interval

    document.querySelector('#carouseBooking .carousel-control-prev')?.addEventListener('click', () => {
      this.currentIndex = (this.currentIndex === 0) ? carouselItems.length - 1 : this.currentIndex - 1;
      this.updateDisplay(carouselItems);
    });

    document.querySelector('#carouseBooking .carousel-control-next')?.addEventListener('click', () => {
      this.currentIndex = (this.currentIndex === carouselItems.length - 1) ? 0 : this.currentIndex + 1;
      this.updateDisplay(carouselItems);
    });

    this.updateDisplay(carouselItems);
  }

  updateDisplay(carouselItems: NodeListOf<Element>): void {
    carouselItems.forEach((item: any, index: number) => {
      if (index === this.currentIndex) {
        item.style.display = 'block';
        item.classList.add('active');
      } else {
        item.style.display = 'none';
        item.classList.remove('active');
      }
    });
  }


  ngAfterViewInit(): void {
    const carouselElement = this.carouselInner.nativeElement;


    if (window.matchMedia('(min-width: 768px)').matches) {
      this.carousel.interval = 0; // Disabling auto interval

      const carouselWidth = carouselElement.scrollWidth;
      const cardWidth = carouselElement.querySelector('.carousel-item')?.clientWidth || 0;
      let scrollPosition = 0;

      document.querySelector('#carouseBooking .carousel-control-next')?.addEventListener('click', () => {
        if (scrollPosition < carouselWidth - cardWidth * 4) {
          scrollPosition += cardWidth;
          carouselElement.scrollTo({ left: scrollPosition, behavior: 'smooth' });
        }
      });

      document.querySelector('#carouseBooking .carousel-control-prev')?.addEventListener('click', () => {
        if (scrollPosition > 0) {
          scrollPosition -= cardWidth;
          carouselElement.scrollTo({ left: scrollPosition, behavior: 'smooth' });
        }
      });
    } else {
      // Add a class for smaller screens


      this.handleSmallScreenLogic();
      this.carousel.interval = 0;

      const carouselItems = carouselElement.querySelectorAll('.carousel-item');
      if (carouselItems?.length > 0) {
        carouselItems[0].style.display = 'block';
      }


      carouselElement.classList.add('slide');
    }
  }
  goProductDetail(id: string) {
    if (!id) return;
    this.router.navigate(['booking/detail/', id]);
  }
}