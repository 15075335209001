import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SharedModalService {

  private _modalConfirmTemplate!: TemplateRef<any>;
  private _modalSlideImagesTemplate!: TemplateRef<any>;

  set modalConfirmTemplate(template: TemplateRef<any>) {
    this._modalConfirmTemplate = template;
  }

  get modalConfirmTemplate(): TemplateRef<any> {
    return this._modalConfirmTemplate;
  }

  set modalSlideImagesTemplate(template: TemplateRef<any>) {
    this._modalSlideImagesTemplate = template;
  }

  get modalSlideImagesTemplate(): TemplateRef<any> {
    return this._modalSlideImagesTemplate;
  }
}
