import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module'

import {coreConfig} from './app-config';
import {AuthModule} from '@auth/auth.module';
import {AppComponent} from './app.component';
import {LayoutModule} from 'layout/layout.module';
import {PagesModule} from '@pages/pages.module';
import {CommonModule} from '@angular/common';

import {AppRoutingModule} from './app-routing.module';
import {SharedComponentModule} from '@shared/components/shared-component.module';

// ng
import {BlockUIModule} from "ng-block-ui";

// ngx
import {TranslateModule} from "@ngx-translate/core";
import {ToastrModule} from 'ngx-toastr';
import {ToastContainerDirective} from "ngx-toastr";
import {NgxSpinnerModule} from "ngx-spinner";

import {environment} from '@environments/environment';

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';

import {GoogleLoginProvider} from '@abacritt/angularx-social-login';

@NgModule({

  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CommonModule,
    AuthModule,
    LayoutModule,
    PagesModule,

    AppRoutingModule,
    SharedComponentModule,

    // ng
    BlockUIModule.forRoot(),

    // ngx
    TranslateModule.forRoot(),
    ToastrModule.forRoot(),
    NgxSpinnerModule.forRoot(),
    CarouselModule.forRoot(),

    SocialLoginModule,
    ToastContainerDirective
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GoogleClientID),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule {
}
