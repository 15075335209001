<div class="swiper swiper-banner">
    <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let item of banners">
            <app-card-banner [item]="item"></app-card-banner>
        </div>
    </div>
    <button type="button" class="btn btn-primary swiper-button-prev swiper-button-prev-banner"></button>
    <button type="button" class="btn btn-primary swiper-button-next swiper-button-next-banner"></button>
    <div class="swiper-pagination"></div>
</div>
