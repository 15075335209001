import { Component } from '@angular/core';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { MarketplaceService } from '@services/api/marketplace.service';
import { Category, GetRespondCategory } from '@models/category.model';
import { Logger } from '@services/logger.service';
const log = new Logger('SectionCategoryComponent');
@Component({
  selector: 'app-section-category',
  templateUrl: './section-category.component.html',
  styleUrls: ['./section-category.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* <=> *', [
        query(':enter',
          [
            style({ opacity: 0, transform: 'translateY(-15px)' }),
            stagger('50ms',
              animate('550ms ease-out',
                style({ opacity: 1, transform: 'translateY(0px)' })
              )
            )
          ],
          { optional: true }
        ),
        query(':leave', animate('50ms', style({ opacity: 0 })), {
          optional: true
        })
      ])
    ])
  ]
})
export class SectionCategoryComponent {
  dataCategory: GetRespondCategory | any;
  isExpanded = false;

  category: Category[] = [];

  showCount = 10;

  constructor(
    private marketplaceService: MarketplaceService,
  ) {

  }

  ngOnInit() {
    this.fetchCategory();
  }
  showMore() {
    if (this.showCount === this.category.length) {
      this.showCount = 10;
    } else this.showCount = this.category.length;
    this.toggleExpand()
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  async fetchCategory() {
    try {
      this.dataCategory = await this.marketplaceService.getCategories();
      if (this.dataCategory) {
        this.category = this.dataCategory?.results || [];
        this.category = this.category.filter(e => e.type === 'onmarket');
      }
      log.debug('category ', this.dataCategory)
    } catch (error) {
      log.error('error get category', error);
    }
  }

}
