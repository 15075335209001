import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BrowserQRCodeReader, BrowserQRCodeSvgWriter } from '@zxing/library';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent {
  @ViewChild('qrcode', { static: true }) qrcodeCanvas!: ElementRef ;
  @Input() textQrCode:string = '';
  @Input() width = 1000;
  @Input() height = 1000;


  constructor() { }

  ngOnInit(): void {
    this.generateQRCode(this.textQrCode);
  }

  generateQRCode(text:string): void {
    const writer = new BrowserQRCodeSvgWriter();
    const svgElement = writer.write(text, this.width, this.height);
    const svgO = svgElement.outerHTML;
    const updatedSvgString = svgO.replace('<svg ', '<svg style="margin: -8px" ');
    console.log('qrcodeCanvas',this.qrcodeCanvas)
    if(this.qrcodeCanvas){
      this.qrcodeCanvas.nativeElement.innerHTML = updatedSvgString;
    } 
  }
}
