import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

type FormatDate = 'DAY' | 'TIME' | ''

@Pipe({
  name: 'dateTh'
})
export class DateThPipe implements PipeTransform {

  transform(value: any, format: FormatDate = ''): any {
    const datePipe = new DatePipe('en-TH'); // 'en-TH' specifies the Thai locale
    const formattedDate = datePipe.transform(value, ' dd/MM/yyyy เวลา HH:mm ', 'th-TH');
    // return formattedDate;
    if (format === 'DAY' && formattedDate) {
      return formattedDate.split(' ')[1];
    } else if (format === 'TIME') {
      return datePipe.transform(value, 'dd/MM/yyyy  HH:mm ', 'th-TH');

    } else {
      return formattedDate;
    }
  }

}
