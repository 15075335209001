<div class="swiper swiper-freemode">
    <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let item of data?.results">
            <app-card-product-booking [typeData]="typeData" [item]="item"></app-card-product-booking>
        </div>
    </div>
    <button type="button" class="btn btn-primary swiper-button-prev swiper-button-prev-freemode"></button>
    <button type="button" class="btn btn-primary swiper-button-next swiper-button-next-freemode"></button>
    <div class="swiper-pagination"></div>
</div>
