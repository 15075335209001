import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Waves from 'node-waves';

import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from "@core/services/translation.service";

import { locale as menuEnglish } from '@assets/i18n/en';
import { locale as menuThai } from '@assets/i18n/th';

// ng ngx bootstrap
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

// service
import { Logger } from '@services/logger.service';
import { SharedDataService } from '@services/shared-data.service';

// service api
import { ApiLangkaService } from '@api/api-langka.service';
import { MarketplaceService } from './services/api/marketplace.service';
import { BookingService } from './services/api/booking.service';
import { UserService } from './services/api/user.service';


const log = new Logger('AppComponent')

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective | any;

  title = 'langka-amulet-webapp';

  coreConfig: any;
  defaultLanguage = 'en'; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage = 'en'; // Set application default language i.e fr

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param document
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   * @param _coreLoadingScreenService
   * @param _langkaService
   * @param sharedDataService
   * @param _toastrService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    public _coreConfigService: CoreConfigService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _langkaService: ApiLangkaService,
    private sharedDataService: SharedDataService,
    private _toastrService: ToastrService,
    private _marketplaceService: MarketplaceService,
    private _bookingService: BookingService,
    private _userService: UserService,
    
  ) {

    // Add languages to the translation service
    this._translateService.addLangs(['en', 'th']);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang('en');

    // Set the translations for the menu
    this._coreTranslationService.translate(menuEnglish, menuThai);

    // Set application default language.
    // Change application language? Read the ngxTranslate Fix
    this._translateService.use('en');
    // ? OR
    // ? User the current browser lang if available, if undefined use 'en'
    // const browserLang = this._translateService.getBrowserLang();
    // this._translateService.use(browserLang.match(/en|th/) ? browserLang : 'en');

    /**
     * ! Fix : ngxTranslate
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     *
     * Using different language than the default ('en') one i.e French?
     * In this case, you may find the issue where application is not properly translated when your app is initialized.
     *
     * It's due to ngxTranslate module and below is a fix for that.
     * Eventually we will move to the multi language implementation over to the Angular's core language service.
     *
     **/

    // Set the default language to 'en' and then back to 'fr'.

    // setTimeout(() => {
    //   this._translateService.setDefaultLang('en');
    //   this._translateService.setDefaultLang('fr');
    // });

    /**
     * !Fix: ngxTranslate
     * ----------------------------------------------------------------------------------------------------
     */

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    // this.fetchUserCurrent();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    // Init wave effect (Ripple effect)
    Waves.init();


    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
      this.coreConfig = config;

      // use appLanguage from coreConfig
      this._translateService.use(config.app.appLanguage);

      this._coreLoadingScreenService.hide();
    });

    this.getConfigCharge().finally(() => {
      this._coreLoadingScreenService.hide();
    });

    this._toastrService.overlayContainer = this.toastContainer;
    this.fetchCategory()
    this.fetchProvinces()

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  async getConfigCharge() {
    try {
      const res = await this._langkaService.getConfigs();
      if (res) this.sharedDataService.setDataFee(res.amountOfCharge);
    } catch (error) {
      log.error('getConfigCharge error', error)
    }
  }

  async fetchCategory() {
    try {
      const resCates = await this._langkaService.getCategories()
      this.sharedDataService.setDataCategoryMarketplace(resCates);
      this.sharedDataService.setDataCategoryBooking(resCates);
    } catch (error) {
      log.error('getDataCategoryMarketplace error', error)
    }
  }

  async fetchProvinces() {
    try {
      const resProvince = await this._langkaService.getProvinces()
      this.sharedDataService.setDataProvinces(resProvince);
    } catch (error) {
      log.error('fetch Province error', error)
    }
  }
  async fetchUserCurrent() {
    try {
      const userInfo = await this._userService.getUserCurrentInfo();
      this.sharedDataService.setDataUserCurrentInfo(userInfo);
      log.debug('user Info',userInfo)
    } catch (error) {
      log.error('fetch userInfo error', error)
    }
  }
}
