import {Component, Input, TemplateRef, ViewChild} from '@angular/core';

// service
import {ModalService} from '@app/services/modal.service';
import {SharedModalService} from '@app/@shared/shared-modal.service';

type Status = 'pre-order' | 'success' | 'error' | 'not-enough-coins' | 'cancel' | any

interface DataModal {
  title?: string,
  detail?: string,
  btnConfirm?: string
  status?: Status
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})

export class ConfirmModalComponent {

  @ViewChild('modalConfirm', {static: true}) modalConfirmTemplate!: TemplateRef<any>;

  @Input() btnName: any;

  // Public
  public dataText: DataModal = {
    title: '',
    detail: '',
    btnConfirm: '',
    status: ''
  };

  constructor(
    private modalService: ModalService,
    private sharedModalService: SharedModalService
  ) {
    this.modalService.modalConfirmData$.subscribe((data: DataModal) => {
      if (data) {
        this.dataText = data;
      }
    })
  }

  ngOnInit() {
    this.sharedModalService.modalConfirmTemplate = this.modalConfirmTemplate;
  }

  onEvent(value: boolean) {
    this.modalService.closeModalConfirm(value);
  }
}


