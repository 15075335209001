import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormBuilder, Validators } from "@angular/forms";

import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { CoreConfigService } from "../../../../@core/services/config.service";

import { AuthService } from '@auth/auth.service';


import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-signin-modal',
  templateUrl: './auth-signin-modal.component.html',
  styleUrls: ['./auth-signin-modal.component.scss'],
})


export class AuthSigninModalComponent implements OnInit {

  @Input() typeModal: any
  @Input() colorText = 'white';
  isLoading = false;
  // Public
  public myModal: any

  public coreConfig: any;
  public passwordTextType: boolean = true;
  public signInForm: any;
  public submitted = false;

  // Private
  private _unsubscribeAll: Subject<any>;
  color: { [klass: string]: any; } | null | undefined;


  socialUser!: SocialUser;
  isLoggedin?: boolean;

  constructor(
    private socialAuthService: SocialAuthService,
    private _modalService: NgbModal,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    private router: Router,
  ) {
    this._unsubscribeAll = new Subject();
    this._authService.loginError.subscribe((e) => {

      if (this.myModal) {
        this.myModal.close()
      }

    })

  }

  ngOnInit(): void {
    this.signInForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
      this.coreConfig = config;
    });

    this.socialAuthService.authState.subscribe((user: any) => {
      this.socialUser = user;
      this.isLoggedin = user != null;
      console.log(' social user', this.socialUser);
      const dataTemp = {
        jwt: this.socialUser.idToken
      }

      this._authService.checkUserLoginWithGoogle(dataTemp);
    });

  }


  modalOpenSignin(modalSignin: any) {
    this.myModal = this._modalService.open(modalSignin, {
      windowClass: "myCustomModalClass",
      size: 'lg',
      backdrop: 'static',
      beforeDismiss: () => {
        return false;
      },
      centered: true
    });
  }

  get f() {
    return this.signInForm?.controls;
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  async onSubmit() {
    this.submitted = true;
    if (this.signInForm?.invalid) return;
    this.isLoading = true;
    const login = await this._authService.login(this.signInForm.value.email, this.signInForm.value.password);

    if (login) {
      this.isLoading = false;
      this.myModal.close()
    }

    this.isLoading = false;

  }



  async loginWithGoogle() {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user: SocialUser) => {
      console.log('login google', user)

      this.modalClose()
    })

  }




  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }


  logOutSocial(): void {
    this.socialAuthService.signOut();
  }


  async goSignUp(link: string) {
    await this.router.navigate([link]);
    this.modalClose()


  }

  modalClose() {
    this.myModal.close()
  }

  checkLogin(ev: any) {
    if (ev) {
      this.modalClose();
    }
    console.log('ev', ev)
  }



}
