import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { Category, GetRespondCategory } from '@app/models';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class ApiLangkaService {



  constructor(
    private httpClient: HttpClient,
  ) { }

  getConfigs(): Promise<any> {
    return this.httpClient
      .get(environment.api + `/system/configs`)
      .toPromise();
  }

  async getCategories(): Promise<GetRespondCategory> {
    return this.httpClient
      .get<GetRespondCategory | any>(environment.api + `/mobile/categories?limit=100`)
      .toPromise()
      .then(response => response)
  }

  getProvinces(): Promise<any> {
    return this.httpClient
      .get(environment.api + `/system/thailand/provinces`)
      .toPromise();
  }
}
