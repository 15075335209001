<div class="sidebar-menu">
  <div class="user-profile">
    <div class="avatar-text">
      <img [src]="currentUser?.profile?.picture || 'assets/images/avatar.svg'"
           [width]="40"
           [height]="40"
           class="img-avatar"
           alt="avatar"
           (error)="checkUrlAvatar($event)" />
      <div class="name-displayId">
        <div class="user-name">
          {{ currentUser?.profile?.firstName && currentUser?.profile?.lastName
            ? currentUser?.profile?.firstName +
            " " +
            currentUser?.profile?.lastName
            : "ไม่ได้ตั้งชื่อ" }}
        </div>
        <div class="user-id">
          <div class="id">ID : {{ currentUser?.profile?.userDisplayId }}</div>
          <img [src]="'assets/icons/menu/copy.svg'"
               alt="icon-copy"
               class="icon-copy"
              (click)="copyText(currentUser?.profile?.userDisplayId || '')" />
        </div>
      </div>
    </div>
  </div>
  <hr class="line-custom"/>
  <ul class="navigation navigation-main">
    <li *ngFor="let menu of menus; let i = index"
        [class.active]="menu.active"
        [class.setting]="menu.name === 'ตั้งค่า'"
        [class.logout]="menu.name === 'ออกจากระบบ'"
        (click)="selectMenu(menu.subMenu.length ? '' : menu.link)">
      <div class="menu"
           [ngClass]="{ 'pill-badge': pillBadgeNoti }"
           (click)="toggle(i)">
        <div class="icon-name-menu"
            [ngClass]="{ logout: menu.name === 'ออกจากระบบ' }">
          <img [src]="menu.icon" class="icon-title" alt=""/>
          <div class="name-title">
            {{ menu.name }}
          </div>
        </div>
        <img [src]="'assets/icons/menu/alt-arrow-down.svg'"
             class="chevron-down"
             alt=""
             [ngClass]="{ callable: !menu.callable }" />
        <span *ngIf="menu.name === 'การแจ้งเตือน' && pillBadgeNoti > 0">{{ pillBadgeNoti }}</span>
      </div>
      <ul class="submenu"
          #submenu
          [style.height.px]="menu.active ? submenu.scrollHeight : 0"
          [ngClass]="{ callable: !menu.callable }">
        <li *ngFor="let submenu of menu.subMenu">
          <a class="sub-menu-name"
             [ngClass]="{
             'user-delete': submenu.name === 'ลบบัญชีผู้ใช้',
             selected: submenu.link === selectedLink,
             'pill-badge': pillBadge
             }"
             (click)="selectSubmenu(submenu.link)">
            {{ submenu.name }}
            <span *ngIf="submenu.badge > 0">{{ submenu.badge }}</span>
          </a>
        </li>
      </ul>
      <hr *ngIf="menu.separatorLine" class="separator-line"/>
    </li>
    <li class="logout" (click)="logOut()">
      <div class="menu">
        <div class="icon-name-menu">
          <img [src]="'assets/icons/menu/logout-red.svg'" class="icon-title" alt=""/>
          <div class="name-title">ออกจากระบบ</div>
        </div>
      </div>
    </li>
  </ul>
  <hr class="line-custom"/>
</div>
