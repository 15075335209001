<div id="page-home" class="app-content">
  <!-- section banner -->
  <app-section-banner></app-section-banner>

    <!-- section booking -->
    <div class="section-booking mt-5">
      <div class="booking">
        <div class="header-bk">
          <div class="tx-1">เลือกดู</div>
          <div class="tx-2">ใบจอง</div>
          <div class="tx-1">ที่คุณต้องการ</div>
        </div>
        <app-section-booking></app-section-booking>
      </div>
    </div>

      <!-- section banner marketplace -->
  <div  class="to-onboard row" (click)="goBoards()">
    <img src="assets/images/banner-onboard.png" />
  </div>

  <!-- section recommend -->
  <app-section-recommended-shop></app-section-recommended-shop>




  <!-- section category -->
  <app-section-category></app-section-category>

  <!-- market place  new update -->
  <app-section-new-update-marketplace></app-section-new-update-marketplace>

  <!-- section download -->
  <app-section-download></app-section-download>
</div>
