import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-coin',
  templateUrl: './coin.component.html',
  styleUrls: ['./coin.component.scss']
})
export class CoinComponent {

  @Input() size = '20';
  @Input() marginTop='0';
  @Input() marginLeft='0';

}
