import {NgModule} from '@angular/core';
import {DateThPipe} from "./date-th.pipe";
import { CommonModule } from '@angular/common';
import { NumberThPipe } from './number-th.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [DateThPipe, NumberThPipe],
  exports:[DateThPipe,NumberThPipe]
})

export class SharedPipesModule {
}
