import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Subject } from 'rxjs';

// service
import { Logger } from '../logger.service';
import { UpdateService } from '../update.service';
import { CreateAddress } from '@app/models';

const log = new Logger('AddressService');

@Injectable({
  providedIn: 'root'
})

export class AddressService {

  public addressSubject = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
    private updateService: UpdateService
  ) {
  }

  async getAllAddress(id: any) {
    const url = `${environment.api}/addresses/`;
    const params = new HttpParams()
      .set('user', id);
    return await this.httpClient
      .get(url, { params })
      .toPromise()
      .then(res => {
        return res;
      });
  }

  async getAddress(id: any) {
    const url = `${environment.api}/addresses/${id}`;
    return await this.httpClient
      .get(url)
      .toPromise()
      .then(res => {
        return res;
      });
  }

  async createAddress(data: CreateAddress) {
    const url = `${environment.api}/mobile/addresses`;
    return await this.httpClient
      .post(url, data)
      .toPromise()
      .then(res => {
        this.updateService.updateUser$.next(true);
        return res;
      });
  }

  async updateAddress(id: string, data: any) {
    const url = `${environment.api}/mobile/addresses/${id}`;
    return await this.httpClient
      .patch(url, data)
      .toPromise()
      .then(res => {
        this.updateService.updateUser$.next(true);
        return res;
      });
  }
}
