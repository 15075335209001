<div class="con-section-recommend">
  <app-header-title
    title="วัตถุมงคลแนะนำ"
    link="/marketplace/home"
  ></app-header-title>

  <div class="con-items">
    <div class="item" *ngFor="let product of dataProductMarketplace?.results">
      <app-card-product-marketplace 
      [item]="product"></app-card-product-marketplace>
    </div>

  </div>
</div>
