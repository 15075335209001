import { Injectable } from '@angular/core';
// ngx
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root',
})

export class ToastService {

  isToastVisible = false;

  constructor(
    private toastr: ToastrService,


  ) {

  }

  showTabSuccess({ title = 'สำเร็จ', message = '' }) {
    if (!this.isToastVisible) {
      this.isToastVisible = true;

      this.toastr.success(
        message,
        title,
        {
          positionClass: 'inline',
          closeButton: false,
          disableTimeOut: true,
        }).onHidden.subscribe(() => {
          this.isToastVisible = false;
        });
    }
  }

  showSuccess({ title = 'success', message = '' }) {
    if (!this.isToastVisible) {
      this.isToastVisible = true;
      this.toastr.success(
        message,
        title,
        {
          closeButton: true,
          disableTimeOut: false,
          timeOut: 3000
        }).onHidden.subscribe(() => {
          this.isToastVisible = false;
        });
    }
  }

  showInfo({ title = 'info', message = '' }) {
    if (!this.isToastVisible) {
      this.isToastVisible = true;
      this.toastr.info(
        message,
        title,
        {
          closeButton: true,
          disableTimeOut: false,
          timeOut: 3000
        }).onHidden.subscribe(() => {
          this.isToastVisible = false;
        });
    }
  }

  showWarning({ title = 'warning', message = '' }) {
    if (!this.isToastVisible) {
      this.isToastVisible = true;
      this.toastr.warning(
        message,
        title,
        {
          closeButton: true,
          disableTimeOut: false,
          timeOut: 3000
        }).onHidden.subscribe(() => {
          this.isToastVisible = false;
        });
    }
  }

  showError({ title = 'เกิดข้อผิดพลาด', message = '' }) {
    if (!this.isToastVisible) {
      this.isToastVisible = true;
      this.toastr.error(
        message,
        title,
        {
          closeButton: true,
          disableTimeOut: false,
          timeOut: 3000
        }).onHidden.subscribe(() => {
          this.isToastVisible = false;
        });
    }
  }

  openToasts({ status = 'info', title = '', message = '' }) {

    switch (status) {
      case 'info':
        this.showInfo({ title: title || 'ตรวจสอบรายการ', message })
        break;
      case 'success':
        this.showSuccess({ title: title || 'ทำรายการสำเร็จ', message })
        break;
      case 'error':
        this.showError({ title: title || 'เกิดข้อผิดพลาด', message })
        break;
      case 'warning':
        this.showWarning({ title: title || 'ตรวจสอบรายการอีกครั้ง', message })
        break;
      case 'tab-success':
        this.showTabSuccess({ title: title || 'สำเร็จ', message })
        break;
    }
  }



}
