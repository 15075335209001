<ng-template #modalConfirm let-modal>
  <div class="modal-header"></div>
  <div class="modal-body" tabindex="0">
    <div class="conf-detail" *ngIf="!dataText.status">
      <div class="img-icon">
        <img [src]="'assets/icons/booking/danger-circle.svg'" alt=""/>
      </div>
      <div class="tt-no-dt">{{dataText.title || ''}}</div>
      <div class="tx-no-dt">
        <div class="tx-no-dt-1">{{dataText.detail || ''}}</div>
      </div>
    </div>
    <div class="conf-detail" *ngIf="dataText.status === 'pre-order'">
      <div class="img-icon">
        <img [src]="'assets/icons/booking/danger-circle.svg'" alt=""/>
      </div>
      <div class="tt-dt">{{dataText.title || 'ยืนยันทำรายการ'}}</div>
      <div class="tx-dt">
        <div class="tx-dt-1">ถ้าหากคุณยืนยันการทำรายการ โปรดคลิก “ตกลง”</div>
        <div class="tx-dt-2">หากคุณคลิก “ยกเลิก” กรณีนี้รายการของคุณจะถูกยกเลิก</div>
      </div>
    </div>
    <div class="conf-detail" *ngIf="dataText.status === 'success'">
      <div class="img-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
          <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
          <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
        </svg>
      </div>
      <div class="tt-dt dt-1">{{dataText.title || ''}}</div>
      <div class="tx-dt">
        <div class="dt-t-1">{{dataText.detail || ''}}</div>
      </div>
    </div>
    <div class="conf-detail" *ngIf="dataText.status === 'error'">
      <div class="img-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
          <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
          <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
          <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
        </svg>
      </div>
      <div class="tt-dt dt-2">{{dataText.title || ''}}</div>
      <div class="tx-dt">
        <div class="dt-t-2">{{dataText.detail || ''}}</div>
      </div>
    </div>
    <div class="conf-detail" *ngIf="dataText.status === 'not-enough-coins'">
      <div class="img-icon">
        <img [src]="'assets/icons/booking/danger-circle.svg'" alt=""/>
      </div>
      <div class="tt-dt dt-3">{{dataText.title || ''}}</div>
      <div class="tx-dt">
        <div class="dt-t-3">{{dataText.detail || ''}}</div>
      </div>
    </div>
    <div class="conf-detail" *ngIf="dataText.status === 'cancel'">
      <div class="img-icon">
        <img [src]="'assets/icons/booking/danger-circle-gray.svg'" alt=""/>
      </div>
      <div class="tt-dt dt-4">{{dataText.title || ''}}</div>
      <div class="tx-dt">
        <div class="dt-t-4">{{dataText.detail || ''}}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row" *ngIf="
    !dataText.status ||
    dataText.status === 'pre-order' ||
    dataText.status === 'not-enough-coins' ||
    dataText.status === 'cancel'">
      <div class="col-lg-6"
           [ngClass]="{
           'button-close-no': !dataText.status,
           'button-close-per-order': dataText.status === 'pre-order',
           'button-close-coin': dataText.status === 'not-enough-coins',
           'button-close': dataText.status === 'cancel'
           }">
        <div class="form-group">
          <button type="button" class="btn-block close" (click)="onEvent(false)" aria-label="Close" rippleEffect>
            ยกเลิก
          </button>
        </div>
      </div>
      <div class="col-lg-6"
           [ngClass]="{
           'button-no': !dataText.status,
           'button-per-order': dataText.status === 'pre-order',
           'button-top-up-coins': dataText.status === 'not-enough-coins',
           'button-cancel': dataText.status === 'cancel'
           }">
        <div class="form-group">
          <button type="button" class="btn-block confirm" (click)="onEvent(true)" rippleEffect>
            {{ dataText.btnConfirm || 'ตกลง' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

