<a (click)="modalOpenSignin(modalSignin)">
  <div class="signin-p" *ngIf="typeModal === 'register'">
    <p [ngStyle]="{ color: colorText ? colorText : 'white' }">เข้าสู่ระบบ</p>
  </div>
  <div class="signin" *ngIf="!typeModal">
    <p [ngStyle]="{ color: colorText ? colorText : 'white' }">เข้าสู่ระบบ</p>
  </div>
</a>

<ng-template #modalSignin let-modal>
  <div class="row">
    <div class="modal-left col-md-6">
      <picture>
        <img
          src="assets/images/banner-langka-login.png"
          alt="LOGIN LANGKA AMULET"
        />
      </picture>
    </div>
    <div class="modal-right col-md-6" *ngIf="!isLoading">
      <div class="modal-header">
        <p class="modal-title">
          <span class="btn-login">เข้าสู่ระบบ</span>
        </p>
        <button
          type="button"
          class="btn btn-flat-primary close"
          (click)="modal.close('Cross click')"
          aria-label="Close"
        >
          <span class="btn-close" aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="content-layout">
          <div id="socialAuthComponent">
            <form
              class="form-login"
              [formGroup]="signInForm"
              (ngSubmit)="onSubmit()"
            >
              <div class="form-group">
                <label for="email" class="form-label email">อีเมล</label>
                <input
                  type="text"
                  formControlName="email"
                  class="form-control"
                  placeholder="john@example.com"
                  aria-describedby="email"
                  id="email"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                />
                <div
                  *ngIf="submitted && f.email.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">
                    Email must be a valid email address
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="password" class="form-label password"
                  >รหัสผ่าน</label
                >
                <div class="input-group input-group-merge form-password-toggle">
                  <input
                    [type]="passwordTextType ? 'password' : 'text'"
                    formControlName="password"
                    class="form-control form-control-merge"
                    placeholder="············"
                    aria-describedby="register-password"
                    id="password"
                    [ngClass]="{
                      'is-invalid error': submitted && f.password.errors
                    }"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i
                        class="feather"
                        [ngClass]="{
                          'icon-eye-off': passwordTextType,
                          'icon-eye': !passwordTextType
                        }"
                        (click)="togglePasswordTextType()"
                      >
                      </i>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="submitted && f.password.errors"
                  class="invalid-feedback"
                  [ngClass]="{ 'd-block': submitted && f.password.errors }"
                >
                  <div *ngIf="f.password.errors.required">
                    Password is required
                  </div>
                </div>
                <div class="con-forgot-password">
                  <a href="/auth/forgot-password"> ลืมรหัสผ่าน </a>
                </div>
              </div>

              <div class="login-or-div">
                <span>หรือ เข้าสู่ระบบผ่าน</span>
                <hr />
              </div>
              <button
                *ngIf="false"
                class="btn btn-facebook btn-block"
                aria-label="Facebook Login"
                type="button"
              >
                <div>
                  <img
                    style="margin-right: 10px"
                    src="assets/icons/logo_facebook.svg"
                    alt=""
                    width="22"
                    height="22"
                  />
                  <span>เข้าสู่ระบบด้วย Facebook</span>
                </div>
              </button>

              <!-- <button
                class="btn btn-google btn-block"
                aria-label="Google Login"
                type="button"
                (click)="loginWithGoogle()"
              >
                <div>
                  <img
                    style="margin-right: 10px"
                    src="assets/icons/logo_google.svg"
                    alt=""
                    width="22"
                    height="22"
                  />
                  <span>เข้าสู่ระบบด้วย Google</span>
                </div>
              </button> 
       -->
              <div class="con-tab-social">
                <app-google-login
                  (onSignIn)="checkLogin($event)"
                ></app-google-login>
              </div>

              <div class="form-group">
                <div class="form-check checkbox">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="agree-term-policy"
                    checked
                  />
                  <label class="form-check-label" for="agree-term-policy">
                    ฉันตกลงและยอมรับ
                    <a href="javascript:void(0);">
                      นโบายความเป็นส่วนตัว & คุ้กกี้
                    </a>
                    และ
                    <a href="javascript:void(0);">
                      ข้อตกลงและ เงื่อนไข ของเรา</a
                    >
                  </label>
                </div>
              </div>
              <button
                class="btn btn-primary btn-block waves-effect waves-float"
                rippleeffect
              >
                ยืนยัน
              </button>
            </form>
          </div>
          <div class="modal-footer">
            <span>หากคุณยังไม่มีบัญชี</span>
            <button class="btn btn-none" (click)="goSignUp('auth/sign-up')">
              สร้างเลย
            </button>
            <!-- <app-auth-register-modal
              (click)="modal.close('Cross click')"
            ></app-auth-register-modal> -->
          </div>
        </div>
      </div>
    </div>
    <div class="modal-right col-md-6" *ngIf="isLoading">
      <app-loading></app-loading>
    </div>
  </div>
</ng-template>
