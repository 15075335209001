import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

// module
import {CoreCommonModule} from 'src/app/@core/common.module';
import {SharedComponentModule} from 'src/app/@shared/components/shared-component.module';

// ng-bootstrap

// component
import {HomeComponent} from '@pages/home/home.component';

import {SectionBookingComponent} from '@pages/home/components/section-booking/section-booking.component';
import {SectionRecommendedShopComponent} from './components/section-recommended-shop/section-recommended-shop.component';
import {SectionNewUpdateMarketplaceComponent} from './components/section-new-update-marketplace/section-new-update-marketplace.component';
import {SectionCategoryComponent} from './components/section-category/section-category.component';
import {SectionBannerComponent} from './components/section-banner/section-banner.component';
import {SectionDownloadComponent} from './components/section-download/section-download.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
];

@NgModule({
  providers: [
    CommonModule,

    // module
    CoreCommonModule,
  ],
  declarations: [
    // component
    HomeComponent,

    SectionRecommendedShopComponent,
    SectionBookingComponent,
    SectionDownloadComponent,
    SectionNewUpdateMarketplaceComponent,
    SectionCategoryComponent,
    SectionBannerComponent,
  ],
  exports: [
    // component
    HomeComponent,

    SectionBookingComponent,
    SectionRecommendedShopComponent,
    SectionNewUpdateMarketplaceComponent,
    SectionCategoryComponent,
    SectionBannerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    // module
    CoreCommonModule,
    SharedComponentModule,

    // ng-bootstrap

    // component
  ]
})

export class HomeModule {
}
