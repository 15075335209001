import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { DataCreateOrderBooking, DataCreateOrderBookingOfferP2P, DataCreateOrderBookingOnBoard, FilterOnBoard, GetBookingOrder, GetResponseBooking, LotsQueryParams, PaymentBooking, PaymentOnboard, } from '@models/booking.model';
import { UserService } from './user.service';
import { SharedDataService } from '../shared-data.service';
import { Category, GetRespondCategory, OrderBookingStatus, TypeStatusOrderOnboard } from '@app/models';



@Injectable({
  providedIn: 'root'
})


export class BookingService {

  constructor(
    private httpClient: HttpClient,
    private _userService: UserService,
    private sharedDataService: SharedDataService,

  ) {

  }

  async getCategoriesBooking(): Promise<GetRespondCategory> {
    return this.httpClient
      .get<GetRespondCategory | any>(environment.api + `/mobile/categories?limit=100`)
      .toPromise()
      .then(response => {
        const filteredResults = response.results.filter((item: Category) => item.type === 'default');
        return {
          ...response,
          totalResults: filteredResults.length,
          results: filteredResults
        };
      })
  }

  getLotsBooking(data: LotsQueryParams): Promise<GetResponseBooking> {
    const { type, page = 1, limit = 10 } = data
    const url = `${environment.api}/mobile/lots`;
    let params = new HttpParams()
      .set('openType', type)
      .set('sortBy', 'updatedAt:desc')
      .set('limit', limit.toString())
      .set('page', page.toString())
    return this.httpClient
      .get<GetResponseBooking | any>(url, { params })
      .toPromise()
  }

  async getProductByIdLot(id: string, page: number = 1, limit: number = 100): Promise<any> {
    return this.httpClient
      .get(environment.api + `/mobile/lots/get-products/${id}?page=${page}&limit=${limit}`)
      .toPromise()
      .then(response => response);
  }

  async getLotById(id: string, page: number = 1, limit: number = 100): Promise<any> {
    // ต้อง login
    return this.httpClient
      .get(environment.api + `/mobile/lots/${id}`)
      .toPromise()
      .then(response => response);
  }

  createOrderBooking(data: DataCreateOrderBooking): Promise<any> {
    return this.httpClient
      .post(environment.api + '/mobile/orders', data)
      .toPromise();
  }

  getOrderBookingById(orderId: string): Promise<any> {
    return this.httpClient
      .get(environment.api + '/mobile/orders/' + orderId)
      .toPromise();
  }



  paymentByCoins(dataTemp: PaymentBooking): Promise<any> {
    return this.httpClient
      .post(environment.api + '/mobile/payments/coin-payment', dataTemp)
      .toPromise();
  }

  paymentOrderOfferP2P(offerID: string): Promise<any> {
    return this.httpClient
      .post(environment.api + '/mobile/orders/confirm-offer/' + offerID, null)
      .toPromise();
  }

  paymentOrderOnBoard(id: string, data: PaymentOnboard): Promise<any> {
    return this.httpClient
      .post(environment.api + '/mobile/orders-on-board/payment/' + id, data)
      .toPromise();
  }



  createOrderOnBoard(dataTemp: DataCreateOrderBookingOnBoard): Promise<any> {
    return this.httpClient
      .post(environment.api + '/mobile/orders-on-board', dataTemp)
      .toPromise();
  }

  cancelOrderBooking(orderId: string): Promise<any> {
    return this.httpClient
      .post(environment.api + '/mobile/orders/cancel-order/' + orderId, null)
      .toPromise();
  }
  cancelOrderOffer(orderId: string): Promise<any> {
    return this.httpClient
      .post(environment.api + '/mobile/orders/cancel-offer/' + orderId, null)
      .toPromise();
  }

  cancelOrderOnBoard(orderId: string): Promise<any> {
    return this.httpClient
      .post(environment.api + '/mobile/orders-on-board/cancel/' + orderId, null)
      .toPromise();
  }


  createOrderBookingP2P(data: DataCreateOrderBookingOfferP2P): Promise<any> {
    return this.httpClient
      .post(environment.api + '/mobile/orders/create-offer', data)
      .toPromise();
  }

  async getInventoriesAll(page: number = 1, limit: number = 20): Promise<any> {
    return this.httpClient
      .get(environment.api + `/mobile/inventories?page=${page}&limit=${limit}`)
      .toPromise();

  }


  async getInventoriesByStatus({ status = '', page = 1, limit = 20 }): Promise<any> {
    return this.httpClient
      .get(environment.api + `/mobile/inventories?status=${status}&page=${page}&limit=${limit}`)
      .toPromise();
  }

  // async getOrderBookingByStatus(orderStatus: OrderBookingStatus): Promise<any> {
  //   //orderStatus : cancel | pending | payment
  //   return this.httpClient
  //     .get(environment.api + `/mobile/orders?orderStatus=${orderStatus}`)
  //     .toPromise()
  // }

  // getHistoryBookingAll(page = 1, limit = 100): Promise<any>  {
  //   return this.httpClient.get(environment.api + `/mobile/orders?page=${page}&limit=${limit}&sortBy=createdAt:desc`)
  //     .toPromise();
  // }


  getOrderBooking({ status = '', limit = 10, page = 1 }: GetBookingOrder): Promise<any> {
    const url = `${environment.api}/mobile/orders`;
    let params = new HttpParams()
      .set('sortBy', 'createdAt:desc')
      .set('limit', limit.toString())
      .set('page', page.toString())
      // .set('orderBy', 'date:desc')
    if (status) params = params.set('orderStatus', status);
    return this.httpClient
      .get(url, { params })
      .toPromise()
  }

  //###################  On Board  ###################///

  async filterOnBoard(data: FilterOnBoard, page: number = 1, limit: number = 10): Promise<any> {
    return this.httpClient
      .post(environment.api + `/mobile/orders-on-board/filter?page=${page}&limit=${limit}&sortBy=createdAt:desc`, data)
      .toPromise();
  }

  async searchOnBoard(dataText: object, page: number = 1, limit: number = 10): Promise<any> {
    return this.httpClient
      .post(environment.api + `/mobile/orders-on-board/search?page=${page}&limit=${limit}&sortBy=createdAt:desc`, dataText)
      .toPromise();
  }

  getOrdersOnBoardPending({ page = 1, limit = 10 }): Promise<any> {
    const url = `${environment.api}/mobile/orders-on-board`;
    let params = new HttpParams()
      .set('orderStatus', 'pending-on-board')
      .set('sortBy', 'updatedAt:desc')
      .set('limit', limit.toString())
      .set('page', page.toString())
    return this.httpClient
      .get(url, { params })
      .toPromise()
  }




  getMyOrderOnboardByStatus(orderStatus?: TypeStatusOrderOnboard, page = 1, limit = 10): Promise<any> {

    const url = `${environment.api}/mobile/owner-orders-on-board`;
    let params = new HttpParams()
      .set('sortBy', 'updatedAt:desc')
      .set('limit', limit.toString())
      .set('page', page.toString());
    if (orderStatus) params = params.set('orderStatus', orderStatus);
    return this.httpClient
      .get(url, { params })
      .toPromise();


  }


}
