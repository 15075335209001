<div class="con-card shadow-sm" (click)="goShop(data?.user.id)">
  <div class="con-image">
    <img
      [src]="data?.user?.profile?.picture || '/assets/logo/langka-logo-1.png'"
    />
  </div>
  <div class="con-text">
    <h2></h2>
    <h2>
      {{
        data?.user?.shop?.name ||
(        (data?.user?.profile.firstName + ' ' + data?.user?.profile.lastName  ) ||
          "ไม่ระบุ")
      }}

    </h2>

    <p>
      <i data-feather="thumbs-up" class="mr-1"></i>
      <!-- {{ data?.user?.shop?.favorite || 0}} ถูกใจ -->
      ร้านค้าแนะนำ
    </p>
  </div>
  <div class="con-baged">
    <span> VIP {{ data?.vip || 1 }}</span>
  </div>
</div>
