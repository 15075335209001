<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown">
    <span class="badge badge-danger badge-up" *ngIf="resBadge?.count > 0">
      {{resBadge?.count}}
    </span>
    <img src="assets/icons/alert.svg" alt="alert" />
  </a>
  <ul ngbDropdownMenu
      aria-labelledby="navbarNotificationDropdown"
      class="dropdown-menu dropdown-menu-media dropdown-menu-right">
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">การแจ้งเตือน</h4>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" *ngIf="notiList?.length">
      <a class="d-flex" *ngFor="let data of notiList">
        <div class="media d-flex align-items-center" (click)="checkToPage(data)">
          <div class="media-left">
            <div class="avatar">
              <img [src]="'assets/icons/noti-2.svg'" alt="ICON_NOTI"/>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading">{{ data.message.title }}</p>
            <small class="notification-text">{{ data.message.body }}</small>
            <small class="notification-date">{{ getElapsedTime(data.readDate) }}</small>
          </div>
        </div>
      </a>
    </li>
    <li class="media-list" *ngIf="!notiList?.length">
      <div style="padding: 1rem; display: flex; justify-content: center;">
        ไม่มีการแจ้งเตือน
      </div>
    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer">
      <a class="btn btn-more btn-block" href="/notification">ดูทั้งหมด</a>
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>
