<div class="container">
    <div class="swiper swiper-container">
        <span class="close-button"></span>
        <div class="gallery-top">
            <div class="swiper-wrapper" *ngIf="images" >
                <div class="swiper-slide slide-top" *ngFor="let bg of images" [style]="'background-image:url(' + bg + ')'"></div>
                <!-- <div class="swiper-slide slide-top" style="background-image:url(https://unsplash.it/1920/1080)"></div> -->
            </div>
            <div class="swiper-pagination swiper-pagination-top"></div>
            <button type="button" class="btn btn-primary swiper-button-prev swiper-button-prev-top top-buttons"></button>
            <button type="button" class="btn btn-primary swiper-button-next swiper-button-next-top top-buttons"></button>
        </div>
        <div class="gallery-thumbs">
            <div class="swiper-wrapper" *ngIf="images">
                <div class="swiper-slide" *ngFor="let bg of images" [style]="'background-image:url(' + bg + ')'"></div>
                <!-- <div class="swiper-slide" style="background-image:url(https://unsplash.it/1920/1080)"></div> -->
            </div>
            <button type="button" class="btn btn-primary swiper-button-prev swiper-button-prev-thumbs lower-buttons"></button>
            <button type="button" class="btn btn-primary swiper-button-next swiper-button-next-thumbs lower-buttons"></button>
        </div>
    </div>
</div>
