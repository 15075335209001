import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastService } from '@app/services/toast.service';
import { AuthService } from '@auth/auth.service';
import { CoreConfigService } from '@core/services/config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateService } from '@app/services/update.service';
import { AddressService } from '@app/services/api/address.service';
import { CreateAddress, UpdateProfile, UserProfile } from '@app/models';
import { Logger } from '@app/services/logger.service';
import { UserService } from '@app/services/api/user.service';
import { Router } from '@angular/router';
import { BlockUiService } from '@app/services/block-ui.service';

const log = new Logger('AuthAddressComponent');

const THAI_MOBILE_PATTERN = /^(06|08|09)\d{8}$/;


@Component({
  selector: 'app-auth-address',
  templateUrl: './auth-address.component.html',
  styleUrls: ['./auth-address.component.scss']
})
export class AuthAddressComponent {
  @Input() currentUser!: UserProfile | any;
  public coreConfig: any;
  public passwordTextType: boolean = true;
  public addressForm: any;
  public submitted = false;

  @Output() onClickBtn = new EventEmitter<string>();

  constructor(
    private _modalService: NgbModal,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    private _toastService: ToastService,
    private _updateService: UpdateService,
    private _addressService: AddressService,
    private _userService: UserService,
    private router: Router,
    private _loadingService: BlockUiService,




  ) {

  }


  ngOnInit(): void {
    this.addressForm = this._formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', Validators.required],
      // mobilePhone: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(THAI_MOBILE_PATTERN)]],
      province: ['', Validators.required],
      district: ['', Validators.required],
      subdistrict: ['', Validators.required],
      zipCode: ['', [Validators.required, Validators.maxLength(5), Validators.pattern('^[0-9]{5}$')]],
      address: ['', Validators.required],
      country: ['ประเทศไทย', Validators.required],

    });

  }




  // emitEvent(value: string) {
  //   this.onClickBtn.emit(value);
  // }


  get f() {
    return this.addressForm?.controls;
  }

  async confirmEditAddress() {
    if (!this.currentUser?.profile?.userDisplayId) {
      this._toastService.showInfo({ title: 'เกิดข้อผิดพลาด' })
      return
    }
    const formValue = this.addressForm.value;

    const hasData = Object.keys(formValue).every((key) => formValue[key]);

    if (!hasData) {
      this._toastService.showInfo({ title: 'กรุณากรอกข้อมูลให้ครบ' })
      return
    }

    const dataCreateAddress: CreateAddress = {
      address: formValue.address,
      subDistrict: formValue.subdistrict,
      city: formValue.district,
      province: formValue.province,
      zipCode: formValue.zipCode,
      country: formValue.country,
      isDefault: true
    }
    const dataUpdateProfile: UpdateProfile = {
      profile: {
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        userDisplayId: this.currentUser.profile.userDisplayId,
        agreement: true,
      }
    };

    if (this.currentUser?.profile?.picture) {
      dataUpdateProfile.profile.picture = this.currentUser.profile.picture
    }

    try {
      if (this.currentUser?.id) {
        this._loadingService.startBlocking('กำลังอัพเดรต...')
        const updateProfile = await this._userService.updateUserProfile(this.currentUser.id, dataUpdateProfile);
        const createAddress = await this._addressService.createAddress(dataCreateAddress);
        if (createAddress && updateProfile) {
          this._loadingService.stopBlocking()
          this._toastService.showSuccess({ title: 'ลงทะเบียนเรียบร้อย' })
          this._updateService.updateUserAddress$.next(true);
          this._updateService.updateUser$.next(true);
          await this.router.navigate(['/home']);
        }

      }
    } catch (error) {
      this._toastService.showError({})
    }
    this._loadingService.stopBlocking()
  }






}
