import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@app/auth/auth.service';
import { VerifyPhone } from '@app/models';
import { BlockUiService } from '@app/services/block-ui.service';
import { ToastService } from '@app/services/toast.service';
import { UpdateService } from '@app/services/update.service';
const THAI_MOBILE_PATTERN = /^(06|08|09)\d{8}$/;

@Component({
  selector: 'app-auth-otp-mobile',
  templateUrl: './auth-otp-mobile.component.html',
  styleUrls: ['./auth-otp-mobile.component.scss']
})
export class AuthOtpMobileComponent {
  @Output() onVerifyMobilePhone = new EventEmitter<string>();
  @ViewChild('otp1') firstOtp: ElementRef<HTMLInputElement> | undefined;

  public otpMobileDigitForm: FormGroup | any;
  public mobileForm: any;

  public mobilePhone = '';
  public tokenOTP!: string;

  public countdown: number = 15;
  public intervalCountdown: any;


  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private _authService: AuthService,
    private _updateService: UpdateService,
    private _loadingService: BlockUiService,
    private _toastService: ToastService,


  ) {

  }


  async ngOnInit() {

    this.mobileForm = this.formBuilder.group({
      mobilePhone: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(THAI_MOBILE_PATTERN)]],
    });

    this.otpMobileDigitForm = this.formBuilder.group({
      otpDigit1: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit2: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit3: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit4: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit5: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
      otpDigit6: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.pattern('[0-9]{1}'),
      ]],
    });


  }

  ngAfterViewInit() {
    if (this.firstOtp) {
      this.renderer.selectRootElement(this.firstOtp.nativeElement).focus();
    }

    this.startCountdown()
  }

  otpController(event: any, next: any, prev: any) {

    if (event.target.value.length < 1 && prev) {
      prev.focus();
    } else if (next && event.target.value.length > 0) {
      next.focus();
    } else {
      return;
    }
  }



  async requestOTP() {
    this.mobilePhone = this.mobileForm.controls["mobilePhone"].value;
    if (!this.mobilePhone) return;
    const data = {
      mobile: this.mobilePhone
    }
    console.log('data', data)
    try {
      const resRequestOTP = await this._authService.requestOtpVerifyPhone(data);

      if (resRequestOTP) this.tokenOTP = resRequestOTP.data.token;
      if (this.firstOtp) {
        this.renderer.selectRootElement(this.firstOtp.nativeElement).focus();
      }
    } catch (e) {
      this._toastService.showError({ title: 'เกิดข้อผิดพลาด' })
      console.error('Error resend ', e);
    }
  }



  async confirmVerifyPhone() {


    const { otpDigit1, otpDigit2, otpDigit3, otpDigit4, otpDigit5, otpDigit6 } = this.otpMobileDigitForm.value;
    const code = `${otpDigit1}${otpDigit2}${otpDigit3}${otpDigit4}${otpDigit5}${otpDigit6}`
    if (this.otpMobileDigitForm.invalid || !code || !this.tokenOTP || !this.mobilePhone) {
      this._toastService.showError({})
      return;
    }
    const data: VerifyPhone = {
      mobile: this.mobilePhone,
      otpToken: this.tokenOTP,
      otpCode: code
    }

    try {
      const res = await this._authService.verifyMobilePhone(data);
      if (res) this.onVerifyMobilePhone.emit('success')
    } catch (error) {
      this.onVerifyMobilePhone.emit('error')
      console.error('confirmVerifyPhone error', error);
    }
  }


  clearInput() {
    this.clearOtpInputs();
    if (this.firstOtp) {
      this.renderer.selectRootElement(this.firstOtp.nativeElement).focus();
    }
  }

  async resend() {
    if (!this.mobilePhone) return;
    this.clearOtpInputs()
    this.countdown = 15;
    this.startCountdown()
    try {
      this.requestOTP()
      if (this.firstOtp) {
        this.renderer.selectRootElement(this.firstOtp.nativeElement).focus();
      }
    } catch (error) {
      console.error('resend', error)
    }
  }


  clearOtpInputs() {
    Object.keys(this.otpMobileDigitForm.controls).forEach(controlName => {
      this.otpMobileDigitForm.get(controlName)?.setValue('');
      this.otpMobileDigitForm.get(controlName)?.markAsPristine();
      this.otpMobileDigitForm.get(controlName)?.markAsUntouched();
      this.otpMobileDigitForm.get(controlName)?.updateValueAndValidity();
    });
  }



  startCountdown() {
    this.intervalCountdown = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(this.intervalCountdown);
      }
    }, 1000);
  }


}
