import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { CORE_CUSTOM_CONFIG } from './services/config.service';
// import { AuthGuard } from './guards/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { TokenInterceptor } from './interceptors/token.interceptor';

@NgModule()
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('Import CoreModule in the AppModule only');
    }
  }

  static forRoot(config: any): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        // AuthGuard,
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: TokenInterceptor,
        //   multi: true
        // },
        {
            provide: CORE_CUSTOM_CONFIG,
            useValue: config
          }
  
      ],
    };
  }
}
