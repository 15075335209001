import {Component, AfterViewInit, ElementRef} from '@angular/core';
import Swiper from 'swiper';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';

// service
import {Logger} from "@services/logger.service";

// service/api
import {BannerService} from "@api/banner.service";

const log = new Logger('Slide Banner');

@Component({
    selector: 'app-slide-banner',
    templateUrl: './slide-banner.component.html',
    styleUrls: ['./slide-banner.component.scss']
})

export class SlideBannerComponent implements AfterViewInit {

    // Public
    public banners: any;

    // Private
    private swiper: any;

    constructor(
        private _newsService: BannerService,
        private elementRef: ElementRef
    ) {
    }

    async ngAfterViewInit() {

        const swiperElement = this.elementRef.nativeElement.querySelector('.swiper-banner');

        this.swiper = new Swiper(swiperElement, {
            direction: 'horizontal',
            loop: false,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next-banner',
                prevEl: '.swiper-button-prev-banner',
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            modules: [Navigation, Pagination, Autoplay],
        });

        await this.getBanner();
    }

    async getBanner() {
        try {
            const dataBanner = await this._newsService.getBanners(1, 10);
            this.banners = dataBanner.results.filter((e: { enabled: any; }) => e.enabled);
            // console.debug('banner: ', this.banners);
            this.swiper.update();
        } catch (error) {
            log.error('error get banner');
        }
    }
}
