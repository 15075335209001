<div class="modal-body">
  <div class="con-otp">
    <div class="con-mobile-phone">
      <h3>รหัส OTP จะถูกส่งไปที่เบอร์ที่คุณกรอกด้านล่างนี้</h3>
      <form [formGroup]="mobileForm">
        <div class="input-group mt-3">
          <input
            type="tel"
            formControlName="mobilePhone"
            class="form-control"
            placeholder="กรอกเบอร์โทร 10 หลัก"
            aria-describedby="mobilePhone"
            maxlength="10"
            id="mobilePhone"
            aria-label="mobilePhone"
            aria-describedby="mobilePhone"
          />
          <button
            [disabled]="mobileForm.invalid"
            class="btn btn-outline-secondary"
            type="button"
            id="mobilePhone"
            (click)="requestOTP()"
          >
            ขอ OTP
          </button>
        </div>
        <div
          class="mt-2"
          style="color: red"
          *ngIf="
            mobileForm.controls['mobilePhone'].invalid &&
            (mobileForm.controls['mobilePhone'].touched ||
              mobileForm.controls['mobilePhone'].dirty)
          "
        >
          ระบุ เบอร์โทรศัพท์ ให้ถูกต้อง
        </div>
      </form>
    </div>

    <div class="mt-4 mb-2 con-ooo">
      <p>กรอกเลข OTP 6 หลัก</p>
      <p *ngIf="!!mobilePhone">
        ที่ sms ส่งไปที่เบอร์ :
        <span>
          {{ mobilePhone }}
        </span>
      </p>
    </div>

    <form class="otp" [formGroup]="otpMobileDigitForm" (reset)="clearInput()">
      <div class="con-input">
        <input
          #otp1
          formControlName="otpDigit1"
          (keyup)="otpController($event, otp2, null)"
          type="tel"
          [maxLength]="1"
        />
        <input
          #otp2
          formControlName="otpDigit2"
          (keyup)="otpController($event, otp3, otp1)"
          type="tel"
          [maxLength]="1"
        />
        <input
          #otp3
          formControlName="otpDigit3"
          (keyup)="otpController($event, otp4, otp2)"
          type="tel"
          [maxLength]="1"
        />
        <input
          #otp4
          formControlName="otpDigit4"
          (keyup)="otpController($event, otp5, otp3)"
          type="tel"
          [maxLength]="1"
        />
        <input
          #otp5
          formControlName="otpDigit5"
          (keyup)="otpController($event, otp6, otp4)"
          type="tel"
          [maxLength]="1"
        />
        <input
          #otp6
          formControlName="otpDigit6"
          (keyup)="otpController($event, null, otp5)"
          type="tel"
          [maxLength]="1"
        />
      </div>
      <div class="time">
        กรุณารอ 15 วินาที เพื่อกดส่งขอรหัสอีกครั้ง
        <button type="button" (click)="resend()">ส่งอีกครั้ง</button>
      </div>
      <button [disabled]="otpMobileDigitForm.invalid || !mobilePhone" type="button" class="confirm" (click)="confirmVerifyPhone()">
        ยืนยัน
      </button>
      <button
        type="button"
        class="reset btn btn-outline-primary"
        (click)="clearInput()"
      >
        ล้าง
      </button>
    </form>
  </div>
</div>
