import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})

export class UpdateService {

  public updateUser$ = new Subject<any>();
  public updateUserAddress$ = new Subject<any>();
  public updateOnBoardBooking$ = new Subject<any>();
  public updateOnBoardFilter$ = new Subject<any>();
  public updateOfferBooking$ = new Subject<any>();
  public updateOrderBooking$ = new Subject<any>();
  public updateOrderMarketplace$ = new Subject<any>();
  public updateDeliver$ = new Subject<any>();
  public updateOnMarketplaceFilter$ = new Subject<any>();
  public updateRegister$ = new Subject<any>();

  constructor() {
  }
}
