import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-card-no-item',
  templateUrl: './card-no-item.component.html',
  styleUrls: ['./card-no-item.component.scss']
})

export class CardNoItemComponent {
  @Input() detail: string = '';
  @Input() title: string = 'ไม่มีรายการ';
}
