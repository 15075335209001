import {Injectable} from '@angular/core';
import {Logger} from './logger.service';
import {HttpClient} from '@angular/common/http';

const log = new Logger('imageDefaultService');

@Injectable({
  providedIn: 'root'
})

export class ImageDefaultService {

  imageDraft = 'assets/icons/marketplace/photo-daft.svg';
  imageAvatar = 'assets/images/avatar.svg';

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  async checkUrlAvatar(url:any) {
    if (url.type === 'error') {
      url.target.src = this.imageAvatar;
    }
  }

  async checkUrlDraft(event: { type: string; target: { src: string; }; }) {
    if (event.type === 'error') {
      event.target.src = this.imageDraft;
    }
  }
}
