import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

// module
import {AuthModalModule} from '@pages/features/auth/auth.module';
import {CoreCommonModule} from 'src/app/@core/common.module';
import {PagesModule} from "@pages/pages.module";
import {SharedComponentModule} from "@shared/components/shared-component.module";
import {SharedPipesModule} from '@app/@shared/pipes/shared-pipes.module';

// bootstrap
import {NgbCollapse, NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";

// component
import {HeaderComponent} from './components/header/header.component';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {FooterComponent} from './components/footer/footer.component';

import {NavbarNotificationComponent} from "layout/components/navbar/navbar-notification/navbar-notification.component";

@NgModule({
  providers: [
    CommonModule,
    CoreCommonModule
  ],
  declarations: [
    MainLayoutComponent,
    HeaderComponent,
    FooterComponent,

    NavbarNotificationComponent,
  ],
  exports: [
    MainLayoutComponent,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,

    // module
    AuthModalModule,
    CoreCommonModule,
    PagesModule,
    SharedComponentModule,
    SharedPipesModule,

    // bootstrap
    NgbDropdownModule,
    NgbCollapse,
  ]
})

export class LayoutModule {
}
