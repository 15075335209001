import {Component} from '@angular/core';

@Component({
  selector: 'app-card-banner-marketplace',
  templateUrl: './card-banner-marketplace.component.html',
  styleUrls: ['./card-banner-marketplace.component.scss']
})
export class CardBannerMarketplaceComponent {

}
