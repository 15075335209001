import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-forgot-password',
  templateUrl: './auth-forgot-password.component.html',
  styleUrls: ['./auth-forgot-password.component.scss']
})
export class AuthForgotPasswordComponent {
  userEmail!:string;
  isAgree:boolean = false;

}
