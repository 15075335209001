import { environment } from "@app/environments/environment"

export const bookingMenu = [
  {
    name: 'ใบจองพระ',
    link: '/booking/home',
    description: 'ใบจองพระจากวัดดังทั่วประเทศ'
  },
  {
    name: 'กระดานใบจอง',
    link: '/booking/boards',
    description: 'ตลาดวางซื้อขายใบจอง'
  },
  {
    name: 'กระเป๋าใบจองของฉัน',
    link: '/booking/my-booking#inventory',
    description: 'กระเป๋าใบจองของฉัน'
  },
  {
    name: 'รอการชำระเงิน',
    link: '/booking/my-booking#waitpay',
    description: 'รายการรอชำระเงินของคุณ'
  },
  {
    name: 'รายการจัดส่ง',
    link: '/delivery#delivered',
    description: 'ติดตามรายการจัดส่งของคุณ'
  },
]

export const profileMenu = [
  {
    name: 'บัญชีของฉัน',
    link: '/profile#my-profile',
    icon: 'assets/icons/menu/user-circle.svg',
    active: true,
    callable: true,
    description: 'บัญชีของฉัน',
    subMenu: [
      {
        name: 'โปรไฟล์ของฉัน',
        link: '/profile#my-profile',
        description: 'โปรไฟล์ของฉัน',
        badge: 0,
      },
      {
        name: 'แนะนำเพื่อน',
        link: '/profile#referral',
        description: 'แนะนำเพื่อน',
        badge: 0,
      },
      {
        name: 'ที่อยู่ของฉัน',
        link: '/profile#address',
        description: 'ที่อยู่ของฉัน',
        badge: 0,
      },
      {
        name: 'บัญชีธนาคาร',
        link: '/profile#bank-account',
        description: 'บัญชีธนาคาร',
        badge: 0,
      },
    ],
    separatorLine: false,
  },
  {
    name: 'การแจ้งเตือน',
    link: '/notification',
    icon: 'assets/icons/notification/noti.svg',
    active: false,
    callable: false,
    description: 'notification',
    subMenu: [],
    separatorLine: true,
  },
  {
    name: 'ใบจองพระ',
    link: '/booking/my-booking#inventory',
    icon: 'assets/icons/menu/notes.svg',
    active: false,
    callable: true,
    description: 'ใบจองพระของฉัน',
    subMenu: [
      {
        name: 'กระเป๋าใบจองของฉัน',
        link: '/booking/my-booking#inventory',
        description: 'รายละเอียด',
        badge: 0,
      },
      {
        name: 'รายการรอชำระ',
        link: '/booking/my-booking#waitpay',
        description: 'รายละเอียด',
        badge: 0,
      },
      {
        name: 'ซื้อขายใบจองของฉัน (P2P)',
        link: '/booking/my-booking#offer',
        description: 'รายละเอียด',
        badge: 0,
      },

    ],
    separatorLine: false,
  },
  {
    name: 'กระดานใบจองของฉัน',
    link: '/booking/my-onboard#pending',
    icon: 'assets/icons/menu/clipboard.svg',
    active: false,
    callable: true,
    description: 'กระดานใบจองของฉัน',
    subMenu: [
      {
        name: 'กำลังวางขาย',
        link: '/booking/my-onboard#pending',
        description: 'รายละเอียด',
        badge: 0,
      },
      {
        name: 'กระดานใบจองที่ขายแล้ว',
        link: '/booking/my-onboard#success',
        description: 'รายละเอียด',
        badge: 0,
      },
      {
        name: 'กระดานใบจองที่ยกเลิก',
        link: '/booking/my-onboard#cancel',
        description: 'รายละเอียด',
        badge: 0,
      },
    ],
    separatorLine: false,
  },
  {
    name: 'มาร์เก็ตเพลส',
    link: '/marketplace/my-market#manage-product',
    icon: 'assets/icons/menu/cart.svg',
    active: false,
    callable: true,
    description: 'ตลาดขายพระ',
    subMenu: [
      {
        name: 'จัดการสินค้า',
        link: '/marketplace/my-market#manage-product',
        description: 'จัดการ ร้านค้า',
        badge: 0,
      },
      {
        name: 'จัดการ ออเดอร์',
        link: '/marketplace/my-market#manage-order',
        description: 'จัดการ ออเดอร์',
        badge: 0,
      },
      {
        name: 'หน้าร้านค้าของฉัน',
        link: '/marketplace/my-market#manage-shop',
        description: 'จัดการหน้าร้านค้าของฉํน',
        badge: 0,
      },
      {
        name: 'package VIP',
        link: '/marketplace/my-market#package',
        description: 'ซื้อ package',
        badge: 0,
      },
      {
        name: 'การเงินของฉัน',
        link: '/marketplace/my-market#transaction',
        description: 'ธุระกรรมการเงิน ของมาร์เก็ตเพล',
        badge: 0,
      },
    ],
    separatorLine: false,
  },
  {
    name: 'Langka Mall',
    link: '#',
    icon: 'assets/icons/menu/langka-mall.svg',
    active: false,
    callable: true,
    description: 'Langka Mall',
    subMenu: [
      {
        name: 'กระเป๋ารูปภาพ',
        link: '#',
        description: '',
        badge: 0,
      },
    ],
    separatorLine: true,
  },
  {
    name: 'กระเป๋าตังค์ของฉัน',
    link: '/my-wallet#coins-buy-sell-transfer',
    icon: 'assets/icons/menu/wallet-money.svg',
    active: false,
    callable: true,
    description: '',
    subMenu: [
      {
        name: 'ซื้อขายโอน Coins',
        link: '/my-wallet#coins-buy-sell-transfer',
        description: '',
        badge: 0,
      },
      {
        name: 'ประวัติการซื้อขายโอน Coins',
        link: '/my-wallet#coins-transfer-trading-history',
        description: '',
        badge: 0,
      },
      {
        name: 'รายการธุรกรรม',
        link: '/my-wallet#coins-transaction',
        description: '',
        badge: 0,
      },
      {
        name: 'Langka Token',
        link: '/my-wallet#langka-token',
        description: '',
        badge: 0,
      },
    ],
    separatorLine: false,
  },
  {
    name: 'แชท',
    link: '/chat',
    icon: 'assets/icons/menu/chat-round-line.svg',
    active: false,
    callable: true,
    description: 'แชท',
    subMenu: [
      {
        name: 'P2P',
        link: '/chat#p2p',
        description: '',
        badge: 0,
      },
      {
        name: 'มาร์เก็ตเพลช',
        link: '/chat#marketplace',
        description: '',
        badge: 0,
      },
    ],
    separatorLine: false,
  },
  {
    name: 'รายการจัดส่งให้ฉัน',
    link: '/delivery#delivered',
    icon: 'assets/icons/menu/delivery.svg',
    active: false,
    callable: true,
    description: 'deliver',
    subMenu: [
      {
        name: 'เตรียมการจัดส่ง',
        link: '/delivery#delivered',
        description: '',
        status: 'delivered',
        badge: 0,
      },
      {
        name: 'ตรวจสอบยอมรับ',
        link: '/delivery#tracking',
        description: '',
        status: 'tracking',
        badge: 0,
      },
      {
        name: 'รายการสำเร็จ',
        link: '/delivery#completed',
        description: '',
        status: 'completed',
        badge: 0,
      },
      // {
      //   name: 'รายการยกเลิก',
      //   link: '/delivery#cancel',
      //   description: '',
      //   status: 'cancel'
      // },
      {
        name: 'การคืนเงิน / คืนวัตถุมงคล',
        link: '/delivery#problemed',
        description: '',
        status: 'problemed',
        badge: 0,
      },
    ],
    separatorLine: false,
  },
  {
    name: 'ประวัติการซื้อขาย',
    link: '/history#booking',
    icon: 'assets/icons/menu/history.svg',
    active: false,
    callable: true,
    description: 'wallet',
    subMenu: [
      {
        name: 'ประวัติการจองพระ',
        link: '/history#booking',
        description: '',
        badge: 0,
      },
      // {
      //   name: 'ประวัติการซื้อขายใบจอง',
      //   link: '/history#bookingP2P',
      //   description: '',
      // },
      // {
      //   name: 'ประวัติการซื้อกระดานใบจอง',
      //   link: '/history#bookingOnBoard',
      //   description: '',
      // },
    ],
    separatorLine: true,
  },
  {
    name: 'ตั้งค่า',
    link: '/setting#reset-password',
    icon: 'assets/icons/menu/settings.svg',
    active: true,
    callable: true,
    description: 'ตั้งค่า',
    subMenu: [
      {
        name: 'เปลี่ยนรหัสผ่าน',
        link: '/setting#reset-password',
        description: '',
        badge: 0,
        display: true,
      },
      {
        name: 'ตั้งค่าแจ้งเตือน',
        link: '/setting#notification',
        description: '',
        badge: 0,
        display: true,
      },
      {
        name: 'Privacy Policy',
        link: '/setting#privacy&policy',
        description: '',
        badge: 0,
        display: true,
      },
      {
        name: 'Terms & Conditions',
        link: '/setting#terms&conditions',
        description: '',
        badge: 0,
        display: true,
      },
      {
        name: 'เวอร์ชั่น ' + environment.webVersion,
        link: '#',
        description: '',
        badge: 0,
        display: false,
      },
      {
        name: 'ลบบัญชีผู้ใช้',
        link: '#',
        description: '',
        badge: 0,
        display: false,
      },
    ],
    separatorLine: true,
  },
  // {
  //   name: 'ออกจากระบบ',
  //   link: '#',
  //   icon: 'assets/icons/menu/logout-red.svg',
  //   active: false,
  //   callable: false,
  //   description: 'ออกจากระบบ',
  //   subMenu: [],
  //   separatorLine: true,
  // },
]

export const marketplaceMenu = [
  {
    name: 'ตลาดขายพระเครื่อง',
    link: 'marketplace/home',
    description: 'ศูนย์รวมตลาดพระเครื่อง'
  },
  {
    name: 'จัดการวัตถุมงคลที่ปล่อยเช่า',
    link: '/marketplace/my-market#manage-product',
    description: 'โชว์พระ ปล่อยเช่า สร้างสินค้า'
  },
  {
    name: 'หน้าร้านค้าของฉัน',
    link: 'marketplace/shop',
    description: 'ไปที่โปรไฟล์ของคุณ'
  },
  {
    name: 'รายการโปรด',
    link: '/marketplace/my-favorite',
    description: 'พระเครื่องที่คุณอยากได้'
  },
  {
    name: 'แชท',
    link: '/chat',
    description: 'ไปที่หน้ารายการแชททั้งหมด'
  },
  {
    name: 'จัดการร้านค้า',
    link: '/marketplace/my-market#manage-product',
    description: 'ไปที่หน้าจัดการร้านค้าของคุณ'
  },
]

export const coinsMenu = [
  {
    name: 'เติม coins',
    link: 'my-wallet#coins-buy-sell-transfer',
    description: 'รายละเอียด'
  },
  {
    name: 'ถอนเงิน',
    link: 'my-wallet#coins-buy-sell-transfer',
    description: 'รายละเอียด'
  },
  {
    name: 'โอน coins',
    link: 'my-wallet#coins-buy-sell-transfer',
    description: 'รายละเอียด'
  },
]

