import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { SharedDataService } from '../shared-data.service';

// service
import { UpdateService } from '../update.service';
import { Logger } from '../logger.service';
import { Register } from '@app/models';

const log = new Logger('UserService');

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(
    private httpClient: HttpClient,
    private updateService: UpdateService,
    private sharedDataService: SharedDataService,
  ) {
  }

  async getUserCurrentInfo(): Promise<any> {
    const url = `${environment.api}/mobile/users/me`;
    return await this.httpClient
      .get(url)
      .toPromise()
      .catch((error) => {
        this.updateService.updateUser$.next('logout');
        this.sharedDataService.setDataUserCurrentInfo(null);
      });
  }

  async updateUserProfile(id: string, profile: any) {
    const url = `${environment.api}/users/${id}`;
    return await this.httpClient
      .patch(url, profile)
      .toPromise()
      .then(res => {
        this.updateService.updateUser$.next(res);
        return res;
      });
  }

  getUserByDisplayId(displayID: string): Promise<any> {
    const url = `${environment.api}/mobile/users/uid/`;
    return this.httpClient
      .get(url + displayID)
      .toPromise();
  }

  getUserById(userId: string): Promise<any> {
    const url = `${environment.api}/mobile/users/id/`;
    return this.httpClient
      .get(url + userId)
      .toPromise();
  }

  forgotPassword(data: { email: string }): Promise<any> {
    return this.httpClient
      .post(environment.api + '/auth/forgot-password', data)
      .toPromise();
  }

  resetPassword(data:Register, otp:string): Promise<any> {
    return this.httpClient
      .post(environment.api + '/auth/reset-password' + `?token=${otp}`, data)
      .toPromise();
  }
}
