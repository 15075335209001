import { Component, AfterViewInit } from '@angular/core';
import Swiper from 'swiper';
import {Autoplay, EffectFade, Navigation} from 'swiper/modules';

@Component({
  selector: 'app-slide-banner-marketplace',
  templateUrl: './slide-banner-marketplace.component.html',
  styleUrls: ['./slide-banner-marketplace.component.scss']
})

export class SlideBannerMarketplaceComponent implements AfterViewInit {

  constructor() {
  }

  ngAfterViewInit() {
    const swiper = new Swiper('.swiper-banner-marketplace', {
      direction: 'horizontal',
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      effect: 'fade',
      navigation: true,
      loop: true,
      modules: [Autoplay, EffectFade, Navigation],
    });
  }
}
