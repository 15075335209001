import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// service
import { AuthService } from "@auth/auth.service";
import { CopyTextService } from '@app/services/copy-text.service';
import { ImageDefaultService } from '@app/services/image-default.service';
import { Logger } from "@services/logger.service";
import { ModalService } from "@services/modal.service";
import { MenuService } from "@services/menu.service";
import { SharedDataService } from '@app/services/shared-data.service';
import { ToastService } from '@app/services/toast.service';
import { UpdateService } from '@app/services/update.service';

// service api
import { DeliveryService } from '@app/services/api/delivery.service';
import { NotificationService } from "@api/notification.service";
import { UserService } from "@api/user.service";

// models
import { UserCurrent } from "@app/models";

// data moc
import { profileMenu } from 'src/assets/data/menu/moc-menu';

const log = new Logger('sidebar-menu')

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})

export class SidebarMenuComponent implements OnInit, AfterViewInit {

  @ViewChild('submenu') submenuElement!: ElementRef;

  @Input() selectedLink: string = '';

  menus = profileMenu;
  options = true;
  config: any;

  // public
  public currentUser: UserCurrent | undefined;
  public currentUserInfo: any;

  public pillBadge = 10;
  public pillBadgeNoti = 0;

  constructor(
    private _authService: AuthService,
    private _copyTextService: CopyTextService,
    private _imageDefaultService: ImageDefaultService,
    private _menuService: MenuService,
    private _modalService: ModalService,
    private _sharedDataService: SharedDataService,
    private _toastService: ToastService,
    private _updateService: UpdateService,
    private _deliveryService: DeliveryService,
    private _notificationService: NotificationService,
    private _userService: UserService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    this._authService.currentUser.subscribe(async (current) => {
      if (current) {
        this.currentUser = current;
        await this.getUserCurrentInfo();
      } else {
        this.currentUser = undefined;
      }
    });

    this._updateService.updateUser$.subscribe(async (e) => {
      if (e && e !== 'logout') {
        await this.getUserCurrentInfo()
      } else {
        this._sharedDataService.setDataUserCurrentInfo(null);
      }
    });

    this._notificationService.pillBadgeSubject.subscribe((event: any) => {
      if (event) {
        this.pillBadgeNoti = event
      }
    });
  }

  ngOnInit() {
    // const linksSelect = this._menuService.getSelectedLinkValue
    this._menuService.selectedLink_1.subscribe(async (link) => {
      if (link) {
        this.selectedLink = link;
        const index = this.getIndexBySubMenuLink(link)
        if (index) {
          this.expandTab(index?.mainIndex)
        }
      }
    })

    this.config = this.mergeConfig(this.options);
    this._menuService.selectedLink$.subscribe((link) => {
      this.selectedLink = link;
    });
    this.fetchDeliverData()
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  async getUserCurrentInfo() {
    if (this.currentUser) {
      try {
        const res = await this._userService.getUserCurrentInfo();
        if (res) {
          this.currentUserInfo = res;
          this._sharedDataService.setDataUserCurrentInfo(res);
        }

        // log.debug('res user me', res)
      } catch (error) {
        log.error('res user me', error)
      }
    }
  }

  mergeConfig(options: any) {
    const config = {
      multi: true
    };
    return {...config, ...options};
  }

  getIndexBySubMenuLink(link: string): { mainIndex: number, subIndex: number } | null {
    for (let mainIndex = 0; mainIndex < profileMenu.length; mainIndex++) {
      const subMenu = profileMenu[mainIndex].subMenu;
      for (let subIndex = 0; subIndex < subMenu.length; subIndex++) {
        if (subMenu[subIndex].link === link) {
          return {mainIndex, subIndex};
        }
      }
    }
    return null;
  }

  toggle(index: number) {
    if (!this.config.multi) {
      this.menus
        .filter((menu, i) => i !== index && menu.active)
        .forEach(menu => (menu.active = !menu.active));
    }
    this.menus[index].active = !this.menus[index].active;
  }

  expandTab(index: number) {
    this.menus
      .forEach((menu, i) => {
        menu.active = false;
      });
    this.menus[index].active = true
  }

  async selectMenu(link: string) {
    if (!link || link === '#') {
      return
    } else {
      this.selectedLink = link;
      this.toggleActiveMenu(link);
      await this.goLink(link);
    }
  }

  private toggleActiveMenu(link: string) {
    const foundMenu = this.menus.find(menu => menu.link === link);
    if (foundMenu) {
      this.menus.forEach(menu => (menu.active = false));
      foundMenu.active = true;
    }
  }

  async selectSubmenu(link: string) {
    if (!link || link === '#') {
      this._toastService.showInfo({title: 'ระบบจะอัพเดรตเร็วๆนี้'})
      return
    }

    if (this.selectedLink !== link) {
      this.selectedLink = link;
      await this.goLink(link);
    }
  }

  async goLink(link: string) {
    const {path, fragment} = this.splitUrl(link);
    if (!fragment) {
      // log.debug('link', path, fragment, link)
      await this.router.navigate([path]);
    } else {
      await this.router.navigate([path], {fragment: fragment});
    }
  }

  splitUrl(url: string): { path: string, fragment: string } {
    const parts = url.split('#');
    const path = parts[0];
    const fragment = parts[1] || '';
    return {path, fragment};
  }

  async fetchDeliverData() {
    try {
      const delivered: any = await this._deliveryService.getDelivered({});
      const tracking = await this._deliveryService.getDeliverTracking({});
      const completed = await this._deliveryService.getDeliverCompleted({});
      const problemed = await this._deliveryService.getDeliverProblemed({});

      const index = this.getIndexBySubMenuLink('/delivery#delivered')
      if (index) {
        this.menus[index.mainIndex].subMenu.forEach((e: any) => {
          if (e.status === 'delivered') e.badge = delivered?.totalResults || 0;
          if (e.status === 'tracking') e.badge = tracking?.totalResults || 0;
          if (e.status === 'completed') e.badge = completed?.totalResults || 0;
          if (e.status === 'problemed') e.badge = problemed?.totalResults || 0;
        })
      }
    } catch (error) {
      log.error('fetchDeliverData error', error)
    }
  }

  async logOut() {
    if (await this._modalService.openModalConfirm({
      title: 'ต้องการออกจากระบบ',
      detail: 'คุณต้องการออกจากระบบใช่หรือไม่ ?'
    })) {
      this._authService.logout();
    }
  }

  copyText(text: string) {
    this._copyTextService.copyText(text)
  }

  async checkUrlAvatar(event: any) {
    await this._imageDefaultService.checkUrlAvatar(event);
  }
}
