import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { GetResponseRecommendShop } from '@models/recommend-shop.model';
import { QueryParams } from '@models/query-params.model';
import { BuyPackageVIP, CreateProductMarketplace, DeleteImage, FavoriteProduct, GetPackages, GetProductMarketplace, GetProductMarketplaceFilter, GetProductMyShopMarketplace, GetResponseFavoriteMarketplace, GetResponseProductMarketplace, LikeShop, PaymentMarketplace, UpdateProductMarketplace, UpdateProfileShop, UpdateTrackingNumber } from '@models/marketplace.model';
import { Category, GetRespondCategory } from '@models/category.model';
import { Subject, pipe } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {
  public updateData$ = new Subject<boolean>();

  constructor(

    private httpClient: HttpClient,
  ) { }


  getRecommendedAdvertise(params: QueryParams): Promise<any> {
    const { page = 1, limit = 10 } = params
    return this.httpClient
      .get<any | any>(environment.api + `/mobile/products-on-market/get-recommend-shop`)
      .toPromise()
      .then(response => response);
  }


  async getProductMarketSorted(params: QueryParams): Promise<GetResponseProductMarketplace> {
    const { page = 1, limit = 10 } = params
    return this.httpClient
      .get<GetResponseProductMarketplace | any>(environment.api + `/mobile/sorted-products-on-market?page=${page}&limit=${limit}`)
      .toPromise()
      .then(response => response);
  }


  async getProductMyShop(dataParam: GetProductMyShopMarketplace): Promise<any> {
    const { status = 'pending-on-market', userId, openType, page = 1, limit = 10 } = dataParam;

    const url = `${environment.api}/mobile/my-shop-products-on-market/${userId}`;
    let params = new HttpParams()
      .set('status', status)
      .set('openType', openType)
      .set('sortBy', 'createdAt:desc')
      .set('limit', limit.toString())
      .set('page', page.toString())
    return this.httpClient
      .get(url, { params })
      .toPromise()


  }


  async getFavoriteProducts(params: QueryParams): Promise<GetResponseFavoriteMarketplace> {
    const { page = 1, limit = 10 } = params
    return this.httpClient
      .get<GetResponseFavoriteMarketplace | any>(environment.api + `/mobile/favorites/my-favorite-product?page=${page}&limit=${limit}&sortBy=createdAt:desc`)
      .toPromise()
      .then(response => response);
  }

  async getProductRecommend(param: QueryParams): Promise<any> {
    const { limit = 30, page = 1 } = param

    return this.httpClient
      .get(environment.api + `/mobile/products-on-market/get-pin-products?page=${page}&limit=${limit}`)
      .toPromise();
  }



  async getCategories(): Promise<GetRespondCategory> {
    return this.httpClient
      .get<GetRespondCategory | any>(environment.api + `/mobile/categories?limit=100`)
      .toPromise()
      .then(response => {
        const filteredResults = response.results.filter((item: Category) => item.type === 'onmarket');
        return {
          ...response,
          totalResults: filteredResults.length,
          results: filteredResults
        };
      })
  }



  getMarketplaceProductById(productId: string): Promise<any> {
    return this.httpClient
      .get(environment.api + `/mobile/products-on-market/` + productId)
      .toPromise()
  }
  createProductMarket(data: CreateProductMarketplace): Promise<any> {
    return this.httpClient
      .post(environment.api + '/mobile/products-on-market', data)
      .toPromise();
  }
  getMyProductMarketplace(data: GetProductMarketplace): Promise<any> {
    const { status, limit = 10, page = 1 } = data;
    const url = `${environment.api}/mobile/my-products-on-market`;
    let params = new HttpParams()
      .set('status', status)
      .set('sortBy', 'updatedAt:desc')
      .set('limit', limit.toString())
      .set('page', page.toString())
    return this.httpClient
      .get(url, { params })
      .toPromise()
  }
  updateProductMarket(data: UpdateProductMarketplace, productID: string): Promise<any> {
    return this.httpClient
      .patch(environment.api + '/mobile/products-on-market/' + productID, data)
      .toPromise();
  }

  updateShopProfile(data: UpdateProfileShop): Promise<any> {
    return this.httpClient
      .patch(environment.api + `/mobile/users/update-shop-profile`, data)
      .toPromise()
  }

  promoteProduct(data: UpdateProductMarketplace, productID: string): Promise<any> {
    return this.httpClient
      .patch(environment.api + `/mobile/products-on-market/set-pin-package/` + productID, data)
      .toPromise()
  }
  closeSellProductMarket(productId: string): Promise<any> {
    return this.httpClient
      .post(environment.api + `/mobile/products-on-market/payment-outside/` + productId, null)
      .toPromise();
  }

  deleteProductOnMarket(id: string): Promise<any> {
    return this.httpClient
      .post(environment.api + `/mobile/products-on-market/cancel/` + id, null)
      .toPromise();
  }
  deleteImage(data: DeleteImage): Promise<any> {
    return this.httpClient
      .delete(environment.api + '/files/delete-by-location', { body: data })
      .toPromise();
  }




  getOrderMarketplace(data: GetProductMarketplace): Promise<any> {
    const { status, limit = 10, page = 1 } = data;
    const url = `${environment.api}/mobile/my-applicant-products-on-market`;
    let params = new HttpParams()
      .set('status', status)
      .set('sortBy', 'updatedAt:desc')
      .set('limit', limit.toString())
      .set('page', page.toString())
    return this.httpClient
      .get(url, { params })
      .toPromise()
  }

  updateTrackingOnMarket(productID: string, data: UpdateTrackingNumber): Promise<any> {
    return this.httpClient
      .patch(environment.api + '/mobile/products-on-market/update-tracking/' + productID, data)
      .toPromise()
  }


  // ############## start package vip ##################//
  getUserPackage(userID: string, sortBy = 'desc'): Promise<any> {
    return this.httpClient
      .get(environment.api + `/mobile/user-package-datas/?user=` + userID + `&sortBy=updatedAt:${sortBy}`)
      .toPromise()
  }
  checkUserPackages(userID: string): Promise<any> {
    return this.httpClient
      .get(environment.api + `/mobile/user-package-datas/check-user-package/` + userID)
      .toPromise()
  }
  getPackages(): Promise<GetPackages | any> {
    return this.httpClient
      .get<GetPackages>(environment.api + `/mobile/user-packages`)
      .toPromise()
  }
  checkQualityPackage(userID: string): Promise<any> {
    return this.httpClient
      .get(environment.api + `/mobile/products-on-market/check-quantity-pin/` + userID)
      .toPromise()
  }

  buyPackages(data: BuyPackageVIP): Promise<any> {
    return this.httpClient
      .post(environment.api + `/mobile/user-package-datas/package`, data)
      .toPromise()
  }



  // ############## end package vip ##################//
  // ############## start Sell Coin Balance ##################//
  getSellerBalanceWaiting(data: QueryParams): Promise<any> {
    const { limit = 10, page = 1 } = data;
    const url = `${environment.api}/mobile/products-on-market/my-history/waiting`;
    let params = new HttpParams()
      .set('sortBy', 'updatedAt:desc')
      .set('limit', limit.toString())
      .set('page', page.toString())
    return this.httpClient
      .get(url, { params })
      .toPromise()
  }
  getSellerBalanceCompleted(data: QueryParams): Promise<any> {
    const { limit = 10, page = 1 } = data;
    const url = `${environment.api}/mobile/products-on-market/my-history/completed`;
    let params = new HttpParams()
      .set('sortBy', 'updatedAt:desc')
      .set('limit', limit.toString())
      .set('page', page.toString())
    return this.httpClient
      .get(url, { params })
      .toPromise()
  }
  // ############## end Sell Coin Balance ##################//
  // ############## start Filter ##################//
  searchTextProductMarket(data: GetProductMarketplaceFilter, param: QueryParams): Promise<any> {
    const { limit = 30, page = 1 } = param
    return this.httpClient
      .post(environment.api + `/mobile/products-on-market/search?page=${page}&limit=${limit}&sortBy=updatedAt:desc`, data)
      .toPromise();
  }
  getFilterProductMarketplace(data: GetProductMarketplaceFilter, param: QueryParams): Promise<any> {
    const { page, limit } = param
    return this.httpClient
      .post(environment.api + `/mobile/products-on-market/filter?page=${page}&limit=${limit}&sortBy=updatedAt:desc`, data)
      .toPromise();
  }
  // ############## end Filter ##################//
  // ############## start Favorite ##################//
  checkFavoriteProduct(productId: string): Promise<any> {
    return this.httpClient
      .get(environment.api + `/mobile/favorites/check-favorite/` + productId)
      .toPromise()
  }
  getTotalFavorite(productId: string) {
    return this.httpClient
      .get(environment.api + `/mobile/favorites/get-favorite-badge?onmarketProduct=` + productId + '&isFavorite=true')
      .toPromise()
  }
  createFavorite(data: FavoriteProduct): Promise<any> {
    return this.httpClient
      .post(environment.api + `/mobile/favorites`, data)
      .toPromise()
  }
  unlikeFavorite(favoriteId: string): Promise<any> {
    return this.httpClient
      .delete(environment.api + `/mobile/favorites/unlike-favorite/` + favoriteId)
      .toPromise()
  }

  likeShop(data: LikeShop): Promise<any> {
    return this.httpClient
      .post(environment.api + `/mobile/favorites/like-shop`, data)
      .toPromise()
  }
  // ############## end Favorite ##################//
  // ############## start Buy Product ##################//

  agreeBuyOnMarket(productId: string): Promise<any> {
    return this.httpClient
      .patch(environment.api + '/mobile/products-on-market/set-buy-policy/' + productId, null)
      .toPromise();
  }
  paymentProductMarket(data: PaymentMarketplace, productId: string) {
    return this.httpClient
      .post(environment.api + `/mobile/products-on-market/payment/` + productId, data)
      .toPromise();
  }

  // ############## end Buy Product ##################//





}
