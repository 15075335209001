import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// model
import { ProductMarketplace } from '@models/marketplace.model';
import { UserCurrent } from '@app/models';

// service
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-card-product-marketplace',
  templateUrl: './card-product-marketplace.component.html',
  styleUrls: ['./card-product-marketplace.component.scss']
})

export class CardProductMarketplaceComponent implements OnInit {

  @Input() item: ProductMarketplace | any;

  isLoading: boolean = true;

  public currentUser: UserCurrent | undefined;

  public items: any

  constructor(
    private router: Router,
    private _authService: AuthService,
  ) {
    this._authService.currentUser.subscribe((current) => {
      if (current) {
        this.currentUser = current;
      } else {
        this.currentUser = undefined;
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.items = this.item;
      this.isLoading = false;
      // console.debug('item', this.item)
    }, 1000);
  }

  goProductDetail(id: string) {
    if (!id) return;
    this.router.navigate(['marketplace/detail', id]);
  }
  
  goChatMarket(productId: string) {

    const applicantId = this.item?.applicant[0]?._id || this.item?.applicant[0]?.id || this.item?.applicant?.id || this.item?.applicant || null
    const currentId = this.currentUser?.id 

    if (currentId && applicantId) {
      // this.router.navigate([`chat/marketplace/${productId}/${applicantId}`]);
      const url = `chat/marketplace/${productId}/${applicantId}#marketplace`;
      window.open(url, '_blank');
    }
    // , { fragment: this.fragment }
  }

  makePhoneCall(tel: string) {
    if (!tel) return
    window.open('tel:' + tel);
  }
}
