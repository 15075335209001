<div class="modal-body">
  <div class="content-layout">
    <div id="socialAuthComponent">
      <form
        class="form-login"
        [formGroup]="addressForm"
        (ngSubmit)="confirmEditAddress()"
      >
        <div class="form-group">
          <label for="firstName" class="form-label firstName">ชื่อ</label>
          <input
            type="text"
            formControlName="firstName"
            class="form-control"
            placeholder="กรุณากรอก ชื่อ"
            aria-describedby="firstName"
            id="firstName"
            [ngClass]="{
              'is-invalid':
                addressForm.controls['firstName'].invalid &&
                (addressForm.controls['firstName'].touched ||
                  addressForm.controls['firstName'].dirty)
            }"
          />
          <div
            style="color: red"
            *ngIf="
              addressForm.controls['firstName'].invalid &&
              (addressForm.controls['firstName'].touched ||
                addressForm.controls['firstName'].dirty)
            "
          >
            กรุณากรอกชื่อ
          </div>
        </div>

        <div class="form-group">
          <label for="lastName" class="form-label lastName">นามสกุล</label>
          <input
            type="text"
            formControlName="lastName"
            class="form-control"
            placeholder="กรุณากรอก นามสกุล"
            aria-describedby="lastName"
            id="lastName"
            [ngClass]="{
              'is-invalid':
                addressForm.controls['lastName'].invalid &&
                (addressForm.controls['lastName'].touched ||
                  addressForm.controls['lastName'].dirty)
            }"
          />
          <div
            style="color: red"
            *ngIf="
              addressForm.controls['lastName'].invalid &&
              (addressForm.controls['lastName'].touched ||
                addressForm.controls['lastName'].dirty)
            "
          >
            กรุณากรอกนามสกุล
          </div>
        </div>

        <!-- <div class="form-group">
          <label for="mobilePhone" class="form-label mobile">เบอร์โทร</label>
          <input
            type="tel"
            formControlName="mobilePhone"
            class="form-control"
            placeholder="กรุณากรอกเบอร์โทร"
            aria-describedby="mobilePhone"
            maxlength="10"
            id="mobilePhone"
            [ngClass]="{
              'is-invalid':
                addressForm.controls['mobilePhone'].invalid &&
                (addressForm.controls['mobilePhone'].touched ||
                  addressForm.controls['mobilePhone'].dirty)
            }"
          />
          <div
            style="color: red"
            *ngIf="
              addressForm.controls['mobilePhone'].invalid &&
              (addressForm.controls['mobilePhone'].touched ||
                addressForm.controls['mobilePhone'].dirty)
            "
          >
            ระบุ เบอร์โทรศัพท์ ให้ถูกต้อง
          </div>
        </div> -->

        <div class="form-group">
          <label for="address" class="form-label address">ที่อยู่</label>
          <input
            type="text"
            formControlName="address"
            class="form-control"
            placeholder="กรุณากรอก บ้านเลขที่ หมู่ อาคาร ตึก"
            aria-describedby="address"
            id="address"
            [ngClass]="{
              'is-invalid':
                addressForm.controls['address'].invalid &&
                (addressForm.controls['address'].touched ||
                  addressForm.controls['address'].dirty)
            }"
          />
          <div
            style="color: red"
            *ngIf="
              addressForm.controls['address'].invalid &&
              (addressForm.controls['address'].touched ||
                addressForm.controls['address'].dirty)
            "
          >
            ระบุ ที่อยู่
          </div>
        </div>

        <div class="form-group">
          <label for="province" class="form-label province">จังหวัด</label>
          <input
            type="text"
            formControlName="province"
            class="form-control"
            placeholder="กรุณากรอกจังหวัด"
            aria-describedby="province"
            id="province"
            [ngClass]="{
              'is-invalid':
                addressForm.controls['province'].invalid &&
                (addressForm.controls['province'].touched ||
                  addressForm.controls['province'].dirty)
            }"
          />
          <div
            style="color: red"
            *ngIf="
              addressForm.controls['province'].invalid &&
              (addressForm.controls['province'].touched ||
                addressForm.controls['province'].dirty)
            "
          >
            ระบุจังหวัด
          </div>
        </div>
        <div class="form-group">
          <label for="district" class="form-label district">อำเภอ</label>
          <input
            type="text"
            formControlName="district"
            class="form-control"
            placeholder="กรุณากรอก อำเภอ"
            aria-describedby="district"
            id="district"
            [ngClass]="{
              'is-invalid':
                addressForm.controls['district'].invalid &&
                (addressForm.controls['district'].touched ||
                  addressForm.controls['district'].dirty)
            }"
          />
          <div
            style="color: red"
            *ngIf="
              addressForm.controls['district'].invalid &&
              (addressForm.controls['district'].touched ||
                addressForm.controls['district'].dirty)
            "
          >
            ระบุ อำเภอ
          </div>
        </div>
        <div class="form-group">
          <label for="subdistrict" class="form-label subdistrict">ตำบล</label>
          <input
            type="text"
            formControlName="subdistrict"
            class="form-control"
            placeholder="กรุณากรอก ตำบล"
            aria-describedby="subdistrict"
            id="subdistrict"
            [ngClass]="{
              'is-invalid':
                addressForm.controls['subdistrict'].invalid &&
                (addressForm.controls['subdistrict'].touched ||
                  addressForm.controls['subdistrict'].dirty)
            }"
          />
          <div
            style="color: red"
            *ngIf="
              addressForm.controls['subdistrict'].invalid &&
              (addressForm.controls['subdistrict'].touched ||
                addressForm.controls['subdistrict'].dirty)
            "
          >
            ระบุ ตำบล
          </div>
        </div>
        <div class="form-group">
          <label for="zipCode" class="form-label zipCode">รหัสไปรษณีย์</label>
          <input
            type="tel"
            formControlName="zipCode"
            class="form-control"
            placeholder="กรุณากรอก รหัสไปรษณีย์"
            aria-describedby="zipCode"
            maxlength="5"
            id="zipCode"
            [ngClass]="{
              'is-invalid':
                addressForm.controls['zipCode'].invalid &&
                (addressForm.controls['zipCode'].touched ||
                  addressForm.controls['zipCode'].dirty)
            }"
          />

          <div
            style="color: red"
            *ngIf="
              addressForm.controls['zipCode'].invalid &&
              (addressForm.controls['zipCode'].touched ||
                addressForm.controls['zipCode'].dirty)
            "
          >
            ระบุเลขไปรษณีย์
          </div>
        </div>
        <div class="form-group">
          <label for="country" class="form-label country">ประเทศ</label>
          <input
            type="text"
            formControlName="country"
            class="form-control"
            placeholder="กรุณากรอก ประเทศ"
            aria-describedby="country"
            id="country"
            [ngClass]="{
              'is-invalid':
                addressForm.controls['country'].invalid &&
                (addressForm.controls['country'].touched ||
                  addressForm.controls['country'].dirty)
            }"
          />

          <div
            style="color: red"
            *ngIf="
              addressForm.controls['country'].invalid &&
              (addressForm.controls['country'].touched ||
                addressForm.controls['country'].dirty)
            "
          >
            ระบุประเทศ
          </div>
        </div>

        <button
          [disabled]="addressForm.invalid"
          class="btn btn-primary btn-block waves-effect waves-float"
          rippleeffect
          type="submit"
        >
          ลงทะเบียน
        </button>
      </form>
    </div>
    <div class="modal-footer">
      <!-- <span>หากคุณยังไม่มีบัญชี</span>
      <app-auth-signin-modal colorText="#133692"></app-auth-signin-modal> -->
    </div>
  </div>
</div>
