<div class="featured-banner">
    <div class="row banner-header">
        <div class="col-md-8 col-12 slide-banner">
            <div class="banner-title">
                <div class="con-title">
                    <div class="text-l">ข่าวสาร</div>
                    <div class="text-c"></div>
                </div>
            </div>
            <app-slide-banner></app-slide-banner>
        </div>
        <div class="col-md-4 col-12 slide-new-product">
            <div class="new-product-title">
                <app-header-title title="ใบจองที่กำลังเปิด" link="/booking/home"></app-header-title>
            </div>
            <app-slide-new-product [data]="dataBooking"></app-slide-new-product>
        </div>
    </div>
</div>
